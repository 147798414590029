import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import Alert from '@material-ui/icons/NotificationImportant';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Snackbar from '../../../components/Snackbar/Snackbar.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { filterMethod } from '../../../helpers/utils';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import CardBody from '../../../components/Card/CardBody';
import TextField from '@material-ui/core/TextField';
import {
  fetchHistoryUsers,
  resetHistory,
} from '../../../actions/reportsAction';
import { fetchCurrentUser } from '../../../actions/loginAction';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import history from '../../../history';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const style = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class HistoryUsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      data: [],
      dataInicio: '',
      dataFim: '',
      idUsuario: '',
      dataInicioError: false,
      dataInicioErrorMessage: '',
      dataFimError: false,
      idUsuarioError: false,
      detailModal: false,
    };
  }

  componentDidMount() {
    this.props.resetHistory();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.historyUsers &&
      this.props.historyUsers !== prevProps.historyUsers
    ) {
      this.setState(
        {
          tableData: this.props.historyUsers,
          notificationMessage: 'Lista atualizada',
        },
        () => {
          this.handleData();
          this.showNotification();
        }
      );
    }

    if (
      this.props.historyUsersError &&
      this.props.historyUsersError != prevProps.historyUsersError
    ) {
      this.setState({ notificationMessage: this.props.historyUsersError }, () =>
        this.showNotification('danger')
      );
    }
  }

  componentWillUnmount() {}

  fetchData() {
    this.setState(
      {
        dataInicioError: '',
        dataFimError: '',
      },
      () => {
        let aprove = true;
        if (
          this.state.dataInicio &&
          this.state.dataFim &&
          this.state.dataInicio > this.state.dataFim
        ) {
          this.setState({
            dataFimError: 'Data final deve ser maior que a data inicial',
          });
          aprove = false;
        }
        if (
          this.state.dataInicio &&
          this.state.dataFim &&
          this.state.dataInicio > this.state.dataFim
        ) {
          this.setState({
            dataFimError: 'Data final deve ser maior que a data inicial',
          });
          aprove = false;
        }
        if (!this.state.dataInicio) {
          this.setState({ dataInicioError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!this.state.dataFim) {
          this.setState({ dataFimError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!aprove) return;
        this.props.fetchHistoryUsers({
          dt_inicial: this.state.dataInicio || '',
          dt_final: this.state.dataFim || '',
          id: this.state.idUsuario || '*',
        });
      }
    );
  }

  handleData = () => {
    const data =
      this.state.tableData && Array.isArray(this.state.tableData)
        ? this.state.tableData.map((prop, key) => {
            return {
              nomeCliente: prop.client,
              dataRegistro: prop.date
                ? moment(prop.date).format('DD/MM/YYYY')
                : '',
              start: prop.start ? prop.start : '',
              efective_start: prop.efective_start ? moment(prop.efective_start ).format('HH:mm') : '-',
              end: prop.end ? prop.end : '-',
              efective_end: prop.efective_end ? moment(prop.efective_end).format('HH:mm') : '',
              valorPagamento: Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }).format(prop.value),
              pont: prop.pont ? prop.pont : '',
              theme: prop.themes
                ? prop.themes.map((item) => item).join(', ')
                : '',
              statusPagamento: prop.status ? prop.status : '',
            };
          })
        : [];
    this.setState({ data });
  };

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetHistory();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  handleModalOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleModalClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleOnChangeuser(event) {
    this.setState({ name: event.target.value });
  }

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <LocalAtmIcon />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <Grid container spacing={32}>
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sm={4}
                    style={{ paddingTop: '15px' }}
                  >
                    <TextField
                      id="dataInicio"
                      label="Início"
                      error={!!this.state.dataInicioError}
                      helpText={
                        !this.state.dataInicioError &&
                        this.state.dataInicioErrorMessage
                      }
                      type="date"
                      onChange={this.handleChange('dataInicio').bind(this)}
                      fullWidth={true}
                      inputProps={{ max: '9999-12-31' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sm={4}
                    style={{ paddingTop: '15px' }}
                  >
                    <TextField
                      id="dataFim"
                      label="Término"
                      error={!!this.state.dataFimError}
                      helpText={this.state.dataFimError}
                      type="date"
                      inputProps={{
                        max: '3000-01-01',
                        min: this.state.dataInicio,
                      }}
                      value={this.state.dataFim}
                      onChange={this.handleChange('dataFim').bind(this)}
                      fullWidth={true}
                      InputLabelProps={{
                        min: this.state.dataInicio,
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} sm={4} style={{ paddingTop: '3px' }}>
                    <CustomInput
                      style={{ paddingTop: '14px' }}
                      labelText="Nome Usuário"
                      id="idUsuario"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: 'idUsuário',
                        onChange: this.handleChange('idUsuario').bind(this),
                        value: this.state.idUsuario,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  spacing={8}
                  style={{ paddingTop: '10px' }}
                >
                  <Grid item>
                    <Button
                      onClick={() => this.fetchData()}
                      color="success"
                      size="sm"
                      block
                    >
                      Pesquisar
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: '32px' }}
                  item
                  xs={12}
                  md={12}
                  sm={12}
                >
                  <ReactTable
                    data={this.state.data}
                    noDataText="Nenhum dado encontrado"
                    filterable
                    defaultFilterMethod={filterMethod}
                    columns={[
                      {
                        Header: 'Nome Cliente',
                        accessor: 'nomeCliente',
                      },
                      {
                        Header: 'Data',
                        accessor: 'dataRegistro',
                        sortMethod: (a, b) => {
                          if (!a || !b) return 0;

                          let dateA = a.split('/');
                          dateA = dateA[2] + '-' + dateA[1] + '-' + dateA[0];
                          dateA = new Date(dateA);

                          let dateB = b.split('/');
                          dateB = dateB[2] + '-' + dateB[1] + '-' + dateB[0];
                          dateB = new Date(dateB);

                          if (dateA > dateB) return -1;
                          if (dateA < dateB) return 1;
                          return 0;
                        },
                      },
                      {
                        Header: 'Início previsto',
                        accessor: 'start',
                      },
                      {
                        Header: 'Início efetivo',
                        accessor: 'efective_start',
                      },
                      {
                        Header: 'Término Previsto',
                        accessor: 'end',
                      },
                      {
                        Header: 'Término Efetivo',
                        accessor: 'efective_end',
                      },
                      {
                        Header: 'Valor Pagamento',
                        accessor: 'valorPagamento',
                        sortMethod: (a, b) => {
                          let valorA = parseFloat(
                            a
                              .replace('R$', '')
                              .replaceAll('.', '')
                              .replace(',', '.')
                          );
                          let valorB = parseFloat(
                            b
                              .replace('R$', '')
                              .replaceAll('.', '')
                              .replace(',', '.')
                          );

                          if (valorA > valorB) return -1;
                          if (valorA < valorB) return 1;
                          return 0;
                        },
                      },
                      {
                        Header: 'Pont',
                        accessor: 'pont',
                      },
                      {
                        Header: 'Temas',
                        accessor: 'theme',
                      },
                      {
                        Header: 'Status',
                        accessor: 'statusPagamento',
                      },
                    ]}
                    // getTdProps={(state, rowInfo, column, instance) => ({
                    //   onClick: () => {
                    //     if (
                    //       column.id !== 'actions' &&
                    //       rowInfo &&
                    //       rowInfo.original
                    //     ) {
                    //       this.props.fetchPaymentPlan(
                    //         rowInfo.original.idStatusPagPlano
                    //       );
                    //       this.setState(
                    //         { paymentSelected: rowInfo.original },
                    //         () => {
                    //           this.handleModalOpen('detailModal');
                    //         }
                    //       );
                    //     }
                    //   },
                    // })}
                    // getTrProps={(state, rowInfo, column) => {
                    //   return {
                    //     style: {
                    //       cursor: 'pointer',
                    //     },
                    //   };
                    // }}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    showPageJump={false}
                    previousText="Anterior"
                    nextText="Próximo"
                    pageText="Página"
                    ofText="de"
                    className="-striped -highlight"
                  />
                </Grid>
                {notification}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

HistoryUsersTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    historyUsers: state.reportsReducer.history_users,
    historyUsersError: state.reportsReducer.history_users_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchHistoryUsers,
    resetHistory,
  })
)(withRouter(HistoryUsersTable));
