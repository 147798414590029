import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import ListAlt from '@material-ui/icons/ListAlt';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import Snackbar from '../../../components/Snackbar/Snackbar.jsx';
import Alert from '@material-ui/icons/NotificationImportant';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx';
import { fetchCurrentUser } from '../../../actions/loginAction';
import { filterMethod } from '../../../helpers/utils';
import '../../../assets/scss/layout/DisputePage.css';
import { fetchHistoryBilling } from '../../../actions/reportsAction';
import { currencyFormat } from '../../../helpers/utils';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const STATUS_USUARIO = 'usuario';
const STATUS_PRESTADOR = 'prestador';
const STATUS_VALOR = 'valor';
const STATUS_TOTAL = '*';

class HistoryListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      billingData: [],
      dataInicio: '',
      dataFim: '',
      typePagamento: '',
      dataInicioError: false,
      dataFimError: false,
      typePagamentoError: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.historyBilling &&
      prevProps.historyBilling != this.props.historyBilling
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          tableData: this.props.historyBilling,
        },
        () => this.handleData(),
        this.showNotification('primary')
      );
    }
    if (
      this.props.historyBillingError &&
      prevProps.historyBillingError != this.props.historyBillingError
    ) {
      this.setState(
        { notificationMessage: this.props.historyBillingError },
        () => this.showNotification('danger')
      );
    }
  }

  handleChange = (name) => (event) => {
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  fetchData() {
    this.setState(
      {
        dataInicioError: '',
        dataFimError: '',
      },
      () => {
        let aprove = true;
        if (
          this.state.dataInicio &&
          this.state.dataFim &&
          this.state.dataInicio > this.state.dataFim
        ) {
          this.setState({
            dataFimError: 'Data final deve ser maior que a data inicial',
          });
          aprove = false;
        }
        if (
          this.state.dataInicio &&
          this.state.dataFim &&
          this.state.dataInicio > this.state.dataFim
        ) {
          this.setState({
            dataFimError: 'Data final deve ser maior que a data inicial',
          });
          aprove = false;
        }
        if (!this.state.dataInicio) {
          this.setState({ dataInicioError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!this.state.dataFim) {
          this.setState({ dataFimError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!aprove) return;
        this.props.fetchHistoryBilling({
          status: '*',
          type: this.state.typePagamento || '*',
          dt_inicial: this.state.dataInicio || '',
          dt_final: this.state.dataFim || '',
        });
      }
    );
  }

  handleData = () => {
    const billingData =
      this.state.tableData.length !== null &&
      this.state.tableData.map((prop) => {
        return {
          id: prop.id ? prop.id : '',
          data_criacao: moment(prop.created_at).format('DD/MM/YYYY HH:mm'),
          data_meeting: moment(prop.meeting_date).format('DD/MM/YYYY HH:mm'),
          meeting_status: prop.meeting_status ? prop.meeting_status : '',
          pont: prop.pont ? prop.pont : '',
          status_pont: prop.status ? prop.status : '',
          type: prop.type ? prop.type : '',
          amount: prop.amount
            ? Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }).format(prop.amount)
            : '',
        };
      });
    this.setState({ billingData });
  };

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <ListAlt />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Grid container justify="flex-end" spacing={32}>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '15px' }}
                  >
                    <TextField
                      id="dataInicio"
                      label="Início"
                      error={!!this.state.dataInicioError}
                      helpText={
                        !this.state.dataInicioError &&
                        this.state.dataInicioErrorMessage
                      }
                      type="date"
                      onChange={this.handleChange('dataInicio').bind(this)}
                      fullWidth={true}
                      inputProps={{ max: '9999-12-31' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '15px' }}
                  >
                    <TextField
                      id="dataFim"
                      label="Término"
                      error={!!this.state.dataFimError}
                      helpText={this.state.dataFimError}
                      type="date"
                      inputProps={{
                        max: '3000-01-01',
                        min: this.state.dataInicio,
                      }}
                      value={this.state.dataFim}
                      onChange={this.handleChange('dataFim').bind(this)}
                      fullWidth={true}
                      InputLabelProps={{
                        min: this.state.dataInicio,
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '16px' }}
                  >
                    <FormControl
                      fullWidth={true}
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="typePagamento">
                        Tipo Cobrança
                      </InputLabel>
                      <Select
                        value={this.state.typePagamento}
                        error={!!this.state.typePagamentoError}
                        onChange={this.handleChange('typePagamento').bind(this)}
                        inputProps={{
                          name: 'typePagamento',
                          id: 'typePagamento',
                        }}
                      >
                        <MenuItem key={1} value={STATUS_USUARIO}>
                          Usuário
                        </MenuItem>
                        <MenuItem key={2} value={STATUS_PRESTADOR}>
                          Prestador
                        </MenuItem>
                        <MenuItem key={3} value={STATUS_VALOR}>
                          Valor
                        </MenuItem>
                        <MenuItem key={4} value={STATUS_TOTAL}>
                          Todos
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  spacing={8}
                  style={{ paddingTop: '10px' }}
                >
                  <Grid item>
                    <Button
                      onClick={() => this.fetchData()}
                      color="success"
                      size="sm"
                      block
                    >
                      Pesquisar
                    </Button>
                  </Grid>
                </Grid>
                <ReactTable
                  data={this.state.billingData}
                  noDataText="Nenhum dado encontrado..."
                  filterable
                  defaultFilterMethod={filterMethod}
                  columns={[
                    {
                      Header: 'Id',
                      accessor: 'id',
                    },
                    {
                      Header: 'Data Criação',
                      accessor: 'data_criacao',
                    },
                    {
                      Header: 'Data Meeting',
                      accessor: 'data_meeting',
                    },
                    {
                      Header: 'Status Meeting',
                      accessor: 'meeting_status',
                    },
                    {
                      Header: 'Pont',
                      accessor: 'pont',
                    },
                    {
                      Header: 'Status Pont',
                      accessor: 'status_pont',
                    },
                    {
                      Header: 'Tipo',
                      accessor: 'type',
                    },
                    {
                      Header: 'Valor',
                      accessor: 'amount',
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  previousText="Anterior"
                  nextText="Próximo"
                  pageText="Página"
                  ofText="de"
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </>
    );
  }
}

HistoryListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    historyBilling: state.reportsReducer.history_billing,
    historyBillingError: state.reportsReducer.history_billing_error,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchCurrentUser, fetchHistoryBilling })
)(withRouter(HistoryListTable));
