import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';


import defaultImage from '../../assets/img/icon-image.png';

import { setThemePhoto } from '../../actions/themesAction';
class PictureUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imagePreviewUrl: '',
      loadedProfilePhoto: false,
      uploadErrorMsg: '',
    };
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userProfilePhoto != this.props.userProfilePhoto) {
      this.setState({ loadedProfilePhoto: false });
    }

    if (this.props.userProfilePhoto) {
      this.setState({
        imagePreviewUrl: 'data:image/*;base64,' + this.props.userProfilePhoto,
        loadedProfilePhoto: true,
      });
    } else if (
      !this.state.loadedProfilePhoto &&
      !!this.props.userProfilePhoto
    ) {
      this.setState({
        imagePreviewUrl: '',
        loadedProfilePhoto: true,
      });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.getBase64(file, (result) => {
        this.setState(
          {
            imagePreviewUrl: 'data:' + file.type + ';base64,' + result,
            uploadErrorMsg: '',
          },
          () => {
            this.props.setThemePhoto(file.type, this.state.imagePreviewUrl);
          }
        );
      });
    }
  }

  getBase64(file, cb) {
    // Less than 300kb, JPEG or PNG.
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      if (file.size <= 300000) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
          cb(reader.result.split('base64,')[1]);
        };
        reader.onerror = function(error) {
          console.log('Error: ', error);
        };
      } else {
        this.setState({
          uploadErrorMsg:
            'O arquivo é muito grande. Apenas imagens de até 300kb podem ser enviadas.',
        });
      }
    } else {
      this.setState({
        uploadErrorMsg:
          'Extensão não suportada. Apenas arquivos JPEG e PNG são permitidos.',
      });
    }
  }

  render() {
    const { visualizationOnly, customCTA, img } = this.props;
    return (
      <div>
        <div className="picture-container">
          <div className="picture">
            <img
              style={{ objectFit: 'cover', height: '100%' }}
              src={
                this.state.imagePreviewUrl !== ''
                  ? this.state.imagePreviewUrl
                  : img ? img : defaultImage
              }
              className="picture-src"
              alt="..."
            />

            <input
              type="file"
              disabled={visualizationOnly ? true : false}
              name="profile_photo"
              onChange={(e) => this.handleImageChange(e)}
            />
          </div>
          <h6 className="description" hidden={visualizationOnly ? true : false}>
            {customCTA ? customCTA : 'Escolha uma imagem para o tema'}
          </h6>
          <small>
            Tamanho máximo: <b>300 Kb</b>
          </small>
        </div>
        <div className="picture-container">
          <span
            name="upload_error"
            style={{ color: 'red' }}
            hidden={!this.state.uploadErrorMsg ? true : false}
          >
            {this.state.uploadErrorMsg}
          </span>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, {
    setThemePhoto,
  })
)(withRouter(PictureUpload));
