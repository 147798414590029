import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import ReplyIcon from '@material-ui/icons/Reply';
import Close from '@material-ui/icons/Close';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
// import PictureUpload from "@views/Components/PictureUpload.jsx";

import { fetchCurrentUser } from '../../../actions/loginAction';
import {
  getBGType,
  updateBgType,
  deleteBgType,
  resetBGTypesInfo,
} from '../../../actions/bgTypesAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Alert from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import PictureUpload2 from '../../Components/PictureUpload2'

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EditBgTypesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      isValidType: true,
      typeValidationMessage: '',
      order: '',
      isValidId: true,
      idValidationMessage: '',
      deleteUserConfirmationModal: false,
      deleteUserConfirmationToggle: false,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (!id) return;
    this.props.getBGType(id);
  }

  componentWillUnmount() {
    this.props.resetBGTypesInfo();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.editBgType &&
      this.props.editBgType !== prevProps.editBgType
    ) {
      this.setState({
        type: this.props ? this.props.editBgType.type : '-',
        order:
          this.props.editBgType.order === null
            ? '-'
            : this.props.editBgType.order,
      });
    }

    if (
      this.props.editBgTypeError &&
      prevProps.editBgTypeError !== this.props.eeditBgTypeError
    ) {
      this.setState(
        {
          notificationMessage: this.props.editBgTypeError,
        },
        () => this.showNotification('danger')
      );
    }
    if (
      this.props.updateBgType &&
      prevProps.updateBgType !== this.props.updateBgType
    ) {
      this.setState(
        {
          notificationMessage: 'Formação Acadêmica alterada com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.updateBgTypeError &&
      prevProps.updateBgTypeError !== this.props.updateBgTypeError
    ) {
      this.setState({ notificationMessage: this.props.updateBgTypeError }, () =>
        this.showNotification('danger')
      );
    }
    if (
      this.props.deleteBgType &&
      prevProps.deleteBgType !== this.props.deleteBgType
    ) {
      this.setState(
        {
          notificationMessage: 'Formação Acadêmica deletada com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.deleteBgTypeError &&
      prevProps.deleteBgTypeError !== this.props.deleteBgTypeError
    ) {
      this.setState({ notificationMessage: this.props.deleteBgTypeError }, () =>
        this.showNotification('danger')
      );
    }
  }

  handleOnChangeType(event) {
    this.setState({ type: event.target.value });
  }

  handleOnChangeId(event) {
    this.setState({ order: event.target.value });
  }

  handleDeleteBgType() {
    this.props.deleteBgType(this.props.editBgType.id);
  }

  handleSubmit() {
    if (this.state.type.length < 3) {
      this.setState({
        isValidType: false,
        typeValidationMessage: 'O tipo deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState({
        isValidType: true,
        typeValidationMessage: '',
      });
    }

    if (this.state.order === "") {
      this.setState({
        isValidId: false,
        idValidationMessage: 'O número de ordem deve ser informado.',
      });
    } else {
      this.setState({
        isValidId: true,
        idValidationMessage: '',
      });
    }


   

    let id = this.props.editBgType && this.props.editBgType.id;

    if (this.state.isValidType && this.state.order ) {
      this.props.updateBgType(id, this.state.order, this.state.type  );
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetBGTypesInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let deleteUserConfirmationModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.deleteUserConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('deleteUserConfirmationModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Excluir background</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Após excluído, a Formação Acadêmica em questão não constará mais
              no sistema. Tem certeza que deseja continuar?
            </h5>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.deleteUserConfirmationToggle}
                    onChange={this.handleChange('deleteUserConfirmationToggle')}
                    value="deleteUserConfirmationToggle"
                    color="primary"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Sim. Desejo excluir este background."
              />
            </div>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.handleDeleteBgType()}
              color="success"
              simple
              disabled={!this.state.deleteUserConfirmationToggle}
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => this.handleClose('deleteUserConfirmationModal')}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/tipo-background/lista">
                  <ReplyIcon className={classes.icon} />
                  Formação Acadêmica
                </Link>
                <Typography color="textPrimary">Editar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                <CustomInput
                    labelText="Número de Ordem"
                    id="id"
                    error={!this.state.isValidId}
                    helpText={
                      !this.state.isValidId &&
                      this.state.idValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'id',
                      onChange: this.handleOnChangeId.bind(this),
                      value: this.state.order,
                    }}
                  />
                  <CustomInput
                    labelText="Tipo"
                    id="type"
                    error={!this.state.isValidType}
                    helpText={
                      !this.state.isValidType &&
                      this.state.typeValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'type',
                      onChange: this.handleOnChangeType.bind(this),
                      value: this.state.type,
                    }}
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Check /> Salvar
                  </Button>
                  <Button
                    onClick={() => {
                      this.handleClickOpen('deleteUserConfirmationModal');
                    }}
                    color="danger"
                  >
                    <Close /> Excluir
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {deleteUserConfirmationModal}
        {notification}
      </div>
    );
  }
}

EditBgTypesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    editBgType: state.bgTypesReducer.bgtype_page,
    editBgTypeError: state.bgTypesReducer.bgtype_page_error,
    updateBgType: state.bgTypesReducer.update_bgtype,
    updateBgTypeError: state.bgTypesReducer.update_bgtype_error,
    deleteBgType: state.bgTypesReducer.delete_bgtype,
    deleteBgTypeError: state.bgTypesReducer.delete_bgtype_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    getBGType,
    updateBgType,
    deleteBgType,
    resetBGTypesInfo,
  })
)(withRouter(EditBgTypesPage));
