import React from "react";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import cloneDeep from "lodash/cloneDeep";
import indexRoutes from "./routes/main";
import "./assets/scss/material-dashboard-pro-react.min.css?v=1.5.0";
import "./assets/css/custom.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function extractRoutes() {
  let routes = cloneDeep(indexRoutes);
  let extractedRoutes = [];
  routes.forEach((prop, key) => {
    if (prop.collapse) {
      for (let i = 0; i < prop.views.length; i++) {
        extractedRoutes.push({
          path: prop.views[i].path,
          component: prop.views[i].component,
        });
      }
    } else {
      extractedRoutes.push({ path: prop.path, component: prop.component });
    }
  });
  return extractedRoutes;
}

const App = (props) => {
  return (
    <Switch>
      {extractRoutes().map((prop, key) => {
        return <Route path={prop.path} key={key} component={prop.component} />;
      })}
    </Switch>
  );
};

export default App;
