import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// material-ui icons
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';

import { fetchCurrentUser } from '../../../actions/loginAction';
import {
  createBgTypes,
  resetBGTypesInfo,
} from '../../../actions/bgTypesAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import ReplyIcon from '@material-ui/icons/Reply';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import Alert from '@material-ui/icons/NotificationImportant';
import Snackbar from '../../../components/Snackbar/Snackbar';
// import PictureUpload2 from '../../Components/PictureUpload2'

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

class AddBgTypesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      isValidType: true,
      typeValidationMessage: '',
      id: '',
      isValidId: true,
      idValidationMessage: '',
    };
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.bgTypesNew &&
      prevProps.bgTypesNew !== this.props.bgTypesNew
    ) {
      this.setState(
        {
          notificationMessage: 'Formação Acadêmica adicionada com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.bgTypesNewError &&
      prevProps.bgTypesNewError !== this.props.bgTypesNewError
    ) {
      this.setState(
        {
          notificationMessage: 'Falha no cadastro do background',
        },
        () => this.showNotification('danger')
      );
    }
  }

  handleOnChangeType(event) {
    this.setState({ type: event.target.value });
  }

  handleOnChangeID(event) {
    this.setState({ id: event.target.value });
  }

  handleSubmit() {
    if (this.state.type.length < 3) {
      this.setState({
        isValidType: false,
        typeValidationMessage: 'O título deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState({
        isValidType: true,
        typeValidationMessage: '',
      });
    }
    if (this.state.id === "") {
      this.setState({
        isValidId: false,
        idValidationMessage: 'O número de ordem deve ser informado.',
      });
    } else {
      this.setState({
        isValidId: true,
        idValidationMessage: '',
      });
    }

    let payload = {
      order: this.state.id,
      type: this.state.type,
      
    };

    if (this.state.isValidType && this.state.id) {
      this.props.createBgTypes(payload);
    }
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetBGTypesInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/tipo-background/lista">
                  <ReplyIcon className={classes.icon} />
                  Formação Acadêmica
                </Link>
                <Typography color="textPrimary">Adicionar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                <CustomInput
                    labelText="Número de Ordem"
                    id="id"
                    error={!this.state.isValidId}
                    helpText={
                      !this.state.isValidId &&
                      this.state.idValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'id',
                      onChange: this.handleOnChangeID.bind(this),
                      value: this.state.id,
                    }}
                  />
                  <CustomInput
                    labelText="Tipo"
                    id="type"
                    error={!this.state.isValidType}
                    helpText={
                      !this.state.isValidType &&
                      this.state.typeValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'type',
                      onChange: this.handleOnChangeType.bind(this),
                      value: this.state.type,
                    }}
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Check /> Cadastrar
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </div>
    );
  }
}

AddBgTypesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    bgTypesNew: state.bgTypesReducer.bgtypes_new_page,
    bgTypesNewError: state.bgTypesReducer.bgtypes_new_page_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    createBgTypes,
    resetBGTypesInfo,
  })
)(withRouter(AddBgTypesPage));
