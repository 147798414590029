import {
  FETCH_DASHBOARD_USERS,
  FETCH_DASHBOARD_USERS_ERROR,
  FETCH_DASHBOARD_CLIENTS,
  FETCH_DASHBOARD_CLIENTS_ERROR,
  RESET_DASH_INFO,
  FETCH_DASHBOARD_CLIENTS_BANNED,
  FETCH_DASHBOARD_CLIENTS_BANNED_ERROR,
  FETCH_DASHBOARD_CLIENTS_VALIDATION,
  FETCH_DASHBOARD_CLIENTS_VALIDATION_ERROR,
  FETCH_DASHBOARD_OPENED_DISPUTES,
  FETCH_DASHBOARD_OPENED_DISPUTES_ERROR,
  FETCH_DASHBOARD_AMOUNT_RECEIVED,
  FETCH_DASHBOARD_AMOUNT_RECEIVED_ERROR,
} from './types';
import { handleResponseError } from './handleResponseError';

export const fetchClientes = () => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers['token'] = token;

  const res = await connections.apiAdmin
    .get('/dashboards/qtdClients', {})
    .then((res) => {
      dispatch({
        type: FETCH_DASHBOARD_CLIENTS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_DASHBOARD_CLIENTS}: ${e.message}`);
      dispatch({
        type: FETCH_DASHBOARD_CLIENTS_ERROR,
        payload: handleResponseError(
          e,
          'Ocorreu um erro ao carregar o dashboard'
        ),
      });
    });
};

export const fetchUsersMeeting = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/dashboards/meetingsDone', {})
      .then((res) => {
        dispatch({
          type: FETCH_DASHBOARD_USERS,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_DASHBOARD_USERS}: ${e.message}`);
        dispatch({
          type: FETCH_DASHBOARD_USERS_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_DASHBOARD_USERS_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchClientsBanned = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/dashboards/qtdClientsBanned', {})
      .then((res) => {
        dispatch({
          type: FETCH_DASHBOARD_CLIENTS_BANNED,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(
          `action: ${FETCH_DASHBOARD_CLIENTS_BANNED}: ${e.message}`
        );
        dispatch({
          type: FETCH_DASHBOARD_CLIENTS_BANNED_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_DASHBOARD_CLIENTS_BANNED_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchClientsValidation = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/dashboards/pontsValidation', {})
      .then((res) => {
        dispatch({
          type: FETCH_DASHBOARD_CLIENTS_VALIDATION,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(
          `action: ${FETCH_DASHBOARD_CLIENTS_VALIDATION}: ${e.message}`
        );
        dispatch({
          type: FETCH_DASHBOARD_CLIENTS_VALIDATION_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_DASHBOARD_CLIENTS_VALIDATION_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchOpenedDisputes = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/dashboards/openedDisputes', {})
      .then((res) => {
        dispatch({
          type: FETCH_DASHBOARD_OPENED_DISPUTES,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(
          `action: ${FETCH_DASHBOARD_OPENED_DISPUTES}: ${e.message}`
        );
        dispatch({
          type: FETCH_DASHBOARD_OPENED_DISPUTES_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_DASHBOARD_OPENED_DISPUTES_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchAmountReceived = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/dashboards/amountReceived', {})
      .then((res) => {
        dispatch({
          type: FETCH_DASHBOARD_AMOUNT_RECEIVED,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(
          `action: ${FETCH_DASHBOARD_AMOUNT_RECEIVED}: ${e.message}`
        );
        dispatch({
          type: FETCH_DASHBOARD_AMOUNT_RECEIVED_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_DASHBOARD_AMOUNT_RECEIVED_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const resetDashInfo = () => async (dispatch, getState, connections) => {
  dispatch({
    type: RESET_DASH_INFO,
  });
};
