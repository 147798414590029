import {
  FETCH_BILLS_TO_RECEIVE,
  FETCH_BILLS_TO_RECEIVE_ERROR,
  RESET_BILLS_TO_RECEIVE,
} from '../actions/types';

const INITIAL_STATE = [{ dispute: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BILLS_TO_RECEIVE:
      return {
        ...state,
        bills_to_receive: action.payload,
      };
    case FETCH_BILLS_TO_RECEIVE_ERROR:
      return {
        ...state,
        bills_to_receive_error: action.payload,
      };

    case RESET_BILLS_TO_RECEIVE:
      return {
        ...state,
        bills_to_receive: null,
        bills_to_receive_error: null,
      };
    default:
      return state;
  }
};
