import {
  FETCH_THEMES,
  FETCH_THEMES_ERROR,
  FETCH_ADD_NEW_THEME,
  FETCH_ADD_NEW_THEME_ERROR,
  FETCH_THEME,
  FETCH_THEME_ERROR,
  UPDATE_THEME,
  UPDATE_THEME_ERROR,
  DELETE_THEME,
  DELETE_THEME_ERROR,
  SET_THEME_PHOTO,
  RESET_THEME_PHOTO,
  RESET_THEME_INFO,
} from '../actions/types';

const INITIAL_STATE = [{ themes: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_THEMES:
      return {
        ...state,
        themes_page: action.payload,
      };
    case FETCH_THEMES_ERROR:
      return { ...state, themes_error: action.payload };
    case FETCH_ADD_NEW_THEME:
      return {
        ...state,
        themes_page_new: action.payload,
      };
    case FETCH_ADD_NEW_THEME_ERROR:
      return { ...state, themes_new_page_error: action.payload };
    case FETCH_THEME:
      return {
        ...state,
        theme_page: action.payload,
      };
    case FETCH_THEME_ERROR:
      return { ...state, theme_page_error: action.payload };
    case UPDATE_THEME:
      return {
        ...state,
        update_theme: action.payload,
      };
    case UPDATE_THEME_ERROR:
      return { ...state, update_theme_error: action.payload };
    case SET_THEME_PHOTO:
      return { ...state, themePhoto: action.payload };
    case RESET_THEME_PHOTO:
      return { ...state, themePhoto: null };
    case DELETE_THEME:
      return {
        ...state,
        delete_theme: action.payload,
      };
    case DELETE_THEME_ERROR:
      return { ...state, delete_theme_error: action.payload };
    case RESET_THEME_INFO:
      return {
        ...state,
        themes_page: null,
        themes_error: null,
        themes_page_new: null,
        themes_new_page_error: null,
        theme_page: null,
        theme_error: null,
        update_theme: null,
        update_theme_error: null,
        delete_theme: null,
        delete_theme_error: null,
      };
    default:
      return state;
  }
};
