import {
  FETCH_BGTYPES,
  FETCH_BGTYPES_ERROR,
  FETCH_ADD_NEW_BG,
  FETCH_ADD_NEW_BG_ERROR,
  FETCH_BGTYPE,
  FETCH_BGTYPE_ERROR,
  UPDATE_BGTYPES,
  UPDATE_BGTYPES_ERROR,
  DELETE_BGTYPES,
  DELETE_BGTYPES_ERROR,
  RESET_BGTYPES_INFO,
} from '../actions/types';

const INITIAL_STATE = [{ themes: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BGTYPES:
      return {
        ...state,
        bgtypes_page: action.payload,
      };
    case FETCH_BGTYPES_ERROR:
      return { ...state, bgtypes_page_error: action.payload };
    case FETCH_ADD_NEW_BG:
      return {
        ...state,
        bgtypes_new_page: action.payload,
      };
    case FETCH_ADD_NEW_BG_ERROR:
      return { ...state, bgtypes_new_page_error: action.payload };
    case FETCH_BGTYPE:
      return {
        ...state,
        bgtype_page: action.payload,
      };
    case FETCH_BGTYPE_ERROR:
      return { ...state, bgtype_page_error: action.payload };
    case UPDATE_BGTYPES:
      return {
        ...state,
        update_bgtype: action.payload,
      };
    case UPDATE_BGTYPES_ERROR:
      return { ...state, update_bgtype_error: action.payload };
    case DELETE_BGTYPES:
      return {
        ...state,
        delete_bgtype: action.payload,
      };
    case DELETE_BGTYPES_ERROR:
      return { ...state, delete_bgtype_error: action.payload };
    case RESET_BGTYPES_INFO:
      return {
        ...state,
        bgtypes_page: null,
        bgtypes_page_error: null,
        bgtypes_new_page: null,
        bgtypes_new_page_error: null,
        bgtype_page: null,
        bgtype_page_error: null,
        update_bgtype: null,
        update_bgtype_error: null,
        delete_bgtype: null,
        delete_bgtype_error: null,
      };
    default:
      return state;
  }
};
