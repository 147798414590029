import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import history from '../../../history';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import { fetchCurrentUser } from '../../../actions/loginAction';
import {
  fetchEditBillsToPay,
  fetchAddPaymentInformation,
  resetBillsToPay,
} from '../../../actions/billsToPay';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Alert from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EditBillsToPayPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editBillsToPay: [],
      id: this.props.match.params.id,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (!id) return;

    this.props.fetchEditBillsToPay(id);
  }

  componentWillUnmount() {
    this.props.resetBillsToPay();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.editBillsToPay &&
      prevProps.editBillsToPay !== this.props.editBillsToPay
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          editBillsToPay: this.props.editBillsToPay,
        },
        () => this.showNotification('primary')
      );
    }

    if (
      this.props.editBillsToPayError &&
      prevProps.editBillsToPayError != this.props.editBillsToPayError
    ) {
      this.setState(
        { notificationMessage: this.props.editBillsToPayError },
        () => this.showNotification('danger')
      );
    }

    if (prevProps.addPayment !== this.props.addPayment) {
      this.setState(
        {
          notificationMessage: 'Pagamento confirmado com sucesso',
        },
        () => this.showNotification('primary')
      );
    }

    if (this.props.addPaymentError) {
      this.setState(
        {
          notificationMessage: 'Falha',
        },
        () => this.showNotification('danger')
      );
    }
  }

  handleModalOpen(modal) {
    this.setState({ [modal]: true });
  }

  handleModalClose(modal) {
    this.setState({ [modal]: false });
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      // this.props.resetBillsToPay();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    console.log(this.props.editBillsToPay);
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let confirmPaymentModal = (
      <GridContainer justify="center">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.confirmPaymentModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleModalClose('confirmPaymentModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h5 className={classes.modalTitle}>Confirmar pagamento</h5>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Deseja prosseguir com a confirmação do pagamento, referente ao Id{' '}
              {this.state.editBillsToPay && this.state.editBillsToPay.id}?
            </h5>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => {
                this.props.fetchAddPaymentInformation(this.state.id);
                this.handleModalClose('confirmPaymentModal');
                this.setState(
                  { notificationMessage: 'Pagamento confirmado com sucesso' },
                  () => this.showNotification('primary')
                );
              }}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                this.handleModalClose('aproveModal');
              }}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );
    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/pagamentos/lista">
                  <ReplyIcon className={classes.icon} />
                  Pagamentos
                </Link>
                <Typography color="textPrimary">Editar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Grid container spacing={8} style={{ marginBottom: '8px' }}>
                  <Grid item>
                    <Typography
                      variant="subheading"
                      gutterBottom
                      style={{ textTransform: 'none' }}
                    >
                      Informações do Pagamento
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container justify="space-between">
                  <Grid item xs={6} md={6} sm={6}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ textTransform: 'none' }}
                        >
                          Id:{' '}
                          {this.state.editBillsToPay
                            ? this.state.editBillsToPay.id
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ textTransform: 'none' }}
                        >
                          Meeting Id:{' '}
                          {this.state.editBillsToPay
                            ? this.state.editBillsToPay.meeting_id
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ textTransform: 'none' }}
                        >
                          Data Criação:{' '}
                          {this.state.editBillsToPay
                            ? moment(
                                this.state.editBillsToPay.created_at
                              ).format('DD/MM/YYYY')
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ textTransform: 'none' }}
                        >
                          Valor:{' '}
                          {this.state.editBillsToPay
                            ? Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(this.state.editBillsToPay.amount)
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ textTransform: 'none' }}
                        >
                          Status:{' '}
                          {this.state.editBillsToPay
                            ? this.state.editBillsToPay.status
                            : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <Button
                    onClick={() => this.handleModalOpen('confirmPaymentModal')}
                    color="success"
                    simple
                    disabled={
                      this.state.editBillsToPay &&
                      this.state.editBillsToPay.status === 'close'
                    }
                  >
                    Confirmar pagamento
                  </Button>
                  <Button
                    onClick={() => history.push('/pagamentos/lista')}
                    link
                    simple
                  >
                    Cancelar
                  </Button>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {confirmPaymentModal}
        {notification}
      </div>
    );
  }
}

EditBillsToPayPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    editBillsToPay: state.billsToPayReducer.edit_bills_to_pay,
    editBillsToPayError: state.billsToPayReducer.edit_bills_to_pay_error,
    addPayment: state.billsToPayReducer.add_payment_information,
    addPaymentError: state.billsToPayReducer.add_payment_information_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchEditBillsToPay,
    fetchAddPaymentInformation,
    resetBillsToPay,
  })
)(withRouter(EditBillsToPayPage));
