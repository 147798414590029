import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import ListAlt from '@material-ui/icons/ListAlt';
import Edit from '@material-ui/icons/Edit';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import Snackbar from '../../../components/Snackbar/Snackbar.jsx';
import Alert from '@material-ui/icons/NotificationImportant';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Drawer from '@material-ui/core/Drawer';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FilterList from '@material-ui/icons/FilterList';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import RefreshIcon from '@material-ui/icons/Refresh';

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx';
import { fetchCurrentUser } from '../../../actions/loginAction';
import history from '../../../history';
import { filterMethod } from '../../../helpers/utils';
import '../../../assets/scss/layout/DisputePage.css';
import { fetchContestation } from '../../../actions/reportsAction';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class HistoryListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      disputeData: [],
    };
  }

  componentDidMount() {
    this.props.fetchContestation();
    this.handleData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setState({ tableData: this.props.tableData }, () =>
        this.handleData()
      );
    }

    if (
      this.props.historyContest &&
      prevProps.historyContest != this.props.historyContest
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          tableData: this.props.historyContest,
        },
        () => this.handleData(),
        this.showNotification('primary')
      );
    }
    if (
      this.props.historyContestError &&
      prevProps.historyContestError != this.props.historyContestError
    ) {
      this.setState(
        { notificationMessage: this.props.historyContestError },
        () => this.showNotification('danger')
      );
    }
  }

  handleData = () => {
    const disputeData =
      this.state.tableData.length !== null &&
      this.state.tableData.map((prop) => {
        return {
          id_pont: prop.pont_id,
          id_cliente: prop.client_id,
          data_criacao: moment(prop.created_at).format('DD/MM/YYYY HH:mm'),
          cliente: prop && prop.client ? prop.client.name : '',
          email: prop && prop.client ? prop.client.email : '',
          descricao: prop && prop.description,
          pont_blocked: prop.pont
            ? prop.pont.blocked === false
              ? 'Ativo'
              : 'Bloqueado'
            : '',
          meeting_status: prop.meeting ? prop.meeting.status : '',
        };
      });
    this.setState({ disputeData });
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    console.log(this.state.tableData);
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <ListAlt />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.disputeData}
                  noDataText="Carregando dados..."
                  filterable
                  defaultFilterMethod={filterMethod}
                  columns={[
                    {
                      Header: 'Pont Id',
                      accessor: 'id_pont',
                    },
                    {
                      Header: 'Cliente Id',
                      accessor: 'id_cliente',
                    },
                    {
                      Header: 'Data Criação',
                      accessor: 'data_criacao',
                    },
                    {
                      Header: 'Cliente',
                      accessor: 'cliente',
                    },
                    {
                      Header: 'Email',
                      accessor: 'email',
                    },
                    {
                      Header: 'Situação Pont',
                      accessor: 'pont_blocked',
                    },

                    {
                      Header: 'Situação Meeting',
                      accessor: 'meeting_status',
                    },
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => ({
                    // onClick: () => {
                    //   if (column.id !== "actions" && rowInfo) {
                    //     history.push("/disputas/"+rowInfo.original.id)
                    //   }
                    // }
                  })}
                  getTrProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        cursor: 'pointer',
                      },
                    };
                  }}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  previousText="Anterior"
                  nextText="Próximo"
                  pageText="Página"
                  ofText="de"
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </>
    );
  }
}

HistoryListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    historyContest: state.reportsReducer.history_contest,
    historyContestError: state.reportsReducer.history_contest_error,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchCurrentUser, fetchContestation })
)(withRouter(HistoryListTable));
