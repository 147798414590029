import {
  FETCH_BILLS_TO_RECEIVE,
  FETCH_BILLS_TO_RECEIVE_ERROR,
  RESET_BILLS_TO_RECEIVE,
} from './types';
import { handleResponseError } from './handleResponseError';
import history from '../history';

export const fetchBillsToReceive = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/payments/receipts/all')
      .then((res) => {
        dispatch({
          type: FETCH_BILLS_TO_RECEIVE,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_BILLS_TO_RECEIVE}: ${e.message}`);
        dispatch({
          type: FETCH_BILLS_TO_RECEIVE_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_BILLS_TO_RECEIVE_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const resetBillsToReceive = () => async (dispatch) => {
  dispatch({
    type: RESET_BILLS_TO_RECEIVE,
  });
};
