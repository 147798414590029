import RoutedApp from './RoutedApp';
import { Provider, useSelector, useDispatch } from 'react-redux';
import Router from 'react-router-dom/Router';
import React, { useEffect } from 'react';
import { logger } from 'redux-logger';
import JssProvider from 'react-jss/lib/JssProvider';
import {
  MuiThemeProvider,
  createMuiTheme,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import Snackbar from './components/Snackbar/Snackbar.jsx';
import reducers from './reducers';
import history from './history';
import Alert from '@material-ui/icons/NotificationImportant';
import { apiIp } from './config';
import { hideNotification } from './actions/notificationAction';

export const url = apiIp;

const axiosInstanceAdmin = axios.create({
  baseURL: url,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstanceAdmin.interceptors.request.use((config) => {
  const userToken = sessionStorage.getItem('userToken');
  if (userToken) {
    config.headers.Authorization = `Bearer ${userToken}`;
  }
  return config;
});
// 401 Handler
axiosInstanceAdmin.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('userToken');
      sessionStorage.removeItem('userProfile');
      window.location = '/auth/login';
    } else {
      return Promise.reject(error);
    }
  }
);

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

// Create a new class name generator.
const generateClassName = createGenerateClassName();
let middlewares = [];
if (process.env.NODE_ENV === 'development') middlewares.push(logger);

const store = createStore(
  reducers,
  window.INITIAL_STATE,
  applyMiddleware(
    thunk.withExtraArgument({
      apiAdmin: axiosInstanceAdmin,
    }),
    ...middlewares
  )
);

export default function App() {
  return (
    <Provider store={store}>
      <JssProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <RoutedApp />
          </Router>
          <Notification />
        </MuiThemeProvider>
      </JssProvider>
    </Provider>
  );
}

function Notification() {
  const { opened, message = 'OK!', type = 'success' } = useSelector(
    ({ notificationReducer }) => notificationReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        dispatch(hideNotification());
      }, 4000);
    }
  }, [opened, dispatch]);

  return (
    <Snackbar
      place="br"
      color={type}
      icon={Alert}
      message={message}
      open={opened}
      closeNotification={() => dispatch(hideNotification())}
      close
    />
  );
}
