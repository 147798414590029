import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// material-ui icons
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import PictureUpload from '../../Components/PictureUpload';

import { fetchCurrentUser } from '../../../actions/loginAction';
import {
  createTheme,
  setThemePhoto,
  resetThemeInfo,
} from '../../../actions/themesAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import ReplyIcon from '@material-ui/icons/Reply';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import Alert from '@material-ui/icons/NotificationImportant';
import Snackbar from '../../../components/Snackbar/Snackbar';

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

class AddThemePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      isValidTitle: true,
      titleValidationMessage: '',
      description: '',
      isValidDescription: true,
      descriptionValidationMessage: '',
      themePhoto: '',
    };
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (this.props.themeNew && prevProps.themeNew !== this.props.themeNew) {
      this.setState(
        {
          notificationMessage: 'Tema adicionado com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.themeNewError &&
      prevProps.themeNewError !== this.props.themeNewError
    ) {
      this.setState(
        {
          notificationMessage: 'Falha no cadastro do tema',
        },
        () => this.showNotification('danger')
      );
    }
    if (this.props.themePhoto && !!this.state.themePhoto) {
      this.setState({
        themePhoto: this.props.themePhoto,
      });
    }
  }

  handleOnChangeTitle(event) {
    this.setState({ title: event.target.value });
  }

  handleOnChangeDescription(event) {
    this.setState({ description: event.target.value });
  }

  handleSubmit() {
    if (this.state.title.length < 3) {
      this.setState({
        isValidTitle: false,
        titleValidationMessage: 'O título deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState({
        isValidTitle: true,
        titleValidationMessage: '',
      });
    }
    let payload = {
      title: this.state.title,
      image: this.props.themePhoto,
    };

    if (this.state.isValidTitle) {
      this.props.createTheme(payload);
    }
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetThemeInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    console.log(this.state.themePhoto)
    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/temas/lista">
                  <ReplyIcon className={classes.icon} />
                  Temas
                </Link>
                <Typography color="textPrimary">Adicionar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                  <CustomInput
                    labelText="Título"
                    id="title"
                    error={!this.state.isValidTitle}
                    helpText={
                      !this.state.isValidTitle &&
                      this.state.titleValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'title',
                      onChange: this.handleOnChangeTitle.bind(this),
                      value: this.state.title,
                    }}
                  />
                  <CardBody>
                    <PictureUpload
                      visualizationOnly={false}
                      userProfilePhoto={this.state.themePhoto} //imagem base64
                      decode={false}
                      customCTA="Escolher imagem do tema"
                    />
                  </CardBody>
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Check /> Cadastrar
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </div>
    );
  }
}

AddThemePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    themeNew: state.themesReducer.themes_page_new,
    themesNewError: state.themesReducer.themes_new_page_error,
    themePhoto: state.themesReducer.themePhoto,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    createTheme,
    setThemePhoto,
    resetThemeInfo,
  })
)(withRouter(AddThemePage));
