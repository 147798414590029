import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Face from '@material-ui/icons/Face';
import DescriptionIcon from '@material-ui/icons/Description';
import Person from '@material-ui/icons/Person';
import Receipt from '@material-ui/icons/Receipt';
import ListAlt from '@material-ui/icons/ListAlt';
import Forum from '@material-ui/icons/Forum';
import PaymentIcon from '@material-ui/icons/Payment';
import Grid from '@material-ui/core/Grid';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardFooter from '../../components/Card/CardFooter.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Snackbar from '../../components/Snackbar/Snackbar.jsx';
import Alert from '@material-ui/icons/NotificationImportant';
import RefreshIcon from '@material-ui/icons/Refresh';

import dashboardStyle from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  fetchUsersMeeting,
  fetchClientes,
  fetchClientsBanned,
  fetchClientsValidation,
  fetchOpenedDisputes,
  fetchAmountReceived,
  resetDashInfo,
} from '../../actions/dashboardAction';
import { withRouter } from 'react-router';
import Button from '../../components/CustomButtons/Button.jsx';
import history from '../../history';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      clients: {},
      meetings: 0,
      banned: {},
      validation: 0,
      opened_disputes: 0,
      amount: 0,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.resetDashInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.clients_dash &&
      prevProps.clients_dash !== this.props.clients_dash
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          clients: this.props.clients_dash,
        },
        () => this.showNotification()
      );
    }
    if (
      this.props.clientsError &&
      prevProps.clientsError !== this.props.clientsError
    ) {
      this.setState({ notificationMessage: this.props.clientsError }, () =>
        this.showNotification('danger')
      );
    }
    if (
      this.props.users_dash &&
      prevProps.users_dash !== this.props.users_dash
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          meetings: this.props.users_dash,
        },
        () => this.showNotification()
      );
    }
    if (
      this.props.usersError &&
      prevProps.usersError != this.props.usersError
    ) {
      this.setState({ notificationMessage: this.props.usersError }, () =>
        this.showNotification('danger')
      );
    }
    if (
      this.props.clients_banned &&
      prevProps.clients_banned !== this.props.clients_banned
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          banned: this.props.clients_banned,
        },
        () => this.showNotification()
      );
    }
    if (
      this.props.clients_banned_error &&
      prevProps.clients_banned_error != this.props.clients_banned_error
    ) {
      this.setState(
        { notificationMessage: this.props.clients_banned_error },
        () => this.showNotification('danger')
      );
    }
    if (
      this.props.clients_validation &&
      prevProps.clients_validation !== this.props.clients_validation
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          validation: this.props.clients_validation,
        },
        () => this.showNotification()
      );
    }
    if (
      this.props.clients_validation_error &&
      prevProps.clients_validation_error != this.props.clients_validation_error
    ) {
      this.setState(
        { notificationMessage: this.props.clients_validation_error },
        () => this.showNotification('danger')
      );
    }

    if (
      this.props.opened_disputes &&
      prevProps.opened_disputes !== this.props.opened_disputes
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          opened_disputes: this.props.opened_disputes,
        },
        () => this.showNotification()
      );
    }
    if (
      this.props.opened_disputes_error &&
      prevProps.opened_disputes_error !== this.props.opened_disputes_error
    ) {
      this.setState(
        { notificationMessage: this.props.clients_validation_error },
        () => this.showNotification('danger')
      );
    }

    if (this.props.amount && prevProps.amount != this.props.amount) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          amount: this.props.amount,
        },
        () => this.showNotification()
      );
    }
    if (
      this.props.amount_received_error &&
      prevProps.amount_received_error !== this.props.amount_received_error
    ) {
      this.setState(
        { notificationMessage: this.props.amount_received_error },
        () => this.showNotification('danger')
      );
    }
  }

  fetchData() {
    this.props.fetchUsersMeeting();
    this.props.fetchClientes();
    this.props.fetchClientsBanned();
    this.props.fetchClientsValidation();
    this.props.fetchOpenedDisputes();
    this.props.fetchAmountReceived();
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    console.log(this.state.amount, this.props.amount);

    return (
      <div>
        <GridContainer justify="flex-end">
          <Grid item>
            <Button onClick={() => this.fetchData()} color="primary" size="sm">
              <RefreshIcon /> Atualizar
            </Button>
          </Grid>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card style={{ height: '220px' }}>
              <CardHeader color="success" stats icon>
                <CardIcon color="info">
                  <Person />
                </CardIcon>
                <p className={classes.cardCategory}>Quantidade de usuários</p>
                <hr />
                <Grid container spacing={8} justify="flex-end">
                  <Grid item>
                    <h3 className={classes.cardTitle}>
                      {this.state.clients.normal}
                    </h3>
                  </Grid>
                  <Grid item>
                    <p className={classes.cardCategory}>Normal</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8} justify="flex-end">
                  <Grid item>
                    <h3 className={classes.cardTitle}>
                      {this.state.clients.pont}
                    </h3>
                  </Grid>
                  <Grid item>
                    <p className={classes.cardCategory}>PONT</p>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.push('/relatorios/usuarios');
                    }}
                  >
                    Ver usuários
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card style={{ height: '220px' }}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="rose">
                  <Forum />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Quantidade de chamadas realizadas
                </p>
                <hr />
                <Grid container spacing={8} justify="flex-end">
                  <Grid item>
                    <h3 className={classes.cardTitle}>{this.state.meetings}</h3>
                  </Grid>
                  <Grid item>
                    <p className={classes.cardCategory}>Meetings</p>
                  </Grid>
                </Grid>
                x
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.push('/relatorios/chamadas');
                    }}
                  >
                    Ver chamadas
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card style={{ height: '220px' }}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="primary">
                  <Face />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {' '}
                  Quantidade de usuários banidos
                </p>
                <hr />
                <Grid container spacing={8} justify="flex-end">
                  <Grid item>
                    <h3 className={classes.cardTitle}>
                      {this.state.banned.normal}
                    </h3>
                  </Grid>
                  <Grid item>
                    <p className={classes.cardCategory}>Normal</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8} justify="flex-end">
                  <Grid item>
                    <h3 className={classes.cardTitle}>
                      {this.state.banned.pont}
                    </h3>
                  </Grid>
                  <Grid item>
                    <p className={classes.cardCategory}>PONT</p>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.push('/relatorios/usuarios');
                    }}
                  >
                    Ver usuarios
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card style={{ height: '220px' }}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <ListAlt />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Quantidade de validações pendentes{' '}
                </p>
                <hr />
                <Grid container spacing={8} justify="flex-end">
                  <Grid item>
                    <h3 className={classes.cardTitle}>
                      {this.state.validation}
                    </h3>
                  </Grid>
                  <Grid item>
                    <p className={classes.cardCategory}>Validações</p>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.push('/ponts/lista');
                    }}
                  >
                    Ver validações
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card style={{ height: '220px' }}>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <DescriptionIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Quantidade de contestações abertas
                </p>
                <hr />
                <Grid item>
                  <h3 className={classes.cardTitle}>
                    {this.state.opened_disputes}
                  </h3>
                </Grid>
                <Grid item>
                  <p className={classes.cardCategory}>Contestações</p>
                </Grid>
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.push('/disputas/lista');
                    }}
                  >
                    Ver contestações
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card style={{ height: '220px' }}>
              <CardHeader color="info" stats icon>
                <CardIcon color="rose">
                  <PaymentIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {' '}
                  Montante recebido e/ou provisionado
                </p>
                <hr />
                <Grid item>
                  <h3 className={classes.cardTitle}>
                    {this.props.amount
                      ? Intl.NumberFormat('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(this.props.amount)
                      : ''}{' '}
                  </h3>
                  <Grid item>
                    <p className={classes.cardCategory}>Total</p>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardFooter>
                <div className={classes.stats}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.push('/recebimentos/lista');
                    }}
                  >
                    Ver recebidos
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    clients_dash: state.dashboardReducer.clients_dash,
    clientsError: state.dashboardReducer.clientsError,
    users_dash: state.dashboardReducer.users_dash,
    usersError: state.dashboardReducer.users_dash_error,
    clients_banned: state.dashboardReducer.clients_banned,
    clients_banned_error: state.dashboardReducer.clients_banned_error,
    clients_validation: state.dashboardReducer.clients_validation,
    clients_validation_error: state.dashboardReducer.clients_validation_error,
    opened_disputes: state.dashboardReducer.opened_disputes,
    opened_disputes_error: state.dashboardReducer.opened_disputes_error,
    amount: state.dashboardReducer.amount_received,
    amount_received_error: state.dashboardReducer.amount_received_error,
  };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, {
    fetchUsersMeeting,
    fetchClientes,
    fetchClientsBanned,
    fetchClientsValidation,
    fetchOpenedDisputes,
    fetchAmountReceived,
    resetDashInfo,
  })
)(withRouter(Dashboard));
