import {
  FETCH_USER,
  FETCH_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_ERROR,
  CREATE_USER,
  CREATE_USER_ERROR,
  EDIT_USER,
  EDIT_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  RESET_UPDATED_USER_PROFILE,
  RESET_CREATED_UPDATED_USER,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_ERROR,
  RESET_CHANGED_USER_PASSWORD,
  DELETE_USER,
  DELETE_USER_ERROR,
  RESET_DELETED_USER,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  SET_USER_PROFILE_PHOTO,
  RESET_USER_PROFILE_PHOTO,
  RESET_USER_INFO,
} from '../actions/types';

const INITIAL_STATE = [{ updatedUser: null, deletedUser: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        user: action.payload,
      };
    case FETCH_USER_ERROR:
      return { ...state, userError: action.payload };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case FETCH_USERS_ERROR:
      return { ...state, usersError: action.payload };
    case UPDATE_PASSWORD:
      return {
        ...state,
        update_password: action.payload,
      };
    case UPDATE_PASSWORD_ERROR:
      return { ...state, update_password_error: action.payload };
    case CREATE_USER:
      return {
        ...state,
        createdUser: action.payload,
        users: null,
      };
    case CREATE_USER_ERROR:
      return { ...state, createdUserError: action.payload };
    case EDIT_USER:
      return {
        ...state,
        editUserInfo: action.payload,
      };
    case EDIT_USER_ERROR:
      return { ...state, editUserError: action.payload };
    case UPDATE_USER:
      return {
        ...state,
        updatedUser: action.payload,
        users: null,
      };
    case UPDATE_USER_ERROR:
      return { ...state, updateUserError: action.payload };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        updatedUserProfile: action.payload,
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updateUserProfileError: action.payload,
      };
    case RESET_UPDATED_USER_PROFILE:
      return { updatedUserProfile: null };
    case RESET_CREATED_UPDATED_USER:
      return { createdUser: null, updatedUser: null };
    case CHANGE_USER_PASSWORD:
      return {
        ...state,
        changedUserPassword: action.payload,
      };
    case CHANGE_USER_PASSWORD_ERROR:
      return { ...state, changeUserPasswordError: action.payload };
    case RESET_CHANGED_USER_PASSWORD:
      return { changedUserPassword: null };
    case DELETE_USER:
      return {
        ...state,
        deletedUser: action.payload,
      };
    case DELETE_USER_ERROR:
      return { ...state, deleteUserError: action.payload };
    case RESET_DELETED_USER:
      return { deletedUser: null };
    case SET_USER_PROFILE_PHOTO:
      return { ...state, profilePhoto: action.payload };
    case RESET_USER_PROFILE_PHOTO:
      return { ...state, profilePhoto: null };
    case RESET_USER_INFO:
      return {
        ...state,
        editUserInfo: null,
        menusError: null,
        deleteUserError: null,
        changeUserPasswordError: null,
        updateUserProfileError: null,
        updateUserError: null,
        createUserError: null,
        fetchUsersError: null,
        userError: null,
        update_password_error: null,
      };
    default:
      return state;
  }
};
