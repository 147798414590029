import {
  FETCH_USER,
  FETCH_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_ERROR,
  CREATE_USER,
  CREATE_USER_ERROR,
  EDIT_USER,
  EDIT_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  RESET_CREATED_UPDATED_USER,
  DELETE_USER,
  DELETE_USER_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  RESET_DELETED_USER,
  RESET_USER_INFO,
} from './types';
import history from '../history';
import { handleResponseError } from './handleResponseError';

export const fetchUsers = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/users')
      .then((res) => {
        dispatch({
          type: FETCH_USERS,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_USERS}: ${e.message}`);
        dispatch({
          type: FETCH_USERS_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_USERS_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchUser = (token) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/user/auth/me')
      .then((res) => {
        dispatch({
          type: FETCH_USER,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_USER}: ${e.message}`);
        dispatch({
          type: FETCH_USER_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_USER_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const createUser = (name, email, password) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers['token'] = token;

  const res = await connections.apiAdmin
    .post('/users', {
      name: name,
      email: email,
      password: password,
      email_verified_at: 'string',
      blocked: false,
    })
    .then((res) => {
      dispatch({
        type: CREATE_USER,
        payload: res.data ? { createdUserName: name } : false,
      });
      setTimeout(function() {
        history.push('/usuario/lista');
      }, 2000);
    })
    .catch((e) => {
      console.error(`action: ${CREATE_USER}: ${e.message}`);
      dispatch({
        type: CREATE_USER_ERROR,
        payload: e.message,
      });
    });
};

export const editUser = (userId) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .get('/users/' + userId)
    .then((res) => {
      dispatch({
        type: EDIT_USER,
        payload: res.data ? res.data : false,
      });
      history.push('/usuario/' + userId);
    })
    .catch((e) => {
      console.error(`action: ${CREATE_USER}: ${e.message}`);
      dispatch({
        type: EDIT_USER_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const updateUser = (
  // currentUserToken,
  userID,
  name,
  email,
  password
) => async (dispatch, getState, connections) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  // connections.apiAdmin.defaults.headers.put['token'] = currentUserToken;

  const res = await connections.apiAdmin
    .put('/users/' + userID, {
      name: name,
      email: email,
      password: password,
      email_verified_at: 'string',
      blocked: false,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data ? res.data : false,
      });
      history.push('/usuario/lista');
    })
    .catch((e) => {
      console.error(`action: ${UPDATE_USER}: ${e.message}`);
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const resetPassword = (userID, name, email, password) => async (
  dispatch,
  getState,
  connections
) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  // connections.apiAdmin.defaults.headers.put['token'] = currentUserToken;

  const res = await connections.apiAdmin
    .put('/users/' + userID, {
      name: name,
      email: email,
      password: password,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_PASSWORD,
        payload: res.data ? res.data : false,
      });
      history.push('/usuario/lista');
    })
    .catch((e) => {
      console.error(`action: ${UPDATE_PASSWORD}: ${e.message}`);
      dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const deleteUser = (userId) => async (
  dispatch,
  getState,
  connections
) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .delete('/users/' + userId)
    .then((res) => {
      dispatch({
        type: DELETE_USER,
        payload: res.data ? res.data : false,
      });
      history.push('/usuario/lista');
    })
    .catch((e) => {
      console.error(`action: ${DELETE_USER}: ${e.message}`);
      dispatch({
        type: DELETE_USER_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const resetCreatedOrUpdatedUser = () => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: RESET_CREATED_UPDATED_USER,
  });
};

export const resetDeletedUser = () => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: RESET_DELETED_USER,
  });
};

export const resetUserInfo = () => async (dispatch, getState, connections) => {
  dispatch({
    type: RESET_USER_INFO,
  });
};
