import {
  USER_LOGIN,
  USER_LOGIN_ERROR,
  FETCH_CURRENT_USER,
  RESET_LOGIN,
} from './types';
import history from '../history';
import { handleResponseError } from './handleResponseError';

export const userLogin = (email, password) => async (
  dispatch,
  getState,
  connections
) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  await connections.apiAdmin
    .post('/user/auth/login', { email, password })
    .then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem('userId', res.data.user.id);
        sessionStorage.setItem('userToken', res.data.token);
        sessionStorage.setItem('userProfile', JSON.stringify(res.data));
        dispatch({
          type: USER_LOGIN,
          payload: {},
        });
      }
      return res;
    })
    .catch((e) => {
      dispatch({
        type: USER_LOGIN_ERROR,
        payload: handleResponseError(e),
      });
    });
  await userLoginProfile(dispatch, connections);
};

export const isUserLoggedIn = () => async (dispatch, getState, connections) => {
  let userId = sessionStorage.getItem('userId');
  let userToken = sessionStorage.getItem('userToken');
  let userProfile = sessionStorage.getItem('userProfile');
  if (userId && userToken && userProfile) {
    history.push('/dashboard');
  }
};

export const userLoginProfile = (
  dispatch,
  connections,
  redirectTo = '/dashboard'
) => {
  sessionStorage.getItem('userId');
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers['token'] = token;
  dispatch({
    type: USER_LOGIN,
    payload: {},
  });

  history.push(redirectTo);
};

export const fetchCurrentUser = () => async (
  dispatch,
  getState,
  connections
) => {
  let userId = sessionStorage.getItem('userId');
  let userToken = sessionStorage.getItem('userToken');
  let userProfile = sessionStorage.getItem('userProfile');

  if (userId && userToken && userProfile) {
    dispatch({
      type: FETCH_CURRENT_USER,
      payload: { userId, userToken, userProfile: JSON.parse(userProfile) },
    });
  } else {
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('userProfile');
    dispatch({
      type: RESET_LOGIN,
    });
  }
};

export const userLogout = () => async (dispatch, getState, connections) => {
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('userToken');
  sessionStorage.removeItem('userProfile');
  sessionStorage.removeItem('userFeatures');
  dispatch({
    type: RESET_LOGIN,
  });
  history.push('/auth/login');
};

export const resetLogin = () => async (dispatch, getState, connections) => {
  dispatch({
    type: RESET_LOGIN,
  });
};
