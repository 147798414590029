import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  userLogin,
  userLogout,
  isUserLoggedIn,
  resetLogin,
} from "../../actions/loginAction";
import LogoIcon from "../../assets/img/logo-name-icon.svg"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Https from "@material-ui/icons/Https";
import Alert from "@material-ui/icons/NotificationImportant";

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import Snackbar from "../../components/Snackbar/Snackbar.jsx";

import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { validateEmail, validatePassword } from "../../helpers/utils";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // Handles user logout or check if the user is already logged in.
    if (this.props.location.pathname === "/auth/logout") {
      this.props.userLogout();
    } else {
      this.props.isUserLoggedIn();
    }

    this.state = {
      cardAnimaton: "cardHidden",
      recoveryPasswordModal: false,
      resetPassword: false,
      resetPasswordNotification: false,
      email: "",
      emailValidationMessage: "",
      isValidEmail: true,
      password: "",
      passwordValidationMessage: "",
      isValidPassword: true,
      recoveryEmail: "",
      recoveryEmailValidationMessage: "",
      isValidRecoveryEmail: true,
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentDidUpdate() {
    if (this.props.loginError) {
      this.showNotification("bl");
      this.setState({
        password: "",
      });
      this.props.resetLogin();
    }

    if (this.props.passwordRecoveryResult) {
      this.handleClose("recoveryPasswordModal");
      this.showNotification("br");
      this.props.resetLogin();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x, () => {
      this.setState({
        recoveryEmail: "",
        recoveryEmailValidationMessage: "",
        isValidRecoveryEmail: true,
      });
    });
  }

  handleOnChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleOnChangeRecoveryEmail(event) {
    this.setState({ recoveryEmail: event.target.value });
  }

  handleOnChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") this.handleSubmit();
  }

  handleSubmit() {
    if (this.state.email === "") {
      this.setState({
        isValidEmail: false,
        emailValidationMessage: "O e-mail não foi preenchido.",
      });
    } else {
      this.setState(
        {
          isValidEmail: validateEmail(this.state.email),
        },
        () => {
          this.setState({
            emailValidationMessage:
              !this.state.isValidEmail && "O e-mail digitado é inválido.",
          });
        }
      );
    }

    if (this.state.password === "") {
      this.setState({
        isValidPassword: false,
        passwordValidationMessage: "A senha não foi informada.",
      });
    } else {
      this.setState(
        {
          isValidPassword: validatePassword(this.state.password),
        },
        () => {
          this.setState(
            {
              passwordValidationMessage:
                !this.state.isValidPassword &&
                "A senha deve conter no mínimo 6 caracteres.",
            },
            () => {
              if (this.state.isValidEmail && this.state.isValidPassword) {
                this.props.userLogin(this.state.email, this.state.password);
              }
            }
          );
        }
      );
    }
  }

  handlePasswordRecovery() {
    if (this.state.recoveryEmail === "") {
      this.setState({
        isValidRecoveryEmail: false,
        recoveryEmailValidationMessage: "O e-mail não foi preenchido.",
      });
    } else {
      this.setState(
        {
          isValidRecoveryEmail: validateEmail(this.state.recoveryEmail),
        },
        () => {
          this.setState(
            {
              recoveryEmailValidationMessage:
                !this.state.isValidRecoveryEmail &&
                "O e-mail digitado é inválido.",
            },
            () => {
              if (this.state.isValidRecoveryEmail) {
                this.props.passwordRecovery(this.state.recoveryEmail);
              }
            }
          );
        }
      );
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      var timeToFade = 10000;
      if (place === "bl") {
        timeToFade = 5000;
      }
      var x = [];
      x[place] = true;
      this.setState(x, () => {
        setTimeout(
          function() {
            x[place] = false;
            this.setState(x);
          }.bind(this),
          timeToFade
        );
      });
    }
  }

  render() {
    const { classes } = this.props;
    var recoveryPasswordModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall,
          }}
          open={this.state.recoveryPasswordModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("recoveryPasswordModal")}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Resetar Senha</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + " " + classes.modalSmallBody}
          >
            <h5>
              Informe seu endereço de e-mail e clique em enviar para receber
              instruções de como resetar a senha.
            </h5>
            <CustomInput
              labelText="Email"
              id="email-recovery"
              error={!this.state.isValidRecoveryEmail}
              helpText={
                !this.state.isValidRecoveryEmail &&
                this.state.recoveryEmailValidationMessage
              }
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                onChange: this.handleOnChangeRecoveryEmail.bind(this),
                value: this.state.recoveryEmail,
              }}
            />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.handlePasswordRecovery()}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Enviar
            </Button>
            <Button
              onClick={() => this.handleClose("recoveryPasswordModal")}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    var recoveryPasswordNotification = (
      <Snackbar
        place="br"
        color="success"
        icon={Alert}
        message="Solicitação de recuperação de senha enviada. Em breve você receberá um e-mail com instruções para resetar sua senha. Verifique sua caixa de entrada e spam."
        open={this.state.br}
        closeNotification={() => this.setState({ br: false })}
        close
      />
    );

    var loginErrorNotification = (
      <Snackbar
        place="bl"
        color="danger"
        icon={Alert}
        message="Senha ou endereço de e-mail inválido. Por favor, verifique seus dados e tente novamente."
        open={this.state.bl}
        closeNotification={() => this.setState({ bl: false })}
        close
      />
    );

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                 <img src={LogoIcon} alt="Logo Pont" style={{ height: "120px"}} />
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    error={!this.state.isValidEmail}
                    helpText={
                      !this.state.isValidEmail &&
                      this.state.emailValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: this.handleOnChangeEmail.bind(this),
                      onKeyPress: this.handleKeyPress.bind(this),
                      value: this.state.email,
                    }}
                  />
                  <CustomInput
                    labelText="Senha"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={!this.state.isValidPassword}
                    helpText={
                      !this.state.isValidPassword &&
                      this.state.passwordValidationMessage
                    }
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Https className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      type: "password",
                      onChange: this.handleOnChangePassword.bind(this),
                      onKeyPress: this.handleKeyPress.bind(this),
                      value: this.state.password,
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    color="primary"
                    simple
                    size="lg"
                    block
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    Entrar
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        {recoveryPasswordModal}
        {recoveryPasswordNotification}
        {loginErrorNotification}
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    loginError: state.loginReducer.loginError ? true : false,
  };
}

export default compose(
  withStyles(loginPageStyle),
  connect(mapStateToProps, {
    userLogin,
    userLogout,
    isUserLoggedIn,
    resetLogin,
  })
)(withRouter(LoginPage));
