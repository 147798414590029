import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import moment from 'moment';

import {
  fetchAllNotes,
  fetchAproveOrReject,
  resetDisputeInfo,
} from '../../../actions/disputeAction';
import { fetchCurrentUser } from '../../../actions/loginAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';

// Icons
import Alert from '@material-ui/icons/NotificationImportant';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import GridItem from '../../../components/Grid/GridItem.jsx';

import Snackbar from '../../../components/Snackbar/Snackbar.jsx';
import ReplyIcon from '@material-ui/icons/Reply';
import '../../../assets/scss/layout/DisputePage.css';

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const listStyles = {
  itemList: {
    padding: '15px',
    marginBottom: '10px',
    border: '1px solid #BBB',
    fontSize: '14px',
    overflowY: 'scroll',
    height: '60%',
    maxHeight: '550px',
    borderRadius: '4px',
  },
  noResults: {
    padding: '15px',
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: '#f7f7f7',
    marginTop: '20px',
  },
};
class NotesListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allNotes: [],
      idNotes: null,
      aproveModal: false,
      reproveModal: false,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.props.fetchAllNotes(id);
      this.setState({ idNotes: id });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.allNotes && this.props.allNotes !== prevProps.allNotes) {
      this.setState(
        {
          allNotes: this.props.allNotes,
          notificationMessage: 'Dados atualizados',
        },
        () => {
          this.showNotification('primary');
        }
      );
    }
    if (
      this.props.allNotesError &&
      prevProps.allNotesError !== this.props.allNotesError
    ) {
      this.setState({ notificationMessage: this.props.allNotesError }, () =>
        this.showNotification('danger')
      );
    }

    if (
      this.props.aproveOrRejectError &&
      prevProps.aproveOrRejectError !== this.props.aproveOrRejectError
    ) {
      this.setState(
        { notificationMessage: this.props.aproveOrRejectError },
        () => this.showNotification('danger')
      );
    }
  }

  showNotification(color = 'success') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.props.resetDisputeInfo();
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.props.resetDisputeInfo();
    this.setState(state);
  };

  handleModalOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleModalClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  listNotes = () => {
    const { allNotes } = this.state;

    return allNotes.length ? (
      (allNotes.sort((a, b) =>
        a.status < b.status ? -1 : a.status > b.status ? 1 : 0
      ),
      allNotes.map((item, index) => (
        <Grid xs={12} item key={index}>
          <div className="note-list">
            <span>
              <strong>Data criação:</strong>{' '}
              {moment(item.created_at).format('DD/MM/YYYY - HH:MM')}
            </span>
            <p>
              <strong>Nota:</strong> {item.note}
            </p>
          </div>
        </Grid>
      )))
    ) : (
      <p style={listStyles.noResults}> Nenhum resultado encontrado! </p>
    );
  };

  render() {
    const { classes } = this.props;
    const { allNotes } = this.state;
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let reproveModal = (
      <GridContainer justify="center">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.reproveModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleModalClose('reproveModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h5 className={classes.modalTitle}>Rejeitar Disputa</h5>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{ paddingTop: '11px', paddingRight: '16px' }}
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <h5>Confirma a rejeição da disputa {this.state.idNotes}?</h5>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => {
                this.props.fetchAproveOrReject(this.state.idNotes, false);
                this.handleModalClose('reproveModal');
                this.setState(
                  { notificationMessage: 'Nota rejeitada com sucesso' },
                  () => this.showNotification('danger')
                );
              }}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                this.handleModalClose('reproveModal');
              }}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    let aproveModal = (
      <GridContainer justify="center">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.aproveModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleModalClose('aproveModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h5 className={classes.modalTitle}>Aprovar Disputa</h5>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>Confirma a aprovação da disputa {this.state.idNotes}?</h5>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => {
                this.props.fetchAproveOrReject(this.state.idNotes, true);
                this.handleModalClose('aproveModal');
                this.setState(
                  { notificationMessage: 'Nota aprovada com sucesso' },
                  () => this.showNotification('primary')
                );
              }}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                this.handleModalClose('aproveModal');
              }}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    return (
      <div>
        <GridContainer justify="space-between" className="notes-list">
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/disputas/lista">
                  <ReplyIcon className={classes.icon} />
                  Disputas
                </Link>
                <Typography color="textPrimary">Notas</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
        </GridContainer>
        <GridContainer justify="space-between" style={listStyles.itemList}>
          {allNotes.length > 0 && this.listNotes()}
        </GridContainer>
        <Grid container spacing={8} justify="flex-end">
          <Grid item>
            <Button
              onClick={() => this.handleModalOpen('aproveModal')}
              color="success"
              simple
            >
              Aprovar
            </Button>
            <Button
              onClick={() => this.handleModalOpen('reproveModal')}
              color="danger"
              simple
            >
              Rejeitar
            </Button>
          </Grid>
        </Grid>
        {notification}
        {reproveModal}
        {aproveModal}
      </div>
    );
  }
}

NotesListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    allNotes: state.disputeReducer.all_notes,
    allNotesError: state.disputeReducer.all_notes_error,
    aproveOrReject: state.disputeReducer.aprove_reject,
    aproveOrRejectError: state.disputeReducer.aprove_reject_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchAllNotes,
    fetchAproveOrReject,
    resetDisputeInfo,
  })
)(withRouter(NotesListPage));
