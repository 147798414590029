import {
  FETCH_THEMES,
  FETCH_THEMES_ERROR,
  FETCH_ADD_NEW_THEME,
  FETCH_ADD_NEW_THEME_ERROR,
  FETCH_THEME,
  UPDATE_THEME,
  UPDATE_THEME_ERROR,
  DELETE_THEME,
  DELETE_THEME_ERROR,
  FETCH_THEME_ERROR,
  SET_THEME_PHOTO,
  RESET_THEME_PHOTO,
  RESET_THEME_INFO,
} from './types';
import { handleResponseError } from './handleResponseError';
import history from '../history';

export const fetchThemes = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/themes')
      .then((res) => {
        dispatch({
          type: FETCH_THEMES,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_THEMES}: ${e.message}`);
        dispatch({
          type: FETCH_THEMES_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_THEMES_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const createTheme = ({ title, image }) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .post('/themes', {
      title,
      image,
    })
    .then((res) => {
      dispatch({
        type: FETCH_ADD_NEW_THEME,
        payload: res.data ? res.data : false,
      });
      setTimeout(function() {
        history.push('/temas/lista');
      }, 2000);
    })
    .catch((e) => {
      console.error(`action: ${FETCH_ADD_NEW_THEME}: ${e.message}`);
      dispatch({
        type: FETCH_ADD_NEW_THEME_ERROR,
        payload: e.message,
      });
    });
};

export const getTheme = (id) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get('/themes/' + id)
    .then((res) => {
      dispatch({
        type: FETCH_THEME,
        payload: res.data ? res.data : false,
      });
      history.push('/tema/' + id);
    })
    .catch((e) => {
      console.error(`action: ${FETCH_THEME}: ${e.message}`);
      dispatch({
        type: FETCH_THEME_ERROR,
        payload: e.message,
      });
    });
};

export const updateTheme = (id, title, image) => async (
  dispatch,
  getState,
  connections
) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .put('/themes/' + id, {
      title,
      image,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_THEME,
        payload: res.data ? res.data : false,
      });
      history.push('/temas/lista');
    })
    .catch((e) => {
      console.error(`action: ${UPDATE_THEME_ERROR}: ${e.message}`);
      dispatch({
        type: UPDATE_THEME_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const deleteTheme = (id) => async (dispatch, getState, connections) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .delete('/themes/' + id)
    .then((res) => {
      dispatch({
        type: DELETE_THEME,
        payload: res.data ? res.data : false,
      });
      history.push('/temas/lista');
    })
    .catch((e) => {
      console.error(`action: ${DELETE_THEME}: ${e.message}`);
      dispatch({
        type: DELETE_THEME_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const setThemePhoto = (imgBase64, imagePreviewUrl) => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: SET_THEME_PHOTO,
    payload: imagePreviewUrl,
    image: imgBase64,
  });
};

export const resetThemePhoto = () => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: RESET_THEME_PHOTO,
  });
};

export const resetThemeInfo = () => async (dispatch, getState, connections) => {
  dispatch({
    type: RESET_THEME_INFO,
  });
};
