import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchCurrentUser } from '../actions/loginAction';
import { isEmpty } from 'lodash';
import indexRoutes from '../routes/main';

// eslint-disable-next-line
export default (ChildComponent) => {
  class RequireAuth extends Component {
    constructor(props) {
      super(props);
      this.props.fetchCurrentUser();
    }

    getAllRoutes() {
      let routes = [];
      indexRoutes.forEach((r) => {
        routes.push(r);

        if (r.views && Array.isArray(r.views)) {
          r.views.forEach((v) => routes.push(v));
        }
      });
      return routes;
    }

    // hasPermission() {
    //   try {
    //     let route = indexRoutes.find((r) => r.path === this.props.match.path);

    //     if (!route) {
    //       Checa
    //       let hasPermisson = false;
    //       indexRoutes.filter((f) => !!f.collapse);
    //         .forEach((s) => {
    //           if (
    //             s.views.some((x) =>
    //               userFeatures().find((f) => f === x.mustHaveFeature)
    //             )
    //           ) {
    //             hasPermisson = true;
    //           }
    //         });

    //       return hasPermisson;
    //     }

    //     if (route.mustHaveFeature) {
    //       return userFeatures().find((f) => f === route.mustHaveFeature);
    //     }

    //     return true;
    //   } catch (error) {
    //     console.log(
    //       `LOG: RequireAuth -> hasPermission -> error`,
    //       error.message
    //     );
    //     return false;
    //   }
    // }

    render() {
      // if (!this.hasPermission() && this.hasPermission() !== undefined) {
      //   return <Redirect to="/auth/login" />;
      // }

      switch (this.props.authenticatedUser) {
        case false:
          return <Redirect to="/auth/login" />;
        case null:
          return <div>Loading...</div>;
        default:
          return !isEmpty(this.props.authenticatedUser) ? (
            <ChildComponent {...this.props} />
          ) : (
            <div>Loading...</div>
          );
      }
    }
  }

  function mapStateToProps(state) {
    return {
      authenticatedUser: state.loginReducer.authenticatedUser,
    };
  }

  return connect(mapStateToProps, { fetchCurrentUser })(RequireAuth);
};
