import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import history from '../../../history';

// @material-ui/core components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import Alert from '@material-ui/icons/NotificationImportant';
import Snackbar from '../../../components/Snackbar/Snackbar';

// material-ui icons
import ListAlt from '@material-ui/icons/ListAlt';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx';

import {
  fetchValidations,
  updateValidation,
  resetValidationInfo,
} from '../../../actions/validationAction';
import { fetchCurrentUser } from '../../../actions/loginAction';

import { filterMethod, formataCPF } from '../../../helpers/utils';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ValidationListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      motivoStatus: [],
      otherVisible: false,
      deleteUserConfirmationModal: false,
      deleteUserConfirmationToggle: false,
      activateUserConfirmationModal: false,
      activateUserConfirmationToggle: false,
      note: '',
      noteValid: true,
      idPerfil: '',
      idCliente: '',
      countChar: 0,
      countCharMessage: '',
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setState({ tableData: this.props.tableData }, () => {
        this.handleData();
      });
    }
  }

  componentWillUnmount() {
    this.props.resetValidationInfo();
  }

  handleModalOpen(modal) {
    this.setState({ [modal]: true });
  }

  handleModalClose(modal) {
    this.setState({ [modal]: false });
  }

  handleData = () => {
    const usersData = this.state.tableData.map((prop, key) => {
      return {
        id: prop.id,
        userId: prop.id,
        nome: prop.client.name,
        email: prop.client.email,
        cpf: prop.client.social_number
          ? formataCPF(prop.client.social_number)
          : '',
        dataCadastro: moment(prop.created_at).format('DD/MM/YYYY'),
        ativo: prop.blocked && prop.blocked === true ? 'Desativado' : 'Ativado',
        pont: prop.client.is_pont === true ? 'Sim' : 'Não',
        action: (
          <Button
            justIcon
            round
            simple
            title="Alterar status"
            // onClick={() => {
            //   this.setState({ idPerfil: prop.id, idCliente: prop.client_id });
            //   this.handleModalOpen('editStatusModal');
            // }}
            style={{ marginTop: '0px', marginBottom: '0px' }}
            color="info"
          >
            <Edit />
          </Button>
        ),
      };
    });
    this.setState({ usersData });
  };

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetUserInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <ListAlt />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.usersData}
                noDataText="Carregando dados..."
                filterable
                defaultFilterMethod={filterMethod}
                columns={[
                  {
                    Header: 'Nome',
                    accessor: 'nome',
                  },
                  {
                    Header: 'Email',
                    accessor: 'email',
                  },
                  {
                    Header: 'CPF',
                    accessor: 'cpf',
                  },
                  {
                    Header: 'Cadastro',
                    accessor: 'dataCadastro',
                    sortMethod: (a, b) => {
                      if (!a || !b) return 0;

                      let dateA = a.split('/');
                      dateA = dateA[2] + '-' + dateA[1] + '-' + dateA[0];
                      dateA = new Date(dateA);

                      let dateB = b.split('/');
                      dateB = dateB[2] + '-' + dateB[1] + '-' + dateB[0];
                      dateB = new Date(dateB);

                      if (dateA > dateB) return -1;
                      if (dateA < dateB) return 1;
                      return 0;
                    },
                  },
                  {
                    Header: 'Pont',
                    accessor: 'pont',
                  },
                  {
                    Header: 'Status',
                    accessor: 'ativo',
                  },
                  {
                    Header: 'Alterar Status',
                    accessor: 'action',
                  },
                ]}
                defaultSorted={[
                  {
                    id: 'ativo',
                    desc: true,
                  },
                ]}
                getTdProps={(state, rowInfo, column, instance) => ({
                  onClick: () => {
                    if (column.id !== 'actions' && rowInfo) {
                      history.push('/ponts/' + rowInfo.original.id);
                    }
                  },
                })}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      cursor: 'pointer',
                    },
                  };
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                showPageJump={false}
                previousText="Anterior"
                nextText="Próximo"
                pageText="Página"
                ofText="de"
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {notification}
      </GridContainer>
    );
  }
}

ValidationListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    validation: state.validationReducer.validation,
    validationError: state.validationReducer.validation_error,
    updateValidation: state.validationReducer.update_validation,
    updateValidationError: state.validationReducer.update_validation_error,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchValidations,
    updateValidation,
    resetValidationInfo,
  })
)(withRouter(ValidationListTable));
