import React from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import ListAlt from "@material-ui/icons/ListAlt";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Grid from "@material-ui/core/Grid";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import Alert from "@material-ui/icons/NotificationImportant";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";

import {
  fetchDispute, fetchAddNewNote
} from "../../../actions/disputeAction";
import { fetchCurrentUser } from "../../../actions/loginAction";

import history from "../../../history";
import { filterMethod } from "../../../helpers/utils";
import '../../../assets/scss/layout/DisputePage.css';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DisputeListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      disputeData: [],
      note:"",
      noteValid: true,
      idDispute: '',
      countChar: 0,
      countCharMessage: "",
      loading: false,
    };
  }

  componentDidMount() {
    // this.props.fetchDispute();
  }

  componentDidUpdate(prevProps) {
     if (this.props.dispute && this.props.dispute !== prevProps.dispute) {
      this.setState({
        tableData: this.props.dispute,
        notificationMessage: 'Dados atualizados',
      }, () => {
        this.handleData()
        this.showNotification('primary')
      })
      
    }
    if (this.props.note && prevProps.note !== this.props.note) {
      this.setState({notificationMessage: "Nota adicionada com sucesso" }, () => this.showNotification('primary'))
    }
    if (this.props.noteError && prevProps.noteError !== this.props.noteError) {
      this.setState({note: "", notificationMessage: this.props.noteError, }, () => this.showNotification('danger'))
    }
  }


  handleModalOpen(modal) {
    this.setState({ [modal]: true });
  }

  handleModalClose(modal) {
    this.setState({[modal]: false});
  }

  handleData = () => {
    const disputeData = this.state.tableData.map((prop) => {
      return {
      id: prop.id,
      cliente: prop.client,
      pont: prop.pont,
      data_abertura: moment(prop.opened_at).format("DD/MM/YYYY"),
      descricao: prop.description,
      valide: prop.is_valid === true ? "Aprovada" : "Rejeitada",
      action: 
          <div>
             <Button
                justIcon
                round
                simple
                title="Adicionar nota"
                onClick={async event => {
					      	event.stopPropagation();
                  this.setState({idDispute:  prop.id })
						      this.handleModalOpen('addNotaModal')}}

                style={{ marginTop: "0px", marginBottom: "0px" }}
                color="info"
              >
                <Edit />
              </Button>
          </div>
      };
    });
    this.setState({ disputeData });
  };
  
  showNotification(color = "primary") {
  if (this.state["showNotification"]) return;

  let state = [];
  state["showNotification"] = true;
  state["notificationColor"] = color;

  this.setState(state, () => {
    setTimeout(
      function() {
        state["showNotification"] = false;
        state["notificationColor"] = color;
        state["notificationMessage"] = "";
        this.setState(state);
      }.bind(this),
      4000
    );
  });
  }

  closeNotification = () => {
    let state = [];
    state["showNotification"] = false;
    state["notificationMessage"] = "";
    this.setState(state);
  };

  handleTextareaChange = e => {
    const { value } = e.target;
    let noteValid;

    noteValid = value !== '' ? true : false

    this.setState({ note: value, noteValid, countChar: value.length })
  }

  handleSubmit() {
    let payload = {
      "dispute_id": this.state.idDispute,
      "note": this.state.note,
    }

    this.props.fetchAddNewNote(payload).then(() => this.setState({note: "", loading: false}));
    this.handleModalClose('addNotaModal');
  }

  render() {
  let limit = Number(300) - this.state.countChar;
  const { classes } = this.props;
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || "success"}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

     let addNotaModal = (
      <GridContainer justify="center">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={this.state.addNotaModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleModalClose('addNotaModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h5 className={classes.modalTitle}>Nota</h5>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + " " + classes.modalSmallBody}
          >
            <Grid item xs={12} md={12} sm={12} style={{paddingTop: '11px', paddingRight: '16px'}}>
              <FormControl component="fieldset" className={classes.formControlTextarea}>
                <textarea 
                  id="note" 
                  row={10}
                  className="note" 
                  maxLength={300} 
                  placeholder="Descreva aqui sua nota sobre a disputa" 
                  onKeyUp={this.handleTextareaChange}
                ></textarea>
                <div class="text">
                  <p className="countChar">Restam {limit} caracteres</p>
                  {!this.state.noteValid && 
                  <span class="text-error">Informe uma nota</span>
                }
                </div>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
          >
            <Button
              onClick={() => {
               this.handleSubmit()
              }}
              disabled={this.state.countChar === 0}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                this.handleModalClose('addNotaModal');
                this.setState({noteValid: true})
              }}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    return (
      <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <ListAlt />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.disputeData}
                noDataText="Carregando dados..."
                filterable
                defaultFilterMethod={filterMethod}
                columns={[
                   {
                    Header: "Data Abertura",
                    accessor: "data_abertura",
                    sortMethod: (a, b) => {
                      if (!a || !b) return 0

                      let dateA = a.split('/')
                      dateA = dateA[2]+'-'+dateA[1]+'-'+dateA[0]
                      dateA = new Date(dateA)

                      let dateB = b.split('/')
                      dateB = dateB[2]+'-'+dateB[1]+'-'+dateB[0]
                      dateB = new Date(dateB)

                      if (dateA > dateB) return -1
                      if (dateA < dateB) return 1
                      return 0
                    }
                  },
                  {
                    Header: "Cliente",
                    accessor: "cliente"
                  },
                  {
                    Header: "Pont",
                    accessor: "pont"
                  },
                 
                  {
                    Header: "Situação",
                    accessor: "valide"
                  },
                  {
                    Header: "Descrição",
                    accessor: "descricao"
                  },
                  {
                    Header: "Adicionar nota",
                    accessor: "action"
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => ({
                  onClick: () => {
                    if (column.id !== "actions" && rowInfo) {
                      history.push("/disputas/"+rowInfo.original.id)
                    }
                  }
                })}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      cursor: "pointer"
                    }
                  };
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                showPageJump={false}
                previousText="Anterior"
                nextText="Próximo"
                pageText="Página"
                ofText="de"
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {notification}
        {addNotaModal}
      </GridContainer>
       
      </>
    );
  }
}

DisputeListTable.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    dispute: state.disputeReducer.dispute,
    disputeError:  state.disputeReducer.disputeError,
    note: state.disputeReducer.note,
    noteError: state.disputeReducer.note_error,
  };
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { fetchCurrentUser, fetchDispute, fetchAddNewNote}
  )
)(withRouter(DisputeListTable));
