import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { fetchValidations } from '../../../actions/validationAction';
import { fetchCurrentUser } from '../../../actions/loginAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Snackbar from '../../../components/Snackbar/Snackbar.jsx';
import Alert from '@material-ui/icons/NotificationImportant';

import ValidationListTable from './ValidationListTable';

const style = {};
class ValidationListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
    };
  }

  componentDidMount() {
    this.props.fetchValidations();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.usersValidation &&
      prevProps.usersValidation != this.props.usersValidation
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          users: this.props.usersValidation,
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.usersValidationError &&
      prevProps.usersValidationError != this.props.usersValidationError
    ) {
      this.setState(
        { notificationMessage: this.props.usersValidationError },
        () => this.showNotification('danger')
      );
    }
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );
    return (
      <div>
        <GridContainer justify="flex-end">
          <Grid item>
            <Button
              onClick={() => this.props.fetchValidations()}
              color="primary"
              size="sm"
              style={{ padding: 10 }}
            >
              <RefreshIcon /> Atualizar
            </Button>
          </Grid>
        </GridContainer>
        <ValidationListTable tableData={this.state.users} {...this.props} />
        {notification}
      </div>
    );
  }
}

ValidationListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    usersValidation: state.validationReducer.validation,
    usersValidationError: state.validationReducer.validation_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchValidations,
  })
)(withRouter(ValidationListPage));
