import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import {
  fetchBillsToReceive,
  resetBillsToReceive,
} from '../../../actions/billsToReceive';
import { fetchCurrentUser } from '../../../actions/loginAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import Alert from '@material-ui/icons/NotificationImportant';

import Snackbar from '../../../components/Snackbar/Snackbar.jsx';

import BillsToReceiveListTable from './BillsToReceiveListTable';

const style = {};
class BillsToReceiveListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      billsToReceive: null,
    };
  }

  componentDidMount() {
    this.props.fetchBillsToReceive();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.billsToReceive &&
      prevProps.billsToReceive !== this.props.billsToReceive
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          billsToReceive: this.props.billsToReceive,
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.billsToReceiveError &&
      prevProps.billsToReceiveError !== this.props.billsToReceiveError
    ) {
      this.setState(
        { notificationMessage: this.props.billsToReceiveError },
        () => this.showNotification('danger')
      );
    }
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );
    return (
      <div>
        <GridContainer justify="flex-end">
          <Grid item>
            <Button
              onClick={() => this.props.fetchBillsToReceive()}
              color="primary"
              size="sm"
            >
              <RefreshIcon /> Atualizar
            </Button>
          </Grid>
        </GridContainer>
        <BillsToReceiveListTable
          tableData={this.state.billsToReceive}
          {...this.props}
        />
        {notification}
      </div>
    );
  }
}

BillsToReceiveListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    billsToReceive: state.billsToReceiveReducer.bills_to_receive,
    billsToReceiveError: state.billsToReceiveReducer.bills_to_receive_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchBillsToReceive,
    resetBillsToReceive,
  })
)(withRouter(BillsToReceiveListPage));
