import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { fetchHistoryBilling } from '../../../actions/reportsAction';
import { fetchCurrentUser } from '../../../actions/loginAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import HistoryListTable from './HistoryListTable';

const style = {};

class HistoryListPage extends React.Component {
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.historyBilling &&
      prevProps.historyBilling != this.props.historyBilling
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          tableData: this.props.historyBilling,
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.historyBillingError &&
      prevProps.historyBillingError != this.props.historyBillingError
    ) {
      this.setState(
        { notificationMessage: this.props.historyBillingError },
        () => this.showNotification('danger')
      );
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      var timeToFade = 4000;
      var x = [];
      x[place] = true;
      this.setState(x, () => {
        setTimeout(
          function() {
            x[place] = false;
            if (place === 'br') {
              x['createOrEditUserSuccessMessage'] = '';
            } else if (place === 'bl') {
              x['deleteUserSuccessMessage'] = '';
            }
            this.setState(x);
          }.bind(this),
          timeToFade
        );
      });
    }
  }

  render() {
    return (
      <div>
        <GridContainer justify="flex-end"></GridContainer>
        <HistoryListTable {...this.props} />
      </div>
    );
  }
}

HistoryListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    historyBilling: state.reportsReducer.history_billing,
    historyBillingError: state.reportsReducer.history_billing_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchHistoryBilling,
  })
)(withRouter(HistoryListPage));
