export const handleResponseError = (error) => {
  if (error.response && error.response.data && error.response.data.children) {
    let messages = error.response.data.children.map((err) => {
      return err.message;
    });

    return messages.join(', ');
  }

  return error.response && error.response.data
    ? error.response.data.message
    : error.message;
};
