import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ReplyIcon from '@material-ui/icons/Reply';
import Key from '@material-ui/icons/VpnKey';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
// import PictureUpload from "@views/Components/PictureUpload.jsx";

import { fetchCurrentUser } from '../../../actions/loginAction';
import { validateEmail, removeReadonly } from '../../../helpers/utils';
import {
  fetchUser,
  resetPassword,
  resetUserInfo,
} from '../../../actions/userAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Photo from '@material-ui/icons/Photo';
import Extension from '@material-ui/icons/Extension';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Alert from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
// import PictureUpload2 from '../../Components/PictureUpload2'

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      name: '',
      isValidName: true,
      nameValidationMessage: '',
      email: '',
      emailValidationMessage: '',
      isValidEmail: true,
      password: '',
      isValidPassword: true,
      passwordValidationMessage: '',
      confirmPassword: '',
      isValidConfirmPassword: true,
      passwordConfirmValidationMessage: '',
    };
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  componentWillUnmount() {
    this.props.resetUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
        name: this.props.user && this.props.user.user.name,
        email: this.props.user && this.props.user.user.email,
      });
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
        },
        () => this.showNotification('primary')
      );
    }

    if (this.props.userError && prevProps.userError != this.props.userError) {
      this.setState({ notificationMessage: this.props.userError }, () =>
        this.showNotification('danger')
      );
    }

    if (
      this.props.updatePassword &&
      prevProps.updatePassword != this.props.updatePassword
    ) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.updatePasswordError &&
      prevProps.updatePasswordError != this.props.updatePasswordError
    ) {
      this.setState({ notificationMessage: this.props.updatePassword }, () =>
        this.showNotification('danger')
      );
    }
  }

  handleOnChangeName(event) {
    this.setState({ name: event.target.value });
  }

  handleOnChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleOnChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleOnChangeConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  handleOnChangeFeature(feature) {
    let features = this.state.features;
    const index = features.findIndex((f) => f.idMenu === feature.idMenu);
    const isFather = !feature.idPai;
    const indexFather = isFather
      ? -1
      : features.findIndex((f) => f.idMenu === feature.idPai);

    // Add
    if (index < 0) {
      features.push({ idMenu: feature.idMenu });
      if (!isFather && indexFather < 0) {
        features.push({ idMenu: feature.idPai });
      }
      if (isFather) {
        this.props.menus.menus
          .filter((m) => m.idPai === feature.idMenu)
          .map((c) => {
            const childIndex = this.state.features.findIndex(
              (f) => f.idMenu === c.idMenu
            );
            if (childIndex < 0) features.push({ idMenu: c.idMenu });
          });
      }
    }
    // Remove
    else {
      features.splice(index, 1);

      // Remove childs
      if (isFather) {
        this.props.menus.menus
          .filter((m) => m.idPai === feature.idMenu)
          .map((c) => {
            const childIndex = this.state.features.findIndex(
              (f) => f.idMenu === c.idMenu
            );
            if (childIndex >= 0) features.splice(childIndex, 1);
          });
      }
      // Uncheck father
      else if (indexFather > -1) {
        let uncheck = true;
        let childs = this.props.menus.menus.filter(
          (m) => m.idPai === feature.idPai
        );
        childs.forEach((c) => {
          const childIndex = features.findIndex((f) => f.idMenu === c.idMenu);
          if (childIndex >= 0) uncheck = false;
        });

        if (uncheck) {
          const newIndexFather = features.findIndex(
            (f) => f.idMenu === feature.idPai
          );
          features.splice(newIndexFather, 1);
        }
      }
    }

    this.setState({ features });
  }

  handleSubmit() {
    if (this.state.password.length < 6) {
      this.setState({
        isValidPassword: false,
        passwordValidationMessage:
          'O campo senha deve conter pelo menos 6 caracteres.',
      });
    } else {
      this.setState(
        {
          isValidConfirmPassword: true,
          passwordConfirmValidationMessage: '',
        },
        () => {
          this.setState({
            passwordConfirmValidationMessage:
              !this.state.isValidPassword &&
              'O campo senha deve conter pelo menos 6 caracteres.',
          });
        }
      );
    }

    if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        isValidConfirmPassword: false,
        passwordConfirmValidationMessage: 'As senhas devem coincidir',
      });
    } else {
      this.setState(
        {
          isValidPassword: true,
          passwordValidationMessage: '',
        },
        () => {
          if (
            this.state.isValidName &&
            this.state.isValidEmail &&
            this.state.isValidPassword
          ) {
            let userId = this.props.user && this.props.user.user.id;
            this.props.resetPassword(
              userId,
              this.state.name,
              btoa(this.state.password)
            );
          }
        }
      );
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetUserInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/dashboard">
                  <ReplyIcon className={classes.icon} />
                  Dashboard
                </Link>
                <Typography color="textPrimary">Editar senha</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    error={!this.state.isValidName}
                    helpText={
                      !this.state.isValidName &&
                      this.state.nameValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    defaultValue={
                      this.state.user.user && this.state.user.user.name
                    }
                    inputProps={{
                      name: 'name',
                      onChange: this.handleOnChangeName.bind(this),
                      value: this.state.name,
                      disabled:
                        this.state.user.user && this.state.user.user.name !== ''
                          ? true
                          : false,
                    }}
                  />

                  <CustomInput
                    labelText="Email"
                    id="email"
                    defaultValue={this.state.email}
                    error={!this.state.isValidEmail}
                    helpText={
                      !this.state.isValidEmail &&
                      this.state.emailValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'email',
                      name: 'email',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangeEmail.bind(this),
                      value: this.state.email,
                      disabled: this.state.email !== '' ? true : false,
                    }}
                  />
                  <CustomInput
                    labelText="Senha *"
                    id="password"
                    error={!this.state.isValidPassword}
                    helpText={
                      !this.state.isValidPassword &&
                      this.state.passwordValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangePassword.bind(this),
                      value: this.state.password,
                    }}
                  />
                  <CustomInput
                    error={!this.state.isValidConfirmPassword}
                    helpText={
                      !this.state.isValidConfirmPassword &&
                      this.state.passwordConfirmValidationMessage
                    }
                    labelText="Confirme sua senha *"
                    id="registerconfirmpassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(
                          event,
                          'registerConfirmPassword',
                          'equalTo',
                          'registerPassword'
                        ),
                      type: 'password',
                      name: 'password',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangeConfirmPassword.bind(this),
                      value: this.state.confirmPassword,
                    }}
                  />
                  <Button
                    color="info"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Key /> Trocar Senha
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </div>
    );
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    user: state.userReducer.user,
    userError: state.userReducer.userError,
    updatedPasswordError: state.userReducer.updated_password_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchUser,
    resetPassword,
    resetUserInfo,
  })
)(withRouter(ProfilePage));
