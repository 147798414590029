import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  fetchDispute
} from "../../../actions/disputeAction";
import { fetchCurrentUser } from "../../../actions/loginAction";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Icons
import Alert from "@material-ui/icons/NotificationImportant";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";

import Snackbar from "../../../components/Snackbar/Snackbar.jsx";

import DisputeListTable from "./DisputeListTable";

const style = {};
class DisputeListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disputes: {},
    };
  }

  componentDidMount() {
    this.props.fetchDispute();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dispute && prevProps.dispute != this.props.dispute) {
      this.setState({notificationMessage: 'Dados atualizados', disputes: this.props.dispute}, () => this.showNotification('primary'))
    }
    if (this.props.disputeError && prevProps.disputeError != this.props.disputeError) {
      this.setState({notificationMessage: this.props.disputeError}, () => this.showNotification('danger'))
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      var timeToFade = 4000;
      var x = [];
      x[place] = true;
      this.setState(x, () => {
        setTimeout(
          function() {
            x[place] = false;
            if (place === "br") {
              x["createOrEditUserSuccessMessage"] = "";
            } else if (place === "bl") {
              x["deleteUserSuccessMessage"] = "";
            }
            this.setState(x);
          }.bind(this),
          timeToFade
        );
      });
    }
  }

  render() {
    var createOrEditUserSuccessNotification = (
      <Snackbar
        place="br"
        color="primary"
        icon={Alert}
        message={this.state.createOrEditUserSuccessMessage}
        open={this.state.br}
        closeNotification={() =>
          this.setState({ br: false, createOrEditUserSuccessMessage: "" })
        }
        close
      />
    );
    var deleteUserSuccessNotification = (
      <Snackbar
        place="bl"
        color="primary"
        icon={Alert}
        message={this.state.deleteUserSuccessMessage}
        open={this.state.bl}
        closeNotification={() =>
          this.setState({ bl: false, deleteUserSuccessMessage: "" })
        }
        close
      />
    );


    return (
      <div>
        <GridContainer justify="flex-end">
        <Grid item>
          <Button
            onClick={() => this.props.fetchDispute()}
            color="primary"
            size="sm"
          >
            <RefreshIcon/> Atualizar
          </Button>
        </Grid>
      </GridContainer>
        <DisputeListTable tableData={this.state.disputes} {...this.props} />
        {createOrEditUserSuccessNotification}
        {deleteUserSuccessNotification}
      </div>
    );
  }
}

DisputeListPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    dispute: state.disputeReducer.dispute,
    disputeError:  state.disputeReducer.disputeError,
  };
}

export default compose(
  withStyles(style),
  connect(
    mapStateToProps,
    {
      fetchCurrentUser,
      fetchDispute
    }
  )
)(withRouter(DisputeListPage));
