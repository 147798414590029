import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ReplyIcon from '@material-ui/icons/Reply';
import DefaultImage from '../../../assets/img/default-avatar.png';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
// import PictureUpload from "@views/Components/PictureUpload.jsx";

import { fetchCurrentUser } from '../../../actions/loginAction';
import { formataCPF, phoneMask } from '../../../helpers/utils';

import {
  fetchValidationId,
  updateValidation,
  resetValidationInfo,
} from '../../../actions/validationAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Alert from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EditValidationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      editPerfil: [],
      motivoStatus: [],
      otherVisible: false,
      deleteUserConfirmationModal: false,
      deleteUserConfirmationToggle: false,
      activateUserConfirmationModal: false,
      activateUserConfirmationToggle: false,
      note: '',
      noteValid: true,
      countChar: 0,
      countCharMessage: '',
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (!id) return;

    this.props.fetchValidationId(id);
  }

  componentWillUnmount() {
    this.props.resetValidationInfo();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.validationId &&
      prevProps.validationId != this.props.validationId
    ) {
      this.setState({
        editPerfil: this.props.validationId,
      });
    }
    if (
      this.props.validationIdError &&
      prevProps.validationIdError != this.props.validationIdError
    ) {
      this.setState({ notificationMessage: this.props.validationIdError }, () =>
        this.showNotification('danger')
      );
    }
    if (
      this.props.updateValidation &&
      prevProps.updateValidation !== this.props.updateValidation
    ) {
      this.setState(
        {
          notificationMessage: 'Perfil alterado com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.updateValidationError &&
      prevProps.updateValidationError !== this.props.updateValidationError
    ) {
      this.setState(
        { notificationMessage: this.props.updateValidationError },
        () => this.showNotification('danger')
      );
    }
  }

  handleModalOpen(modal) {
    this.setState({ [modal]: true });
  }

  handleModalClose(modal) {
    this.setState({ [modal]: false });
  }

  handleDeleteUser() {
    let payload = {
      id: this.state.editPerfil.id,
      blocked: true,
      client_id: this.state.editPerfil.client_id,
      banned_reason: this.state.note,
    };

    this.props.updateValidation(payload);
  }

  handleActivateUser() {
    let payload = {
      id: this.state.editPerfil.id,
      blocked: false,
      client_id: this.state.editPerfil.client_id,
      banned_reason: '',
    };
    this.props.updateValidation(payload);
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleTextareaChange = (e) => {
    const { value } = e.target;
    let noteValid;

    noteValid = value !== '' ? true : false;

    this.setState({ note: value, noteValid, countChar: value.length });
  };

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetUserInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;
    let limit = Number(100) - this.state.countChar;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let editStatusModal = (
      <GridContainer justify="center">
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.editStatusModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleModalClose('editStatusModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h5 className={classes.modalTitle}>Alterar status</h5>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <p className={classes.modalTitle}>
              Por favor, validar o cadastro do perfil
            </p>
          </DialogContent>
          {this.state.editPerfil && this.state.editPerfil.blocked === true ? (
            <DialogActions className={classes.modalFooter}>
              <Button
                color="success"
                onClick={() => {
                  this.handleClickOpen('activateUserConfirmationModal');
                  this.handleModalClose('editStatusModal');
                }}
                activateUserConfirmationModal
              >
                <Check /> Ativar
              </Button>
            </DialogActions>
          ) : (
            <DialogActions className={classes.modalFooter}>
              <Button
                onClick={() => {
                  this.handleClickOpen('deleteUserConfirmationModal');
                  this.handleModalClose('editStatusModal');
                }}
                color="danger"
              >
                <Close /> Desativar
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </GridContainer>
    );

    let deleteUserConfirmationModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.deleteUserConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('deleteUserConfirmationModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Desativar Usuário</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Após desativado, o usuário em questão não poderá mais logar no
              sistema. Para continuar, descreva o motivo da desativação.
            </h5>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{ paddingTop: '11px', paddingRight: '16px' }}
            >
              <FormControl
                component="fieldset"
                className={classes.formControlTextarea}
              >
                <textarea
                  id="note"
                  row={3}
                  className="note"
                  maxLength={100}
                  placeholder="Descreva aqui o motivo"
                  onKeyUp={this.handleTextareaChange}
                  style={{ height: '100px' }}
                ></textarea>
                <div class="text">
                  <p className="countChar">Restam {limit} caracteres</p>
                  {!this.state.noteValid && (
                    <span class="text-error">Informe uma nota</span>
                  )}
                </div>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
          >
            <Button
              onClick={() => {
                this.handleDeleteUser();
                this.handleClose('deleteUserConfirmationModal');
              }}
              color="success"
              simple
              disabled={!this.state.note}
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                this.handleClose('deleteUserConfirmationModal');
                this.setState({ note: '', noteValid: true });
              }}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    let activateUserConfirmationModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.activateUserConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('activateUserConfirmationModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Ativar Usuário</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Após ativado, o usuário em questão poderá acessar o sistema. Tem
              certeza que deseja continuar?
            </h5>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
          >
            <Button
              onClick={() => {
                this.handleActivateUser();
                this.handleClose('activateUserConfirmationModal');
              }}
              color="success"
              simple
              // xdisabled={!this.state.activateUserConfirmationToggle}
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => this.handleClose('activateUserConfirmationModal')}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/ponts/lista">
                  <ReplyIcon className={classes.icon} />
                  Validação Perfil
                </Link>
                <Typography color="textPrimary">Editar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Paper>
                <Tabs
                  value={this.state.tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, value) => this.setState({ tab: value })}
                >
                  <Tab label="Pessoais" />
                  <Tab label="Formação" />
                  <Tab label="Profissionais" />
                  <Tab label="Temas" />
                  <Tab label="Vídeos" />
                </Tabs>
              </Paper>
              <CardBody>
                {this.state.tab === 0 ? (
                  <Grid container spacing={8} style={{ margin: '20px 0' }}>
                    <Grid item>
                      <Typography
                        variant="subheading"
                        gutterBottom
                        style={{ textTransform: 'capitalize' }}
                      >
                        - Informações Pessoais
                      </Typography>
                    </Grid>

                    <Grid container justify="space-between">
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={12}>
                          <img
                            style={{
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover',
                              borderRadius: '50%',
                            }}
                            src={
                              this.state.editPerfil.photo_path !== null
                                ? this.state.editPerfil.photo_path
                                : DefaultImage
                            }
                            alt="Imagem tema"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Id:{' '}
                            {this.state.editPerfil
                              ? this.state.editPerfil.id
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Ativo:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.blocked === true
                              ? 'Não'
                              : 'Sim'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Data Criação:{' '}
                            {this.state.editPerfil &&
                              moment(this.state.editPerfil.created_at).format(
                                'DD/MM/YYYY - HH:MM'
                              )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Cliente Id:{' '}
                            {this.state.editPerfil
                              ? this.state.editPerfil.client_id
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Nome:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.client
                              ? this.state.editPerfil.client.name
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            CPF:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.client
                              ? formataCPF(
                                  this.state.editPerfil.client.social_number
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Data nascimento:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.client
                              ? moment(
                                  this.state.editPerfil.client.birthday
                                ).format('DD/MM/YYYY')
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            Email:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.client
                              ? this.state.editPerfil.client.email
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Banido:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.client &&
                            this.state.editPerfil.client.banned_at !== null
                              ? this.state.editPerfil.client.banned_at
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Valor:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.value !== null
                              ? Intl.NumberFormat('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(this.state.editPerfil.value)
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                {this.state.tab === 1 ? (
                  <Grid container spacing={8} style={{ margin: '20px 0' }}>
                    <Grid item>
                      <Typography
                        variant="subheading"
                        gutterBottom
                        style={{ textTransform: 'capitalize' }}
                      >
                        - Informações Acadêmicas
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Formação:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.backgrounds
                              ? this.state.editPerfil.backgrounds.map(
                                  (item) => item.type_description
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Instituição:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.backgrounds
                              ? this.state.editPerfil.backgrounds.map(
                                  (item) => item.institution
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Cidade:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.backgrounds
                              ? this.state.editPerfil.backgrounds.map(
                                  (item) => item.locale
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'capitalize' }}
                          >
                            Curso:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.backgrounds
                              ? this.state.editPerfil.backgrounds.map(
                                  (item) => item.title
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            Pont ID:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.professionals
                              ? this.state.editPerfil.professionals.map(
                                  (item) => item.pont_id
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                {this.state.tab === 2 ? (
                  <Grid container spacing={8} style={{ margin: '20px 0' }}>
                    <Grid item>
                      <Typography
                        variant="subheading"
                        gutterBottom
                        style={{ textTransform: 'none' }}
                      >
                        - Informações Profissionais
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            Empresa:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.professionals
                              ? this.state.editPerfil.professionals.map(
                                  (item) => item.company
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            Função:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.professionals
                              ? this.state.editPerfil.professionals.map(
                                  (item) => item.function
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            Cidade:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.professionals
                              ? this.state.editPerfil.professionals.map(
                                  (item) => item.city
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            Pont ID:{' '}
                            {this.state.editPerfil &&
                            this.state.editPerfil.professionals
                              ? this.state.editPerfil.professionals.map(
                                  (item) => item.pont_id
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                {this.state.tab === 3 ? (
                  <Grid container spacing={8} style={{ margin: '15px 0 ' }}>
                    <Grid item>
                      <Typography
                        variant="subheading"
                        gutterBottom
                        style={{ textTransform: 'none' }}
                      >
                        - Informações de Temas
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            {this.state.editPerfil &&
                            this.state.editPerfil.themes
                              ? this.state.editPerfil.themes.map((item) => (
                                  <Grid xs={12} item key={item.id}>
                                    <div className="note-list">
                                      <span>
                                        <strong>Tema:</strong> {item.title}
                                      </span>
                                    </div>
                                  </Grid>
                                ))
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                {this.state.tab === 4 ? (
                  <Grid container spacing={8} style={{ margin: '20px 0' }}>
                    <Grid item>
                      <Typography
                        variant="subheading"
                        gutterBottom
                        style={{ textTransform: 'none' }}
                      >
                        - Informações de Vídeos
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ textTransform: 'none' }}
                          >
                            {this.state.editPerfil &&
                            this.state.editPerfil.videos
                              ? this.state.editPerfil.videos.map((item) => (
                                  <Grid xs={12} item key={item.id}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <span
                                        style={{ marginBottom: '15px' }}
                                      ></span>
                                      <video
                                        src={item.path}
                                        controls
                                        style={{
                                          width: '400px',
                                          height: '250px',
                                          objectFit: 'cover',
                                        }}
                                      ></video>
                                    </div>
                                  </Grid>
                                ))
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid container justify="flex-end">
                  {this.state.editPerfil &&
                  this.state.editPerfil.blocked === true ? (
                    <Button
                      color="success"
                      onClick={() => {
                        this.handleClickOpen('activateUserConfirmationModal');
                        this.handleModalClose('editStatusModal');
                      }}
                      activateUserConfirmationModal
                    >
                      <Check /> Ativar
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        this.handleClickOpen('deleteUserConfirmationModal');
                        this.handleModalClose('editStatusModal');
                      }}
                      color="danger"
                    >
                      <Close /> Desativar
                    </Button>
                  )}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {editStatusModal}
        {deleteUserConfirmationModal}
        {activateUserConfirmationModal}
        {notification}
      </div>
    );
  }
}

EditValidationPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    validationId: state.validationReducer.validation_id,
    validationIdError: state.validationReducer.validation_id_error,
    updateValidation: state.validationReducer.update_validation,
    updateValidationError: state.validationReducer.update_validation_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchValidationId,
    updateValidation,
    resetValidationInfo,
  })
)(withRouter(EditValidationPage));
