import {
  FETCH_DISPUTE,
  FETCH_DISPUTE_ERROR,
  FETCH_ADD_NEW_NOTE,
  FETCH_ADD_NEW_NOTE_ERROR,
  FETCH_ALL_NOTES,
  FETCH_ALL_NOTES_ERROR,
  FETCH_APROVE_OR_REJECT,
  FETCH_APROVE_OR_REJECT_ERROR,
  RESET_DISPUTE_INFO,
} from '../actions/types';

const INITIAL_STATE = [{ dispute: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DISPUTE:
      return {
        ...state,
        dispute: action.payload,
      };
    case FETCH_DISPUTE_ERROR:
      return { ...state, dispute_error: action.payload };
    case FETCH_ADD_NEW_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case FETCH_ADD_NEW_NOTE_ERROR:
      return {
        ...state,
        note_error: action.payload,
      };
    case FETCH_ALL_NOTES:
      return {
        ...state,
        all_notes: action.payload,
      };
    case FETCH_ALL_NOTES_ERROR:
      return {
        ...state,
        all_notes_error: action.payload,
      };
    case FETCH_APROVE_OR_REJECT:
      return {
        ...state,
        aprove_reject: action.payload,
      };
    case FETCH_APROVE_OR_REJECT_ERROR:
      return {
        ...state,
        aprove_reject_error: action.payload,
      };

    case RESET_DISPUTE_INFO:
      return {
        ...state,
        validation: null,
        validation_error: null,
        note_error: null,
        all_notes_error: null,
      };
    default:
      return state;
  }
};
