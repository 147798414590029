import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import {
  fetchUsers,
  resetCreatedOrUpdatedUser,
  resetDeletedUser,
} from '../../../actions/userAction';
import { fetchCurrentUser } from '../../../actions/loginAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

// Icons
import Alert from '@material-ui/icons/NotificationImportant';

import Snackbar from '../../../components/Snackbar/Snackbar.jsx';

import UsersListTable from './UsersListTable';

const style = {};
class UsersListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      createOrEditUserSuccessMessage: '',
      deleteUserSuccessMessage: '',
    };
  }

  componentDidMount() {
    this.props.fetchUsers(this.props.authenticatedUser.userToken);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.users && prevProps.users !== this.props.users) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          users: this.props.users,
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.usersError &&
      prevProps.usersError != this.props.usersError
    ) {
      this.setState({ notificationMessage: this.props.usersError }, () =>
        this.showNotification('danger')
      );
    }
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    var createOrEditUserSuccessNotification = (
      <Snackbar
        place="br"
        color="primary"
        icon={Alert}
        message={this.state.createOrEditUserSuccessMessage}
        open={this.state.br}
        closeNotification={() =>
          this.setState({ br: false, createOrEditUserSuccessMessage: '' })
        }
        close
      />
    );
    var deleteUserSuccessNotification = (
      <Snackbar
        place="bl"
        color="primary"
        icon={Alert}
        message={this.state.deleteUserSuccessMessage}
        open={this.state.bl}
        closeNotification={() =>
          this.setState({ bl: false, deleteUserSuccessMessage: '' })
        }
        close
      />
    );

    return (
      <div>
        <GridContainer justify="flex-end">
          <Grid item>
            <Button
              onClick={() =>
                this.props.fetchUsers(this.props.authenticatedUser.userToken)
              }
              color="primary"
              size="sm"
            >
              <RefreshIcon /> Atualizar
            </Button>
          </Grid>
        </GridContainer>
        <UsersListTable tableData={this.state.users} {...this.props} />
        {createOrEditUserSuccessNotification}
        {deleteUserSuccessNotification}
        {notification}
      </div>
    );
  }
}

UsersListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    users: state.userReducer.users,
    usersError: state.userReducer.usersError,
    fetchUsersError: state.userReducer.fetchUsersError,
    updatedUser: state.userReducer.updatedUser,
    deletedUser: state.userReducer.deletedUser,
    createdUser: state.userReducer.createdUser,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchUsers,
    resetCreatedOrUpdatedUser,
    resetDeletedUser,
  })
)(withRouter(UsersListPage));
