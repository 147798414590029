import {
  FETCH_BILLS_TO_PAY,
  FETCH_BILLS_TO_PAY_ERROR,
  EDIT_BILLS_TO_PAY,
  EDIT_BILLS_TO_PAY_ERROR,
  ADD_PAYMENT_INFORMATION,
  ADD_PAYMENT_INFORMATION_ERROR,
  RESET_BILLS_TO_PAY,
} from './types';
import { handleResponseError } from './handleResponseError';
import history from '../history';

export const fetchBillsToPay = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/provisions/bills')
      .then((res) => {
        dispatch({
          type: FETCH_BILLS_TO_PAY,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_BILLS_TO_PAY}: ${e.message}`);
        dispatch({
          type: FETCH_BILLS_TO_PAY_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_BILLS_TO_PAY_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchEditBillsToPay = (id) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/provisions/' + id)
      .then((res) => {
        dispatch({
          type: EDIT_BILLS_TO_PAY,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${EDIT_BILLS_TO_PAY}: ${e.message}`);
        dispatch({
          type: EDIT_BILLS_TO_PAY_ERROR,
          payload: handleResponseError(e),
        });
        history.push('/pagamento/' + id);
      });
  } else {
    dispatch({
      type: EDIT_BILLS_TO_PAY_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchAddPaymentInformation = (id) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  await connections.apiAdmin
    .put('/provisions/' + id, {
      status: 'close',
    })
    .then((res) => {
      dispatch({
        type: ADD_PAYMENT_INFORMATION,
        payload: res.data ? res.data : false,
      });
      history.push('/pagamentos/lista');
    })
    .catch((e) => {
      console.error(`action: ${ADD_PAYMENT_INFORMATION}: ${e.message}`);
      dispatch({
        type: ADD_PAYMENT_INFORMATION_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const resetBillsToPay = () => async (dispatch) => {
  dispatch({
    type: RESET_BILLS_TO_PAY,
  });
};
