// LoginAction
export const USER_LOGIN = 'user_login';
export const USER_LOGIN_ERROR = 'user_login_error';
export const USER_LOGIN_PROFILE = 'user_login_profile';
export const USER_LOGIN_PROFILE_ERROR = 'user_login_profile_error';
export const FETCH_UPDATED_USER_PROFILE = 'fetch_updated_user_profile';
export const FETCH_UPDATED_USER_PROFILE_ERROR =
  'fetch_updated_user_profile_error';
export const FETCH_CURRENT_USER = 'fetch_current_user';
export const RESET_LOGIN = 'reset_login';
export const PASSWORD_RECOVERY = 'password_recovery';
export const PASSWORD_RECOVERY_ERROR = 'password_recovery_error';

//Dash
export const FETCH_DASHBOARD_CLIENTS = 'fetch_dashboard_clients';
export const FETCH_DASHBOARD_CLIENTS_ERROR = 'fetch_dashboard_clients_error';
export const FETCH_DASHBOARD_USERS = 'fetch_dashboard_users';
export const FETCH_DASHBOARD_USERS_ERROR = 'fetch_dashboard_users_error';
export const FETCH_DASHBOARD_CLIENTS_BANNED = 'fetch_dashboard_clients_banned';
export const FETCH_DASHBOARD_CLIENTS_BANNED_ERROR =
  'fetch_dashboard_clients_banned_error';
export const FETCH_DASHBOARD_CLIENTS_VALIDATION =
  'fetch_dashboard_clients_validation';
export const FETCH_DASHBOARD_CLIENTS_VALIDATION_ERROR =
  'fetch_dashboard_clients_validation_error';
export const RESET_DASH_INFO = 'reset_dash_info';
export const FETCH_DASHBOARD_OPENED_DISPUTES =
  'fetch_dashboard_opened_disputes';
export const FETCH_DASHBOARD_OPENED_DISPUTES_ERROR =
  'fetch_dashboard_opened_disputes_error';
export const FETCH_DASHBOARD_AMOUNT_RECEIVED =
  'fetch_dashboard_amount_received';
export const FETCH_DASHBOARD_AMOUNT_RECEIVED_ERROR =
  'fetch_dashboard_amount_received_error';
//Validation
export const FETCH_VALIDATION = 'fetch_validation';
export const FETCH_VALIDATION_ERROR = 'fetch_validation_error';
export const FETCH_VALIDATION_ID = 'fetch_validation_id';
export const FETCH_VALIDATION_ID_ERROR = 'fetch_validation_id_error';
export const RESET_VALIDATION_INFO = 'reset_validation_info';
export const UPDATE_VALIDATION = 'fetch_update_validation';
export const UPDATE_VALIDATION_ERROR = 'fetch_update_validation_error';

//Themes
export const FETCH_THEMES = 'fetch_themes';
export const FETCH_THEMES_ERROR = 'fetch_themes_error';
export const FETCH_ADD_NEW_THEME = 'fetch_add_new_theme';
export const FETCH_ADD_NEW_THEME_ERROR = 'fetch_add_new_theme_error';
export const FETCH_THEME = 'fetch_theme_page';
export const FETCH_THEME_ERROR = 'fetch_theme_page_error';
export const UPDATE_THEME = 'update_theme';
export const UPDATE_THEME_ERROR = 'update_theme_error';
export const DELETE_THEME = 'delete_theme';
export const DELETE_THEME_ERROR = 'delete_theme_error';
export const SET_THEME_PHOTO = 'set_theme_photo';
export const RESET_THEME_PHOTO = 'reset_theme_photo';
export const RESET_THEME_INFO = 'reset_theme_info';

//BG_TYPEs
export const FETCH_BGTYPES = 'fetch_bgtypes';
export const FETCH_BGTYPES_ERROR = 'fetch_bgtypes_error';
export const FETCH_ADD_NEW_BG = 'fetch_bgtypes_new';
export const FETCH_ADD_NEW_BG_ERROR = 'fetch_bgtypes_new_error';
export const FETCH_BGTYPE = 'fetch_bgtype';
export const FETCH_BGTYPE_ERROR = 'fetch_bgtype_error';
export const UPDATE_BGTYPES = 'update_bgTypes';
export const UPDATE_BGTYPES_ERROR = 'update_bgTypes_error';
export const DELETE_BGTYPES = 'delete_bgTypes';
export const DELETE_BGTYPES_ERROR = 'delete_bgTypes_error';
export const RESET_BGTYPES_INFO = 'reset_bgtypes_info';

//Dispute
export const FETCH_DISPUTE = 'fetch_dispute';
export const FETCH_DISPUTE_ERROR = 'fetch_dispute_error';
export const FETCH_ADD_NEW_NOTE = 'fetch_add_new_note';
export const FETCH_ADD_NEW_NOTE_ERROR = 'fetch_add_new_note_error';
export const FETCH_ALL_NOTES = 'fetch_all_notes';
export const FETCH_ALL_NOTES_ERROR = 'fetch_all_notes_error';
export const FETCH_APROVE_OR_REJECT = 'fetch_aprove_or_reject';
export const FETCH_APROVE_OR_REJECT_ERROR = 'fetch_aprove_or_reject_error';
export const RESET_DISPUTE_INFO = 'reset_dispute_info';

//history
export const FETCH_HISTORY_CONTESTATION = 'fetch_history_contest';
export const FETCH_HISTORY_CONTESTATION_ERROR = 'fetch_history_contest_error';
export const FETCH_HISTORY_CONTESTATION_FILTER = 'fetch_history_contest_filter';
export const FETCH_HISTORY_CONTESTATION_FILTER_ERROR =
  'fetch_history_contest_filter_error';
export const FETCH_HISTORY_CALLS = 'fetch_history_calls';
export const FETCH_HISTORY_CALLS_ERROR = 'fetch_history_calls_error';
export const FETCH_HISTORY_USERS = 'fetch_history_users';
export const FETCH_HISTORY_USERS_ERROR = 'fetch_history_users_error';
export const FETCH_HISTORY_PROVIDERS = 'fetch_history_providers';
export const FETCH_HISTORY_PROVIDERS_ERROR = 'fetch_history_providers_error';
export const FETCH_HISTORY_PAYMENT = 'fetch_history_payment';
export const FETCH_HISTORY_PAYMENT_ERROR = 'fetch_history_payment_error';
export const FETCH_TYPES_PAYMENT = 'fetch_types_payment';
export const FETCH_TYPES_PAYMENT_ERROR = 'fetch_types_payment_error';
export const FETCH_HISTORY_BILLING = 'fetch_history_billing';
export const FETCH_HISTORY_BILLING_ERROR = 'fetch_history_billing_error';
export const RESET_HISTORY = 'reset_history';

//BILLS TO PAY
export const FETCH_BILLS_TO_PAY = 'fetch_bills_to_pay';
export const FETCH_BILLS_TO_PAY_ERROR = 'fetch_bills_to_pay_error';
export const EDIT_BILLS_TO_PAY = 'edit_bills_to_pay';
export const EDIT_BILLS_TO_PAY_ERROR = 'edit_bills_to_pay_error';
export const ADD_PAYMENT_INFORMATION = 'add_payment_information';
export const ADD_PAYMENT_INFORMATION_ERROR = 'add_payment_information_error';
export const RESET_BILLS_TO_PAY = 'reset_bills_to_pay';

//BILLS TO RECEIVE
export const FETCH_BILLS_TO_RECEIVE = 'fetch_bills_to_receive';
export const FETCH_BILLS_TO_RECEIVE_ERROR = 'fetch_bills_to_receive_error';
export const RESET_BILLS_TO_RECEIVE = 'reset_bills_to_receive';

// UserAction
export const FETCH_USER = 'fetch_user';
export const FETCH_USER_ERROR = 'fetch_user_error';
export const FETCH_USERS = 'fetch_users';
export const FETCH_USERS_ERROR = 'fetch_users_error';
export const CREATE_USER = 'create_user';
export const CREATE_USER_ERROR = 'create_user_error';
export const EDIT_USER = 'edit_user';
export const EDIT_USER_ERROR = 'edit_user_error';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_ERROR = 'update_user_error';
export const UPDATE_USER_PROFILE = 'update_user_profile';
export const UPDATE_USER_PROFILE_ERROR = 'update_user_profile_error';
export const RESET_UPDATED_USER_PROFILE = 'reset_updated_user_profile';
export const RESET_CREATED_UPDATED_USER = 'reset_created_updated_user';
export const DELETE_USER = 'delete_user';
export const DELETE_USER_ERROR = 'delete_user_error';
export const UPDATE_PASSWORD = 'update_password';
export const UPDATE_PASSWORD_ERROR = 'update_password_error';
export const RESET_DELETED_USER = 'reset_deleted_user';
export const CHANGE_USER_PASSWORD = 'change_user_password';
export const CHANGE_USER_PASSWORD_ERROR = 'change_user_password_error';
export const RESET_CHANGED_USER_PASSWORD = 'reset_changed_user_password';
export const SET_USER_PROFILE_PHOTO = 'set_user_profile_photo';
export const RESET_USER_PROFILE_PHOTO = 'reset_user_profile_photo';
export const RESET_USER_INFO = 'reset_user_info';

//Notifications
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
