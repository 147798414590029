import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import ReplyIcon from '@material-ui/icons/Reply';
import Close from '@material-ui/icons/Close';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import PictureUpload from '../../Components/PictureUpload';

import { fetchCurrentUser } from '../../../actions/loginAction';
import {
  getTheme,
  updateTheme,
  deleteTheme,
  setThemePhoto,
  resetThemeInfo,
} from '../../../actions/themesAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Alert from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// import PictureUpload2 from '../../Components/PictureUpload2'

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EditThemePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isValidTitle: true,
      titleValidationMessage: '',
      description: '',
      isValidDescription: true,
      descriptionValidationMessage: '',
      image: '',
      themePhoto: '',
      isValidImage: true,
      imageValidationMessage: '',
      deleteUserConfirmationModal: false,
      deleteUserConfirmationToggle: false,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (!id) return;
    this.props.getTheme(id);
  }

  componentWillUnmount() {
    this.props.resetThemeInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.editTheme && this.props.editTheme !== prevProps.editTheme) {
      this.setState({
        title: this.props ? this.props.editTheme.title : '-',
        description:
          this.props.editTheme.description === null
            ? '-'
            : this.props.editTheme.description,
        image: this.props.editTheme.image_path,
      });
    }

    if (
      this.props.editThemeError &&
      prevProps.editThemeError !== this.props.editThemeError
    ) {
      this.setState(
        {
          notificationMessage: this.props.editThemeError,
        },
        () => this.showNotification('danger')
      );
    }
    if (
      this.props.updateTheme &&
      prevProps.updateTheme !== this.props.updateTheme
    ) {
      this.setState(
        {
          notificationMessage: 'Tema alterado com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.updateThemeError &&
      prevProps.updateThemeError !== this.props.updateThemeError
    ) {
      this.setState({ notificationMessage: this.props.updateThemeError }, () =>
        this.showNotification('danger')
      );
    }
    if (
      this.props.deleteTheme &&
      prevProps.deleteTheme !== this.props.deleteTheme
    ) {
      this.setState(
        {
          notificationMessage: 'Tema deletado com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.deleteThemeError &&
      prevProps.deleteThemeError !== this.props.deleteThemeError
    ) {
      this.setState({ notificationMessage: this.props.deleteThemeError }, () =>
        this.showNotification('danger')
      );
    }
    if (this.props.themePhoto && !!this.state.themePhoto) {
      this.setState({
        themePhoto: this.props.themePhoto,
      });
    }
  }

  handleOnChangeTitle(event) {
    this.setState({ title: event.target.value });
  }

  handleOnChangeDescription(event) {
    this.setState({ description: event.target.value });
  }

  handleDeleteTheme() {
    this.props.deleteTheme(this.props.editTheme.id);
  }

  handleSubmit() {
    if (this.state.title.length < 3) {
      this.setState({
        isValidTitle: false,
        titleValidationMessage: 'O título deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState({
        isValidTitle: true,
        titleValidationMessage: '',
      });
    }


    let id = this.props.editTheme && this.props.editTheme.id;
    if (this.state.isValidTitle) {
      this.props.updateTheme(id, this.state.title, this.props.themePhoto);
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetThemeInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let deleteUserConfirmationModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.deleteUserConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('deleteUserConfirmationModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Excluir tema</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Após excluído, o tema em questão não constará mais no sistema. Tem
              certeza que deseja continuar?
            </h5>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.deleteUserConfirmationToggle}
                    onChange={this.handleChange('deleteUserConfirmationToggle')}
                    value="deleteUserConfirmationToggle"
                    color="primary"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Sim. Desejo excluir este tema."
              />
            </div>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.handleDeleteTheme()}
              color="success"
              simple
              disabled={!this.state.deleteUserConfirmationToggle}
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => this.handleClose('deleteUserConfirmationModal')}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

  
    console.log(this.state.image)

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/temas/lista">
                  <ReplyIcon className={classes.icon} />
                  Temas
                </Link>
                <Typography color="textPrimary">Editar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                  <CustomInput
                    labelText="Título"
                    id="title"
                    error={!this.state.isValidTitle}
                    helpText={
                      !this.state.isValidTitle &&
                      this.state.titleValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'title',
                      onChange: this.handleOnChangeTitle.bind(this),
                      value: this.state.title,
                    }}
                  />
                  <PictureUpload
                    visualizationOnly={false}
                    userProfilePhoto={this.state.themePhoto} //imagem base64
                    decode={false}
                    customCTA="Alterar imagem do tema"
                    img={this.state.image}
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Check /> Salvar
                  </Button>
                  <Button
                    onClick={() => {
                      this.handleClickOpen('deleteUserConfirmationModal');
                    }}
                    color="danger"
                  >
                    <Close /> Excluir
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {deleteUserConfirmationModal}
        {notification}
      </div>
    );
  }
}

EditThemePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    editTheme: state.themesReducer.theme_page,
    editThemeError: state.themesReducer.theme_page_error,
    updateTheme: state.themesReducer.update_theme,
    updateThemeError: state.themesReducer.update_theme_error,
    deleteTheme: state.themesReducer.delete_theme,
    deleteThemeError: state.themesReducer.delete_theme_error,
    themePhoto: state.themesReducer.themePhoto,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    getTheme,
    updateTheme,
    deleteTheme,
    resetThemeInfo,
    setThemePhoto,
   
  })
)(withRouter(EditThemePage));
