import {
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_PROFILE_ERROR,
  FETCH_UPDATED_USER_PROFILE,
  FETCH_UPDATED_USER_PROFILE_ERROR,
  FETCH_CURRENT_USER,
  RESET_LOGIN,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_ERROR,
} from '../actions/types';

const INITIAL_STATE = { authenticatedUser: null };

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, authenticatedUser: action.payload };
    case USER_LOGIN_ERROR:
      return { ...state, loginError: true };
    case USER_LOGIN_PROFILE_ERROR:
      return { ...state, userLoginProfileError: action.payload };
    case FETCH_UPDATED_USER_PROFILE:
      return { ...state, authenticatedUser: action.payload };
    case FETCH_UPDATED_USER_PROFILE_ERROR:
      return { ...state, fetchUpdatedUserProfileError: action.payload };
    case FETCH_CURRENT_USER:
      return { ...state, authenticatedUser: action.payload };
    case RESET_LOGIN:
      return { authenticatedUser: false };
    case PASSWORD_RECOVERY:
      return { ...state, passwordRecovery: action.payload };
    case PASSWORD_RECOVERY_ERROR:
      return { ...state, passwordRecoveryError: true };
    default:
      return state;
  }
};

export default reducer;
