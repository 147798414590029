import {
  FETCH_HISTORY_CONTESTATION,
  FETCH_HISTORY_CONTESTATION_ERROR,
  FETCH_HISTORY_CONTESTATION_FILTER,
  FETCH_HISTORY_CONTESTATION_FILTER_ERROR,
  FETCH_HISTORY_CALLS,
  FETCH_HISTORY_CALLS_ERROR,
  FETCH_HISTORY_USERS,
  FETCH_HISTORY_USERS_ERROR,
  FETCH_HISTORY_PROVIDERS,
  FETCH_HISTORY_PROVIDERS_ERROR,
  FETCH_HISTORY_PAYMENT,
  FETCH_HISTORY_PAYMENT_ERROR,
  FETCH_TYPES_PAYMENT,
  FETCH_TYPES_PAYMENT_ERROR,
  FETCH_HISTORY_BILLING,
  FETCH_HISTORY_BILLING_ERROR,
  RESET_HISTORY,
} from './types';
import { handleResponseError } from './handleResponseError';

export const fetchContestation = () => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/disputes?relations=client,pont,meeting.meetingValue')
      .then((res) => {
        dispatch({
          type: FETCH_HISTORY_CONTESTATION,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_HISTORY_CONTESTATION}: ${e.message}`);
        dispatch({
          type: FETCH_HISTORY_CONTESTATION_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_HISTORY_CONTESTATION_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchContestationFilter = (client, pont, date) => async (
  dispatch,
  getState,
  connections
) => {
  let token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/disputes?relations=client,pont,meeting.meetingValue', {
        params: {
          where: { client_id: client, pont_id: pont, created_at: date },
        },
      })
      .then((res) => {
        dispatch({
          type: FETCH_HISTORY_CONTESTATION_FILTER,
          payload: res.data,
        });
        console.log(res);
      })
      .catch((e) => {
        console.error(
          `action: ${FETCH_HISTORY_CONTESTATION_FILTER}: ${e.message}`
        );
        dispatch({
          type: FETCH_HISTORY_CONTESTATION_FILTER_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_HISTORY_CONTESTATION_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchCalls = () => async (dispatch, getState, connections) => {
  let token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/meetings?relations=client,pont,meetingValue')
      .then((res) => {
        dispatch({
          type: FETCH_HISTORY_CALLS,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_HISTORY_CALLS}: ${e.message}`);
        dispatch({
          type: FETCH_HISTORY_CALLS_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_HISTORY_CALLS_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchHistoryUsers = ({ dt_inicial, dt_final, id } = {}) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get(`/meetings/byClient/` + dt_inicial + '/' + dt_final + '/' + id)
    .then((res) => {
      dispatch({
        type: FETCH_HISTORY_USERS,
        payload: res.data ? res.data : false,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_HISTORY_USERS}: ${e.message}`);
      dispatch({
        type: FETCH_HISTORY_USERS_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const fetchHistoryProviders = ({
  dt_inicial,
  dt_final,
  id,
} = {}) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get(`/meetings/byPont/` + dt_inicial + '/' + dt_final + '/' + id)
    .then((res) => {
      dispatch({
        type: FETCH_HISTORY_PROVIDERS,
        payload: res.data ? res.data : false,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_HISTORY_PROVIDERS}: ${e.message}`);
      dispatch({
        type: FETCH_HISTORY_PROVIDERS_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const fetchHistoryPayment = ({
  dt_inicial,
  dt_final,
  status,
  type,
} = {}) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get(
      `/provisions/bills/` +
        type +
        '/' +
        status +
        '/' +
        dt_inicial +
        '/' +
        dt_final
    )
    .then((res) => {
      dispatch({
        type: FETCH_HISTORY_PAYMENT,
        payload: res.data ? res.data : false,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_HISTORY_PAYMENT}: ${e.message}`);
      dispatch({
        type: FETCH_HISTORY_PAYMENT_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const fetchTypesPayment = ({} = {}) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  await connections.apiAdmin
    .get(`/provisions-types`)
    .then((res) => {
      dispatch({
        type: FETCH_TYPES_PAYMENT,
        payload: res.data ? res.data : false,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_TYPES_PAYMENT}: ${e.message}`);
      dispatch({
        type: FETCH_TYPES_PAYMENT_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const fetchHistoryBilling = ({
  status,
  type,
  dt_inicial,
  dt_final,
} = {}) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get(
      '/provisions/bills/' +
        status +
        '/' +
        type +
        '/' +
        dt_inicial +
        '/' +
        dt_final
    )
    .then((res) => {
      dispatch({
        type: FETCH_HISTORY_BILLING,
        payload: res.data ? res.data : false,
      });
      console.log(res.data);
    })
    .catch((e) => {
      console.error(`action: ${FETCH_HISTORY_BILLING}: ${e.message}`);
      dispatch({
        type: FETCH_HISTORY_BILLING_ERROR,
        payload: handleResponseError(e),
      });
    });
};
export const resetHistory = () => async (dispatch, getState, connections) => {
  dispatch({
    type: RESET_HISTORY,
  });
};
