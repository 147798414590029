import { combineReducers } from 'redux';

// api`s reducers
import loginReducer from './loginReducer';
import dashboardReducer from './dashboardReducer';
import userReducer from './userReducer';
import validationReducer from './validationReducer';
import notificationReducer from './notificationReducer';
import disputeReducer from './disputeReducer';
import reportsReducer from './reportsReducer';
import billsToPayReducer from './billsToPayReducer';
import billsToReceiveReducer from './billsToReceiveReducer';
import themesReducer from './themesReducer';
import bgTypesReducer from './bgTypesReducer';

const rootReducer = combineReducers({
  loginReducer,
  dashboardReducer,
  userReducer,
  validationReducer,
  notificationReducer,
  disputeReducer,
  reportsReducer,
  billsToPayReducer,
  billsToReceiveReducer,
  themesReducer,
  bgTypesReducer,
});

export default rootReducer;
