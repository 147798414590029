import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  fetchContestation
} from "../../../actions/reportsAction";
import { fetchCurrentUser } from "../../../actions/loginAction";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Icons
import Alert from "@material-ui/icons/NotificationImportant";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "../../../components/CustomButtons/Button.jsx";
import Grid from "@material-ui/core/Grid";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FilterList from "@material-ui/icons/FilterList";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";

import HistoryListTable from "./HistoryListTable";

const style = {};
const drawerStyle = {
  width: 400,
};
const buttonStyle = {
  marginTop: 40,
};

class HistoryListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersModal: false,
      disputes: [],
      filterClientId: null,
      filterPontId: null,
      filterCreatedAt: null,
     
    };
  }

  componentDidMount() {
    this.props.fetchContestation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
     if (this.props.historyContest && prevProps.historyContest != this.props.historyContest) {
      this.setState({notificationMessage: 'Dados atualizados', tableData: this.props.historyContest}, () => this.showNotification('primary'))
    }
    if (this.props.historyContestError && prevProps.historyContestError != this.props.historyContestError) {
      this.setState({notificationMessage: this.props.historyContestError}, () => this.showNotification('danger'))
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      var timeToFade = 4000;
      var x = [];
      x[place] = true;
      this.setState(x, () => {
        setTimeout(
          function() {
            x[place] = false;
            if (place === "br") {
              x["createOrEditUserSuccessMessage"] = "";
            } else if (place === "bl") {
              x["deleteUserSuccessMessage"] = "";
            }
            this.setState(x);
          }.bind(this),
          timeToFade
        );
      });
    }
  }

  

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer justify="flex-end">
        <Grid item>
          <Button
            onClick={() => this.props.fetchContestation()}
            color="primary"
            size="sm"
            style={{padding: 10 }}
          >
            <RefreshIcon/> Atualizar
          </Button>
        </Grid>
        </GridContainer>
        <HistoryListTable {...this.props} />
      </div>
    );
  }
}

HistoryListPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    historyContest: state.reportsReducer.history_contest,
    historyContestError:  state.reportsReducer.history_contest_error,
  };
}

export default compose(
  withStyles(style),
  connect(
    mapStateToProps,
    {
      fetchCurrentUser,
      fetchContestation
    }
  )
)(withRouter(HistoryListPage));
