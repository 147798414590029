import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "../views/Components/AdminNavbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Sidebar from "../views/Components/Sidebar.jsx";

import routes from "../routes/main";
import requireAuth from "../hocs/requireAuth";
import { cloneDeep, isEmpty } from "lodash";

import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import defaultImage from "../assets/img/default-avatar.png";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: null,
      color: "blue",
      bgColor: "black",
      hasImage: false,
      fixedClasses: "dropdown"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    if (window) {
      window.addEventListener("resize", this.resizeFunction);
    }
    this.getMenuRoutes();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    if (window) {
      window.removeEventListener("resize", this.resizeFunction);
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };

  handleColorClick = color => {
    this.setState({ color: color });
  };

  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }

  getActiveRoute = routes => {
    let activeRoute = "";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);

        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (
        typeof window !== "undefined" &&
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].displayTitle ? routes[i].displayTitle : routes[i].name;
      } else {
        try {
          let pathName = window.location.pathname;
          let href = pathName.split("/");
          let route = routes[i].path.split("/");
          let match = [];

          if (href.length === route.length) {
            for (let j = 0; j < route.length; j++) {
              if (href[j] === route[j] || route[j].indexOf(":") === 0) {
                match.push(href[j]);
              }
            }
          }

          if (match.join("/") === pathName) {
            return routes[i].displayTitle
              ? routes[i].displayTitle
              : routes[i].name;
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    return activeRoute;
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.isAdmin) {
        return (
          <Route path={prop.path} exact={prop.isExact} component={prop.childComponent} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  getMenuRoutes() {
    // Cloning original routes object to a new one.
    let menuRoutes = cloneDeep(routes);

    let routesToDisplay = [];
    for (let i = 0; i < menuRoutes.length; i++) {
      if (menuRoutes[i].collapse) {
        let viewsToDisplay = [];
        for (let j = 0; j < menuRoutes[i].views.length; j++) {
          if (menuRoutes[i].views[j].displayInMenu) {
            viewsToDisplay.push(menuRoutes[i].views[j]);
          }
        }
        menuRoutes[i].views = viewsToDisplay;
        routesToDisplay.push(menuRoutes[i]);
      } else {
        if (menuRoutes[i].displayInMenu) {
          routesToDisplay.push(menuRoutes[i]);
        }
      }
    }
    return routesToDisplay;
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (typeof window !== "undefined" && window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    const { classes, ...rest } = this.props;

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.getMenuRoutes()}
          logoText={"PONT - Admin"}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          userName={
            !isEmpty(this.props.authenticatedUser)
              ? this.props.authenticatedUser.userProfile.user.name
              : ""
          }
          userProfilePhoto={
            !isEmpty(this.props.authenticatedUser)
              ? this.props.authenticatedUser.userProfile.userProfilePhoto
              : defaultImage
            
          }
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
          {/* <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleBgColorClick={this.handleBgColorClick}
            handleHasImage={this.handleHasImage}
            color={this.state["color"]}
            bgColor={this.state["bgColor"]}
            bgImage={this.state["image"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
          /> */}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(withRouter(requireAuth(Dashboard)));
