import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import ListAlt from '@material-ui/icons/ListAlt';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx';

import { fetchCurrentUser } from '../../../actions/loginAction';

import history from '../../../history';
import { filterMethod } from '../../../helpers/utils';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class BillsToReceiveListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      billsToReceiveData: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setState({ tableData: this.props.tableData }, () => {
        this.handleData();
      });
    }
  }

  handleData = () => {
    const data = this.state.tableData.map((prop, key) => {
      return {
        id: key,
        dataCadastro: moment(prop.created_at).format('DD/MM/YYYY'),
        method: prop.method,
        amount: prop.amount
          ? Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }).format(prop.amount)
          : '',

        status: prop.status,
        nomeCLiente: prop.client ? prop.client.name : '',
      };
    });
    this.setState({ billsToReceiveData: data });
  };

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <ListAlt />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.billsToReceiveData}
                noDataText="Carregando dados..."
                filterable
                defaultFilterMethod={filterMethod}
                columns={[
                  {
                    Header: 'Cadastro',
                    accessor: 'dataCadastro',
                    sortMethod: (a, b) => {
                      if (!a || !b) return 0;

                      let dateA = a.split('/');
                      dateA = dateA[2] + '-' + dateA[1] + '-' + dateA[0];
                      dateA = new Date(dateA);

                      let dateB = b.split('/');
                      dateB = dateB[2] + '-' + dateB[1] + '-' + dateB[0];
                      dateB = new Date(dateB);

                      if (dateA > dateB) return -1;
                      if (dateA < dateB) return 1;
                      return 0;
                    },
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'method',
                  },
                  {
                    Header: 'Valor',
                    accessor: 'amount',
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                  {
                    Header: 'Nome Cliente ',
                    accessor: 'nomeCLiente',
                  },
                ]}
                defaultSorted={[
                  {
                    id: 'type',
                    desc: true,
                  },
                  {
                    id: 'status',
                    desc: false,
                  },
                ]}
                // getTdProps={(state, rowInfo, column, instance) => ({
                //   onClick: () => {
                //     if (column.id !== 'actions' && rowInfo) {
                //       history.push('/pagamento/' + rowInfo.original.id);
                //     }
                //   },
                // })}
                // getTrProps={(state, rowInfo, column) => {
                //   return {
                //     style: {
                //       cursor: 'pointer',
                //     },
                //   };
                // }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                showPageJump={false}
                previousText="Anterior"
                nextText="Próximo"
                pageText="Página"
                ofText="de"
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

BillsToReceiveListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchCurrentUser })
)(withRouter(BillsToReceiveListTable));
