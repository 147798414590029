import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';

// material-ui icons
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ReplyIcon from '@material-ui/icons/Reply';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
// import PictureUpload from "@views/Components/PictureUpload.jsx";

import { fetchCurrentUser } from '../../../actions/loginAction';
import { validateEmail, removeReadonly } from '../../../helpers/utils';
import {
  editUser,
  updateUser,
  deleteUser,
  resetUserInfo,
} from '../../../actions/userAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Photo from '@material-ui/icons/Photo';
import Extension from '@material-ui/icons/Extension';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Alert from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
// import PictureUpload2 from '../../Components/PictureUpload2'

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EditUserPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      isValidName: true,
      nameValidationMessage: '',
      email: '',
      emailValidationMessage: '',
      isValidEmail: true,
      password: '',
      passwordValidationMessage: '',
      isValidPassword: true,
      insertDate: '',
      userProfilePhoto: '',
      userProfileDataHasBeenParsed: false,
      features: [],
      deleteUserConfirmationModal: false,
      deleteUserConfirmationToggle: false,
      activateUserConfirmationModal: false,
      activateUserConfirmationToggle: false,
      usuarioAtivo: null,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (!id) return;

    this.props.editUser(id);
  }

  componentWillUnmount() {
    this.props.resetUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.editUserInfo && !this.state.userProfileDataHasBeenParsed) {
      this.setState({
        name: this.props.editUserInfo.name,
        email: this.props.editUserInfo.email,
        insertDate: moment(this.props.editUserInfo.created_at).format(
          'DD/MM/YYYY'
        ),
        usuarioAtivo: this.props.editUserInfo.blocked,
        userProfileDataHasBeenParsed: true,
      });
    }

    if (
      this.props.editUserError &&
      prevProps.editUserError != this.props.editUserError
    ) {
      this.setState({ notificationMessage: this.props.editUserError }, () =>
        this.showNotification('danger')
      );
    }
    if (
      this.props.updateUserError &&
      prevProps.menusError != this.props.updateUserError
    ) {
      this.setState({ notificationMessage: this.props.menusError }, () =>
        this.showNotification('danger')
      );
    }
  }

  handleOnChangeName(event) {
    this.setState({ name: event.target.value });
  }

  handleOnChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleOnChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleOnChangeFeature(feature) {
    let features = this.state.features;
    const index = features.findIndex((f) => f.idMenu === feature.idMenu);
    const isFather = !feature.idPai;
    const indexFather = isFather
      ? -1
      : features.findIndex((f) => f.idMenu === feature.idPai);

    // Add
    if (index < 0) {
      features.push({ idMenu: feature.idMenu });
      if (!isFather && indexFather < 0) {
        features.push({ idMenu: feature.idPai });
      }
      if (isFather) {
        this.props.menus.menus
          .filter((m) => m.idPai === feature.idMenu)
          .map((c) => {
            const childIndex = this.state.features.findIndex(
              (f) => f.idMenu === c.idMenu
            );
            if (childIndex < 0) features.push({ idMenu: c.idMenu });
          });
      }
    }
    // Remove
    else {
      features.splice(index, 1);

      // Remove childs
      if (isFather) {
        this.props.menus.menus
          .filter((m) => m.idPai === feature.idMenu)
          .map((c) => {
            const childIndex = this.state.features.findIndex(
              (f) => f.idMenu === c.idMenu
            );
            if (childIndex >= 0) features.splice(childIndex, 1);
          });
      }
      // Uncheck father
      else if (indexFather > -1) {
        let uncheck = true;
        let childs = this.props.menus.menus.filter(
          (m) => m.idPai === feature.idPai
        );
        childs.forEach((c) => {
          const childIndex = features.findIndex((f) => f.idMenu === c.idMenu);
          if (childIndex >= 0) uncheck = false;
        });

        if (uncheck) {
          const newIndexFather = features.findIndex(
            (f) => f.idMenu === feature.idPai
          );
          features.splice(newIndexFather, 1);
        }
      }
    }

    this.setState({ features });
  }

  handleDeleteUser() {
    this.props.deleteUser(this.props.editUserInfo.id);
  }

  handleActivateUser() {
    this.props.activateUser(
      this.props.authenticatedUser.userToken,
      this.props.editUserInfo.id,
      this.props.editUserInfo.nomeUsuarioAdm
    );
  }

  handleSubmit() {
    if (this.state.name.length < 3) {
      this.setState({
        isValidName: false,
        nameValidationMessage: 'O nome deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState({
        isValidName: true,
        nameValidationMessage: '',
      });
    }

    if (this.state.email === '') {
      this.setState({
        isValidEmail: false,
        emailValidationMessage: 'O e-mail não foi preenchido.',
      });
    } else {
      this.setState(
        {
          isValidEmail: validateEmail(this.state.email),
        },
        () => {
          this.setState({
            emailValidationMessage:
              !this.state.isValidEmail && 'O e-mail digitado é inválido.',
          });
        }
      );
    }

    if (this.state.password.length < 3) {
      this.setState({
        isValidPassword: false,
        passwordValidationMessage:
          'O campo senha deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState(
        {
          isValidPassword: true,
          passwordValidationMessage: '',
        },
        () => {
          if (
            this.state.isValidName &&
            this.state.isValidEmail &&
            this.state.isValidPassword
          ) {
            let userId = this.props.editUserInfo && this.props.editUserInfo.id;
            this.props.updateUser(
              userId,
              this.state.name,
              this.state.email,
              btoa(this.state.password)
            );
          }
        }
      );
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetUserInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let deleteUserConfirmationModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.deleteUserConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('deleteUserConfirmationModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Desativar Usuário</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Após desativado, o usuário em questão não poderá mais logar no
              sistema. Tem certeza que deseja continuar?
            </h5>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.deleteUserConfirmationToggle}
                    onChange={this.handleChange('deleteUserConfirmationToggle')}
                    value="deleteUserConfirmationToggle"
                    color="primary"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Sim. Desejo desativar este usuário."
              />
            </div>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.handleDeleteUser()}
              color="success"
              simple
              disabled={!this.state.deleteUserConfirmationToggle}
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => this.handleClose('deleteUserConfirmationModal')}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    let activateUserConfirmationModal = (
      <GridContainer justify="center">
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          open={this.state.activateUserConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('activateUserConfirmationModal')}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h3 className={classes.modalTitle}>Ativar Usuário</h3>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + ' ' + classes.modalSmallBody}
          >
            <h5>
              Após ativado, o usuário em questão poderá acessar o sistema. Tem
              certeza que deseja continuar?
            </h5>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.activateUserConfirmationToggle}
                    onChange={this.handleChange(
                      'activateUserConfirmationToggle'
                    )}
                    value="activateUserConfirmationToggle"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Sim. Desejo ativar este usuário."
              />
            </div>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.handleActivateUser()}
              color="success"
              simple
              disabled={!this.state.activateUserConfirmationToggle}
              className={
                classes.modalSmallFooterFirstButton +
                ' ' +
                classes.modalSmallFooterSecondButton
              }
            >
              Confirmar
            </Button>
            <Button
              onClick={() => this.handleClose('activateUserConfirmationModal')}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    );

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/usuario/lista">
                  <ReplyIcon className={classes.icon} />
                  Usuários
                </Link>
                <Typography color="textPrimary">Editar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    error={!this.state.isValidName}
                    helpText={
                      !this.state.isValidName &&
                      this.state.nameValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'name',
                      onChange: this.handleOnChangeName.bind(this),
                      value: this.state.name,
                    }}
                  />
                  <CustomInput
                    labelText="Email"
                    id="email"
                    error={!this.state.isValidEmail}
                    helpText={
                      !this.state.isValidEmail &&
                      this.state.emailValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'email',
                      name: 'email',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangeEmail.bind(this),
                      value: this.state.email,
                    }}
                  />
                  <CustomInput
                    labelText="Senha"
                    id="password"
                    error={!this.state.isValidPassword}
                    helpText={
                      !this.state.isValidPassword &&
                      this.state.passwordValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangePassword.bind(this),
                      value: this.state.password,
                    }}
                  />
                  <CustomInput
                    labelText="Data de Cadastro"
                    id="insert_date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'insert_date',
                      value: this.state.insertDate,
                      disabled: true,
                    }}
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Check /> Salvar
                  </Button>
                  {/* { this.state.usuarioAtivo === 'A' ? ( */}
                  <Button
                    onClick={() => {
                      this.handleClickOpen('deleteUserConfirmationModal');
                    }}
                    color="danger"
                  >
                    <Close /> Desativar
                  </Button>
                  {/* ) : ( */}
                  {/* <Button
                      onClick={() => {
                        this.handleClickOpen("activateUserConfirmationModal");
                      }}
                      color="info"
                    >
                      <Check /> Ativar
                    </Button> */}
                  {/* ) } */}
                </form>
              </CardBody>
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader>
                <h4 className={classes.modalTitle}>Permissões</h4>
              </CardHeader>
              <CardBody>
                {features}
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
        {deleteUserConfirmationModal}
        {notification}
        {/* {activateUserConfirmationModal} */}
      </div>
    );
  }
}

EditUserPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    editUserInfo: state.userReducer.editUserInfo,
    editUserError: state.userReducer.editUserError,
    updateUserError: state.userReducer.updateUserError,
    updatedUser: state.userReducer.updatedUser,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    editUser,
    updateUser,
    deleteUser,
    resetUserInfo,
  })
)(withRouter(EditUserPage));
