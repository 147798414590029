import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions/types';

const INITIAL_STATE = {
  message: '',
  opened: false,
  type: 'success',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        opened: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        opened: false,
      };
    default:
      return state;
  }
};

export default reducer;
