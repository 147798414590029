import AdminLayout from '../layouts/Admin.jsx';
import AuthLayout from '../layouts/Auth.jsx';

//Pages
import Dashboard from '../views/Dashboard/Dashboard.jsx';
import LoginPage from '../views/Pages/LoginPage.jsx';
import AddUserPage from '../views/Pages/User/AddUserPage';
import UsersListPage from '../views/Pages/User/UsersListPage';
import EditUserPage from '../views/Pages/User/EditUserPage';
import ValidationPage from '../views/Pages/Validation/ValidationListPage';
import EditValidationPage from '../views/Pages/Validation/EditValidationPage';
import DisputePage from '../views/Pages/Dispute/DisputeListPage';
import NotesPage from '../views/Pages/Dispute/NotesListPage';
import HistoryDispute from '../views/Pages/HistoryDispute/HistoryListPage';
import HistoryCalls from '../views/Pages/HistoryCalls/HistoryListPage';
import HistoryUsers from '../views/Pages/HistoryUsers/HistoryUsersPage';
import HistoryPayment from '../views/Pages/HistoryPayment/HistoryPaymentPage';
import HistoryBilling from '../views/Pages/HistoryBilling/HistoryListPage';
import BillsToPay from '../views/Pages/BillsToPay/BillsToPayListPage';
import EditBillsToPay from '../views/Pages/BillsToPay/EditBillsToPayPage';
import ThemesPage from '../views/Pages/ThemesPage/ThemesListPage';
import AddThemePage from '../views/Pages/ThemesPage/AddThemePage';
import EditThemePage from '../views/Pages/ThemesPage/EditThemePage';
import BGTypesPage from '../views/Pages/BGTypesPage/BgTypesListPage';
import AddBgTypesPage from '../views/Pages/BGTypesPage/AddBgTypesPage';
import EditBgTypesPage from '../views/Pages/BGTypesPage/EditBgTypesPage';
import BillsToReceive from '../views/Pages/BillsToReceive/BillsToReceiveListPage';
import ProfilePage from '../views/Pages/User/ProfilePage';
//Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Money from '@material-ui/icons/Money';
import Business from '@material-ui/icons/Business';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import LinkIcon from '@material-ui/icons/Link';
import Forum from '@material-ui/icons/Forum';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ListAlt from '@material-ui/icons/ListAlt';
import CheckBox from '@material-ui/icons/CheckBox';
import Receipt from '@material-ui/icons/Receipt';
import Face from '@material-ui/icons/Face';

let mainRoutes = [
  {
    path: '/auth/login',
    name: 'Autenticação',
    component: AuthLayout,
    childComponent: LoginPage,
    displayInMenu: false,
    isAuth: true,
  },
  {
    path: '/auth/logout',
    name: 'Sair',
    component: AuthLayout,
    childComponent: LoginPage,
    displayInMenu: false,
    isAuth: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: AdminLayout,
    childComponent: Dashboard,
    displayInMenu: true,
    isAdmin: true,
    layout: '',
    // permission: 1,
  },
  {
    collapse: true,
    name: 'Usuários',
    icon: Person,
    state: 'userMenuCollapse',
    views: [
      {
        path: '/usuario/perfil',
        name: 'Perfil',
        displayTitle: 'Meu Perfil',
        icon: Person,
        component: AdminLayout,
        childComponent: ProfilePage,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/usuario/lista',
        name: 'Usuários',
        displayTitle: 'Lista usuário',
        icon: Person,
        component: AdminLayout,
        childComponent: UsersListPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/usuario/adicionar',
        name: 'Adicionar',
        displayTitle: 'Adicionar Usuário',
        icon: PersonAdd,
        component: AdminLayout,
        childComponent: AddUserPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/usuario/:id',
        name: 'Editar',
        displayTitle: 'Editar Usuário',
        icon: PersonAdd,
        component: AdminLayout,
        childComponent: EditUserPage,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Validação Perfis',
    icon: CheckBox,
    state: 'profileMenuCollapse',
    views: [
      {
        path: '/ponts/lista',
        name: 'Perfis',
        displayTitle: 'Lista de perfis para validação',
        icon: Face,
        component: AdminLayout,
        childComponent: ValidationPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/ponts/:id',
        name: 'Editar',
        displayTitle: 'Editar validação',
        icon: PersonAdd,
        component: AdminLayout,
        childComponent: EditValidationPage,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Disputas',
    icon: Receipt,
    state: 'disputesMenuCollapse',
    views: [
      {
        path: '/disputas/lista',
        name: 'Contestações',
        displayTitle: 'Lista de Contestações',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: DisputePage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/disputas/:id',
        name: 'Notas',
        displayTitle: 'Editar Nota',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: NotesPage,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Contas à Pagar',
    icon: Money,
    state: 'paymentsMenuCollapse',
    views: [
      {
        path: '/pagamentos/lista',
        name: 'Pagamentos',
        displayTitle: 'Lista de títulos à pagar',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: BillsToPay,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/pagamento/:id',
        name: 'Editar Pagamento',
        displayTitle: 'Pagamento',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: EditBillsToPay,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/pagamentos/reembolsos',
        name: 'Reembolso',
        displayTitle: 'Reembolso à usuário',
        icon: ListAlt,
        component: AdminLayout,
        // childComponent: PaymentsPlanListPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Contas à Receber',
    icon: Money,
    state: 'receiveMenuCollapse',
    views: [
      {
        path: '/recebimentos/lista',
        name: 'Recebimentos',
        displayTitle: 'Lista de títulos à receber',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: BillsToReceive,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Temas',
    icon: AssignmentInd,
    state: 'themesMenuCollapse',
    views: [
      {
        path: '/temas/lista',
        name: 'Temas',
        displayTitle: 'Lista de temas',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: ThemesPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/tema/adicionar',
        name: 'Adicionar',
        displayTitle: 'Adicionar Tema',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: AddThemePage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/tema/:id',
        name: 'Editar',
        displayTitle: 'Editar Tema',
        icon: PersonAdd,
        component: AdminLayout,
        childComponent: EditThemePage,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Formação',
    icon: BusinessCenter,
    state: 'bgTypesMenuCollapse',
    views: [
      {
        path: '/tipo-background/lista',
        name: 'Formação Acadêmica',
        displayTitle: 'Lista de Formação Acadêmica',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: BGTypesPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/tipo-background/adicionar',
        name: 'Adicionar',
        displayTitle: 'Adicionar Formação Acadêmica',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: AddBgTypesPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/tipo-background/:id',
        name: 'Editar',
        displayTitle: 'Editar Formação Acadêmica',
        icon: PersonAdd,
        component: AdminLayout,
        childComponent: EditBgTypesPage,
        displayInMenu: false,
        isAdmin: true,
        layout: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Relatórios',
    icon: Business,
    state: 'listsMenuCollapse',
    views: [
      {
        path: '/relatorios/usuarios',
        name: 'Reuniões realizadas',
        displayTitle: 'Histórico de reuniões realizadas por usuários',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: HistoryUsers,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      // {
      //   path: '/relatorios/prestadores',
      //   name: 'Prestadores',
      //   displayTitle: 'Histórico de serviços realizados por prestador',
      //   icon: ListAlt,
      //   component: AdminLayout,
      //   childComponent: HistoryProviders,
      //   displayInMenu: true,
      //   isAdmin: true,
      //   layout: '',
      // },
      {
        path: '/relatorios/contestacoes',
        name: 'Contestações',
        displayTitle: 'Histórico de contestações',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: HistoryDispute,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/relatorios/cobrancas',
        name: 'Cobranças',
        displayTitle: 'Histórico de cobranças',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: HistoryBilling,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/relatorios/chamadas',
        name: 'Chamadas',
        displayTitle: 'Histórico de chamadas',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: HistoryCalls,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/relatorios/pagamentos',
        name: 'Pagamentos',
        displayTitle: 'Histórico de pagamentos',
        icon: ListAlt,
        component: AdminLayout,
        childComponent: HistoryPayment,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
      {
        path: '/relatorios/reembolsos',
        name: 'Reembolsos',
        displayTitle: 'Histórico de reembolsos',
        icon: ListAlt,
        component: AdminLayout,
        // childComponent: BlogAssociationPage,
        displayInMenu: true,
        isAdmin: true,
        layout: '',
      },
    ],
  },
];

export default mainRoutes;
