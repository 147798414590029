import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import Alert from '@material-ui/icons/NotificationImportant';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Snackbar from '../../../components/Snackbar/Snackbar.jsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { currencyFormat, filterMethod } from '../../../helpers/utils';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import CardBody from '../../../components/Card/CardBody';
import TextField from '@material-ui/core/TextField';
import {
  fetchHistoryPayment,
  fetchTypesPayment,
  resetHistory,
} from '../../../actions/reportsAction';
import { fetchCurrentUser } from '../../../actions/loginAction';
import Slide from '@material-ui/core/Slide';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const style = (theme) => ({});

const STATUS_OPEN = 'open';
const STATUS_CANCEL = 'cancel';
const STATUS_CLOSE = 'close';
const STATUS_TOTAL = '*';

class HistoryPaymentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      data: [],
      dataInicio: '',
      dataFim: '',
      statusPagamento: '',
      typesPagamento: [],
      typePagamento: '',
      dataInicioError: false,
      dataFimError: false,
      statusPagamentoError: false,
      typePagamentoError: false,
    };
  }

  componentDidMount() {
    this.props.fetchTypesPayment();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.historyPayment &&
      this.props.historyPayment !== prevProps.historyPayment
    ) {
      this.setState(
        {
          tableData: this.props.historyPayment,
          notificationMessage: 'Lista atualizada',
        },
        () => {
          this.handleData();
          this.showNotification();
        }
      );
    }

    if (
      this.props.historyPaymentError &&
      this.props.historyPaymentError != prevProps.historyPaymentError
    ) {
      this.setState(
        { notificationMessage: this.props.historyPaymentError },
        () => this.showNotification('danger')
      );
    }

    if (
      this.props.historyTypePayment !== prevProps.historyTypePayment &&
      Array.isArray(this.props.historyTypePayment)
    ) {
      this.setState({ typesPagamento: this.props.historyTypePayment });
    }
  }

  componentWillUnmount() {
    this.props.resetHistory();
  }

  fetchData() {
    this.setState(
      {
        dataInicioError: '',
        dataFimError: '',
      },
      () => {
        let aprove = true;
        if (
          this.state.dataInicio &&
          this.state.dataFim &&
          this.state.dataInicio > this.state.dataFim
        ) {
          this.setState({
            dataFimError: 'Data final deve ser maior que a data inicial',
          });
          aprove = false;
        }
        if (
          this.state.dataInicio &&
          this.state.dataFim &&
          this.state.dataInicio > this.state.dataFim
        ) {
          this.setState({
            dataFimError: 'Data final deve ser maior que a data inicial',
          });
          aprove = false;
        }
        if (!this.state.dataInicio) {
          this.setState({ dataInicioError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!this.state.dataFim) {
          this.setState({ dataFimError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!this.state.statusPagamento) {
          this.setState({ statusPagamentoError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!this.state.typePagamento) {
          this.setState({ typePagamentoError: 'Campo obrigatório' });
          aprove = false;
        }
        if (!aprove) return;
        this.props.fetchHistoryPayment({
          type: this.state.typePagamento || '',
          status: this.state.statusPagamento || '',
          dt_inicial: this.state.dataInicio || '',
          dt_final: this.state.dataFim || '',
        });
      }
    );
  }

  handleData = () => {
    const data =
      this.state.tableData && Array.isArray(this.state.tableData)
        ? this.state.tableData.map((prop, key) => {
            return {
              id: prop.id ? prop.id : '',
              dataRegistro: prop.created_at
                ? moment(prop.created_at).format('DD/MM/YYYY')
                : '',
              quantidade: prop.amout ? prop.amout : '',
              meeting_date: prop.meeting_date
                ? moment(prop.meeting_date).format('DD/MM/YYYY')
                : '',
              status_meeting: prop.meeting_status ? prop.meeting_status : '',
              type: prop.type ? prop.type : '',
              statusPagamento: prop.status ? prop.status : '',
            };
          })
        : [];
    this.setState({ data });
  };

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetHistory();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  handleModalOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleModalClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleOnChangeuser(event) {
    this.setState({ name: event.target.value });
  }

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'success'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    let optionsType =
      this.state.typesPagamento && Array.isArray(this.state.typesPagamento)
        ? this.state.typesPagamento.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.description}
            </MenuItem>
          ))
        : null;

    let optionsAll = (
      <MenuItem key={'all'} value={'*'}>
        Todos
      </MenuItem>
    );

    optionsType.push(optionsAll);

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <LocalAtmIcon />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <Grid container spacing={32}>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '15px' }}
                  >
                    <TextField
                      id="dataInicio"
                      label="Início"
                      error={!!this.state.dataInicioError}
                      helpText={
                        !this.state.dataInicioError &&
                        this.state.dataInicioErrorMessage
                      }
                      type="date"
                      onChange={this.handleChange('dataInicio').bind(this)}
                      fullWidth={true}
                      inputProps={{ max: '9999-12-31' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '15px' }}
                  >
                    <TextField
                      id="dataFim"
                      label="Término"
                      error={!!this.state.dataFimError}
                      helpText={this.state.dataFimError}
                      type="date"
                      inputProps={{
                        max: '3000-01-01',
                        min: this.state.dataInicio,
                      }}
                      value={this.state.dataFim}
                      onChange={this.handleChange('dataFim').bind(this)}
                      fullWidth={true}
                      InputLabelProps={{
                        min: this.state.dataInicio,
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '16px' }}
                  >
                    <FormControl
                      fullWidth={true}
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="statusPagamento">
                        Status Pagamento
                      </InputLabel>
                      <Select
                        value={this.state.statusPagamento}
                        error={!!this.state.statusPagamentoError}
                        onChange={this.handleChange('statusPagamento').bind(
                          this
                        )}
                        inputProps={{
                          name: 'statusPagamento',
                          id: 'statusPagamento',
                        }}
                      >
                        <MenuItem key={1} value={STATUS_OPEN}>
                          Aberto
                        </MenuItem>
                        <MenuItem key={2} value={STATUS_CANCEL}>
                          Cancelado
                        </MenuItem>
                        <MenuItem key={3} value={STATUS_CLOSE}>
                          Fechado
                        </MenuItem>
                        <MenuItem key={4} value={STATUS_TOTAL}>
                          Todos
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    style={{ paddingTop: '16px' }}
                  >
                    <FormControl
                      fullWidth={true}
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="typePagamento">
                        Tipo Pagamento
                      </InputLabel>
                      <Select
                        value={this.state.typePagamento || []}
                        error={!!this.state.typePagamentoError}
                        onChange={this.handleChange('typePagamento').bind(this)}
                        inputProps={{
                          name: 'typePagamento',
                          id: 'typePagamento',
                        }}
                      >
                        {optionsType}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  spacing={8}
                  style={{ paddingTop: '10px' }}
                >
                  <Grid item>
                    <Button
                      onClick={() => this.fetchData()}
                      color="success"
                      size="sm"
                      block
                    >
                      Pesquisar
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: '32px' }}
                  item
                  xs={12}
                  md={12}
                  sm={12}
                >
                  <ReactTable
                    data={this.state.data}
                    noDataText="Nenhum dado encontrado"
                    filterable
                    defaultFilterMethod={filterMethod}
                    columns={[
                      {
                        Header: 'ID',
                        accessor: 'id',
                      },
                      {
                        Header: 'Criado em',
                        accessor: 'dataRegistro',
                        sortMethod: (a, b) => {
                          if (!a || !b) return 0;

                          let dateA = a.split('/');
                          dateA = dateA[2] + '-' + dateA[1] + '-' + dateA[0];
                          dateA = new Date(dateA);

                          let dateB = b.split('/');
                          dateB = dateB[2] + '-' + dateB[1] + '-' + dateB[0];
                          dateB = new Date(dateB);

                          if (dateA > dateB) return -1;
                          if (dateA < dateB) return 1;
                          return 0;
                        },
                      },
                      {
                        Header: 'Data Meeting',
                        accessor: 'meeting_date',
                        sortMethod: (a, b) => {
                          let valorA = parseFloat(
                            a
                              .replace('R$', '')
                              .replaceAll('.', '')
                              .replace(',', '.')
                          );
                          let valorB = parseFloat(
                            b
                              .replace('R$', '')
                              .replaceAll('.', '')
                              .replace(',', '.')
                          );

                          if (valorA > valorB) return -1;
                          if (valorA < valorB) return 1;
                          return 0;
                        },
                      },
                      {
                        Header: 'Meeting Status',
                        accessor: 'status_meeting',
                      },
                      {
                        Header: 'Tipo',
                        accessor: 'type',
                      },
                      {
                        Header: 'Status',
                        accessor: 'statusPagamento',
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    showPageJump={false}
                    previousText="Anterior"
                    nextText="Próximo"
                    pageText="Página"
                    ofText="de"
                    className="-striped -highlight"
                  />
                </Grid>
                {notification}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

HistoryPaymentTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    historyPayment: state.reportsReducer.history_payment,
    historyPaymentError: state.reportsReducer.history_payment_error,
    historyTypePayment: state.reportsReducer.history_type_payment,
    historyTypePaymentError: state.reportsReducer.history_type_payment_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchHistoryPayment,
    fetchTypesPayment,
    resetHistory,
  })
)(withRouter(HistoryPaymentTable));
