import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { fetchCurrentUser } from '../../../actions/loginAction';
import { fetchThemes } from '../../../actions/themesAction';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

// Icons
import Alert from '@material-ui/icons/NotificationImportant';

import Snackbar from '../../../components/Snackbar/Snackbar.jsx';

import ThemesListTable from './ThemesListTable';

const style = {};
class ThemesListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.themes && prevProps.themes !== this.props.themes) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          themes: this.props.themes,
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.themesError &&
      prevProps.themesError != this.props.themesError
    ) {
      this.setState({ notificationMessage: this.props.themesError }, () =>
        this.showNotification('danger')
      );
    }
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <div>
        <GridContainer justify="flex-end">
          <Grid item>
            <Button
              onClick={() => this.props.fetchThemes()}
              color="primary"
              size="sm"
            >
              <RefreshIcon /> Atualizar
            </Button>
          </Grid>
        </GridContainer>
        <ThemesListTable {...this.props} />
        {notification}
      </div>
    );
  }
}

ThemesListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    themes: state.themesReducer.themes_page,
    themesError: state.themesReducer.themes_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchThemes,
  })
)(withRouter(ThemesListPage));
