import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { fetchCurrentUser } from '../../../actions/loginAction';
import { fetchBGTypes } from '../../../actions/bgTypesAction';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

// Icons
import Alert from '@material-ui/icons/NotificationImportant';

import Snackbar from '../../../components/Snackbar/Snackbar.jsx';

import BgTypesListTable from './BgTypesListTable';

const style = {};
class BgTypesListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bgTypes: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.bgTypes && prevProps.bgTypes !== this.props.bgTypes) {
      this.setState(
        {
          notificationMessage: 'Dados atualizados',
          bgTypes: this.props.bgTypes,
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.bgTypesError &&
      prevProps.bgTypesError != this.props.bgTypesError
    ) {
      this.setState({ notificationMessage: this.props.bgTypesError }, () =>
        this.showNotification('danger')
      );
    }
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <div>
        <GridContainer justify="flex-end">
          <Grid item>
            <Button
              onClick={() => this.props.fetchBGTypes()}
              color="primary"
              size="sm"
            >
              <RefreshIcon /> Atualizar
            </Button>
          </Grid>
        </GridContainer>
        <BgTypesListTable {...this.props} />
        {notification}
      </div>
    );
  }
}

BgTypesListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    bgTypes: state.bgTypesReducer.bgtypes_page,
    bgTypesError: state.bgTypesReducer.bgtypes_page_error,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, {
    fetchCurrentUser,
    fetchBGTypes,
  })
)(withRouter(BgTypesListPage));
