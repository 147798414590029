import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

import DefaultImage from '../../../assets/img/icon-image.png';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import ListAlt from '@material-ui/icons/ListAlt';

import Add from '@material-ui/icons/Add';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react.jsx';

import { fetchThemes } from '../../../actions/themesAction';
import { fetchCurrentUser } from '../../../actions/loginAction';

import history from '../../../history';
import { filterMethod } from '../../../helpers/utils';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class ThemesListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themesData: null,
      themes: [],
    };
  }

  componentDidMount() {
    this.props.fetchThemes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.themes && this.props.themes !== prevProps.themes) {
      this.setState(
        {
          themesData: this.props.themes,
          notificationMessage: 'Lista atualizada',
        },
        () => {
          this.handleData();
          this.showNotification();
        }
      );
    }

    if (
      this.props.themesError &&
      this.props.themesError != prevProps.themesError
    ) {
      this.setState({ notificationMessage: this.props.themesError }, () =>
        this.showNotification('danger')
      );
    }
  }

  handleAddTheme() {
    history.push('/tema/adicionar');
  }

  handleData = () => {
    const themes = this.state.themesData.map((prop) => {
      return {
        id: prop.id ? prop.id : '',
        title: prop.title ? prop.title : '',
        descricao: prop.description ? prop.description : '',
        image: (
          <img
            style={{ width: '100px', height: '50px', objectFit: 'contain' }}
            src={prop.image_path ? prop.image_path : DefaultImage}
            alt="Imagem tema"
          />
        ),
        ativo: prop.blocked && prop.blocked === true ? 'Não' : 'Sim',
      };
    });
    this.setState({ themes });
  };

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <ListAlt />
              </CardIcon>
            </CardHeader>
            <CardHeader>
              <Button
                size="sm"
                onClick={() => {
                  this.handleAddTheme();
                }}
                color="success"
              >
                <Add /> Novo tema
              </Button>
            </CardHeader>

            <CardBody>
              <ReactTable
                data={this.state.themes}
                noDataText="Carregando dados..."
                filterable
                defaultFilterMethod={filterMethod}
                columns={[
                  {
                    Header: 'Imagem',
                    accessor: 'image',
                  },

                  {
                    Header: 'Título',
                    accessor: 'title',
                  },
                  // {
                  //   Header: 'Descrição',
                  //   accessor: 'descricao',
                  // },
                  {
                    Header: 'Ativo',
                    accessor: 'ativo',
                  },
                ]}
                defaultSorted={[
                  {
                    id: 'description',
                    desc: false,
                  },
                  {
                    id: 'titulo',
                    desc: false,
                  },
                ]}
                getTdProps={(state, rowInfo, column, instance) => ({
                  onClick: () => {
                    if (column.id !== 'actions' && rowInfo) {
                      history.push('/tema/' + rowInfo.original.id);
                    }
                  },
                })}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      cursor: 'pointer',
                    },
                  };
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                showPageJump={false}
                previousText="Anterior"
                nextText="Próximo"
                pageText="Página"
                ofText="de"
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ThemesListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    themes: state.themesReducer.themes_page,
    themesError: state.themesReducer.themes_error,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchCurrentUser, fetchThemes })
)(withRouter(ThemesListTable));
