import {
  FETCH_HISTORY_CONTESTATION,
  FETCH_HISTORY_CONTESTATION_ERROR,
  FETCH_HISTORY_CONTESTATION_FILTER,
  FETCH_HISTORY_CONTESTATION_FILTER_ERROR,
  FETCH_HISTORY_CALLS,
  FETCH_HISTORY_CALLS_ERROR,
  FETCH_HISTORY_USERS,
  FETCH_HISTORY_USERS_ERROR,
  FETCH_HISTORY_PROVIDERS,
  FETCH_HISTORY_PROVIDERS_ERROR,
  FETCH_HISTORY_PAYMENT,
  FETCH_HISTORY_PAYMENT_ERROR,
  FETCH_TYPES_PAYMENT,
  FETCH_TYPES_PAYMENT_ERROR,
  FETCH_HISTORY_BILLING,
  FETCH_HISTORY_BILLING_ERROR,
  RESET_HISTORY,
} from '../actions/types';

const INITIAL_STATE = [{ dispute: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_HISTORY_CONTESTATION:
      return {
        ...state,
        history_contest: action.payload,
      };
    case FETCH_HISTORY_CONTESTATION_ERROR:
      return { ...state, history_contest_error: action.payload };

    case FETCH_HISTORY_CONTESTATION_FILTER:
      return {
        ...state,
        history_contest_filter: action.payload,
      };
    case FETCH_HISTORY_CONTESTATION_FILTER_ERROR:
      return { ...state, history_contest_filter_error: action.payload };

    case FETCH_HISTORY_CALLS:
      return {
        ...state,
        history_calls: action.payload,
      };
    case FETCH_HISTORY_CALLS_ERROR:
      return { ...state, history_calls_error: action.payload };

    case FETCH_HISTORY_USERS:
      return {
        ...state,
        history_users: action.payload,
      };
    case FETCH_HISTORY_USERS_ERROR:
      return { ...state, history_users_error: action.payload };

    case FETCH_HISTORY_PROVIDERS:
      return {
        ...state,
        history_providers: action.payload,
      };
    case FETCH_HISTORY_PROVIDERS_ERROR:
      return { ...state, history_providers_error: action.payload };

    case FETCH_HISTORY_PAYMENT:
      return {
        ...state,
        history_payment: action.payload,
      };
    case FETCH_HISTORY_PAYMENT_ERROR:
      return { ...state, history_payment_error: action.payload };

    case FETCH_TYPES_PAYMENT:
      return {
        ...state,
        history_type_payment: action.payload,
      };
    case FETCH_TYPES_PAYMENT_ERROR:
      return { ...state, history_type_payment_error: action.payload };

    case FETCH_HISTORY_BILLING:
      return {
        ...state,
        history_billing: action.payload,
      };
    case FETCH_HISTORY_BILLING_ERROR:
      return { ...state, history_billing_error: action.payload };
    case RESET_HISTORY:
      return {
        ...state,
        history_contest: null,
        history_contest_error: null,
        history_contest_filter: null,
        history_contest_filter_error: null,
        history_calls: null,
        history_calls_error: null,
        history_users: null,
        history_users_error: null,
        history_providers: null,
        history_providers_error: null,
        history_payment: null,
        history_payment_error: null,
        history_type_payment: null,
        history_type_payment_error: null,
        history_billing_error: null,
      };
    default:
      return state;
  }
};
