import {
  FETCH_DASHBOARD_USERS,
  FETCH_DASHBOARD_USERS_ERROR,
  FETCH_DASHBOARD_CLIENTS,
  FETCH_DASHBOARD_CLIENTS_ERROR,
  FETCH_DASHBOARD_CLIENTS_BANNED,
  FETCH_DASHBOARD_CLIENTS_BANNED_ERROR,
  FETCH_DASHBOARD_CLIENTS_VALIDATION,
  FETCH_DASHBOARD_CLIENTS_VALIDATION_ERROR,
  FETCH_DASHBOARD_OPENED_DISPUTES,
  FETCH_DASHBOARD_OPENED_DISPUTES_ERROR,
  FETCH_DASHBOARD_AMOUNT_RECEIVED,
  FETCH_DASHBOARD_AMOUNT_RECEIVED_ERROR,
  RESET_DASH_INFO,
} from '../actions/types';

const INITIAL_STATE = [{ updatedUser: null, deletedUser: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_USERS:
      return {
        ...state,
        users_dash: action.payload,
      };
    case FETCH_DASHBOARD_USERS_ERROR:
      return { ...state, users_dash_error: action.payload };
    case FETCH_DASHBOARD_CLIENTS:
      return {
        ...state,
        clients_dash: action.payload,
      };
    case FETCH_DASHBOARD_CLIENTS_ERROR:
      return { ...state, clientsError: action.payload };
    case FETCH_DASHBOARD_CLIENTS_BANNED:
      return {
        ...state,
        clients_banned: action.payload,
      };
    case FETCH_DASHBOARD_CLIENTS_BANNED_ERROR:
      return { ...state, clients_banned_error: action.payload };
    case FETCH_DASHBOARD_CLIENTS_VALIDATION:
      return {
        ...state,
        clients_validation: action.payload,
      };
    case FETCH_DASHBOARD_CLIENTS_VALIDATION_ERROR:
      return { ...state, clients_validation_error: action.payload };
    case FETCH_DASHBOARD_OPENED_DISPUTES:
      return {
        ...state,
        opened_disputes: action.payload,
      };
    case FETCH_DASHBOARD_OPENED_DISPUTES_ERROR:
      return { ...state, opened_disputes_error: action.payload };
    case FETCH_DASHBOARD_AMOUNT_RECEIVED:
      return {
        ...state,
        amount_received: action.payload,
      };
    case FETCH_DASHBOARD_AMOUNT_RECEIVED_ERROR:
      return { ...state, amount_received_error: action.payload };
    case RESET_DASH_INFO:
      return {
        ...state,
        users_dash_error: null,
        clientsError: null,
        clients_banned_error: null,
        opened_disputes_error: null,
        amount_received_error: null,
      };
    default:
      return state;
  }
};
