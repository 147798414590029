import {
  FETCH_BILLS_TO_PAY,
  FETCH_BILLS_TO_PAY_ERROR,
  EDIT_BILLS_TO_PAY,
  EDIT_BILLS_TO_PAY_ERROR,
  ADD_PAYMENT_INFORMATION,
  ADD_PAYMENT_INFORMATION_ERROR,
  RESET_BILLS_TO_PAY,
} from '../actions/types';

const INITIAL_STATE = [{ dispute: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BILLS_TO_PAY:
      return {
        ...state,
        bills_to_pay: action.payload,
      };
    case FETCH_BILLS_TO_PAY_ERROR:
      return { ...state, bills_to_pay_error: action.payload };

    case EDIT_BILLS_TO_PAY:
      return {
        ...state,
        edit_bills_to_pay: action.payload,
      };
    case EDIT_BILLS_TO_PAY_ERROR:
      return { ...state, edit_bills_to_pay_error: action.payload };

    case ADD_PAYMENT_INFORMATION:
      return {
        ...state,
        add_payment_information: action.payload,
      };
    case ADD_PAYMENT_INFORMATION_ERROR:
      return { ...state, add_payment_information_error: action.payload };

    case RESET_BILLS_TO_PAY:
      return {
        ...state,
        bills_to_pay: null,
        bills_to_pay_error: null,
        edit_bills_to_pay: null,
        edit_bills_to_pay_error: null,
        add_payment_information: null,
        add_payment_information_error: null,
      };
    default:
      return state;
  }
};
