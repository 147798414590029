import {
  FETCH_VALIDATION,
  FETCH_VALIDATION_ERROR,
  FETCH_VALIDATION_ID,
  FETCH_VALIDATION_ID_ERROR,
  UPDATE_VALIDATION,
  UPDATE_VALIDATION_ERROR,
  RESET_VALIDATION_INFO,
} from '../actions/types';

const INITIAL_STATE = [{ validation: null }];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_VALIDATION:
      return {
        ...state,
        validation: action.payload,
      };
    case FETCH_VALIDATION_ERROR:
      return { ...state, validation_error: action.payload };
    case FETCH_VALIDATION_ID:
      return {
        ...state,
        validation_id: action.payload,
      };
    case FETCH_VALIDATION_ID_ERROR:
      return { ...state, validation_id_error: action.payload };
    case UPDATE_VALIDATION:
      return {
        ...state,
        update_validation: action.payload,
      };
    case UPDATE_VALIDATION_ERROR:
      return { ...state, update_validation_error: action.payload };
    case RESET_VALIDATION_INFO:
      return {
        ...state,
        validation: null,
        validation_error: null,
        validation_id_error: null,
        update_validation: null,
        update_validation_error: null,
      };
    default:
      return state;
  }
};
