/* eslint-disable no-useless-escape */
const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

function formataCPF(cpf) {
  //retira os caracteres indesejados...
  cpf = cpf.replace(/[^\d]/g, '');

  //realizar a formatação...
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

const onlyString = (value) => {
  var re = /^[a-zA-Z_ ]*$/;
  return re.test(value);
};

const validatePassword = (password) => {
  return password.length >= 5 ? true : false;
};

const removeReadonly = (event) => {
  event.target.removeAttribute('readonly');
};

const currencyFormat = (val, addCentsDigits = true) => {
  if (val === null || val === '' || val === undefined) return;

  if (addCentsDigits) {
    return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',00';
  } else {
    return val.toString().replace(/(?=(?:\d{3})+(?:\.|$))/g, '$1.');
  }
};
const filterMethod = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  const content = row[id];
  let noDiacritics;

  if (typeof content !== 'undefined') {
    if (typeof content === 'object' && content !== null && content.key) {
      noDiacritics = removeDiacritics(content.key);
      return String(noDiacritics)
        .toLowerCase()
        .includes(removeDiacritics(filter.value).toLowerCase());
    } else {
      noDiacritics = removeDiacritics(content);
      return String(noDiacritics)
        .toLowerCase()
        .includes(removeDiacritics(filter.value).toLowerCase());
    }
  }
  return true;
};
const removeDiacritics = (val) => {
  let str = val.toString();
  var map = {
    '-': ' ',
    '-': '_',
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  for (var pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str.toLowerCase();
};

const phoneMask = (value, maxLength, isCellphone = true) => {
  if (value.length <= maxLength) {
    if (isCellphone) {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');
    }
  } else return false;
};

const zipCodeMask = (value) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3}).*/, '$1-$2');
};

const numberToReal = (number) => {
  var value = number;
  value = value + '';
  value = parseInt(value.replace(/[\D]+/g, ''));
  value = value + '';
  value = value.replace(/([0-9]{2})$/g, ',$1');

  if (value.length > 6) {
    value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }

  if (value === 'NaN') value = '';

  return value;
};

const cellphoneMask = (userInput) => {
  if (userInput && userInput !== '') {
    const numbers = userInput.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
      if (numberLength > 10) {
        return [
          '(',
          /[1-9]/,
          /[1-9]/,
          ')',
          ' ',
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }
    }
    return [
      '(',
      /[1-9]/,
      /[1-9]/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  return false;
};

export {
  validateEmail,
  validatePassword,
  removeReadonly,
  currencyFormat,
  phoneMask,
  cellphoneMask,
  zipCodeMask,
  onlyString,
  numberToReal,
  filterMethod,
  formataCPF,
};
