import {
  FETCH_DISPUTE,
  FETCH_DISPUTE_ERROR,
  FETCH_ADD_NEW_NOTE,
  FETCH_ADD_NEW_NOTE_ERROR,
  FETCH_ALL_NOTES,
  FETCH_ALL_NOTES_ERROR,
  FETCH_APROVE_OR_REJECT,
  FETCH_APROVE_OR_REJECT_ERROR,
  RESET_DISPUTE_INFO,
} from './types';
import { handleResponseError } from './handleResponseError';
import history from '../history';

export const fetchDispute = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/disputes/getOpen')
      .then((res) => {
        dispatch({
          type: FETCH_DISPUTE,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_DISPUTE}: ${e.message}`);
        dispatch({
          type: FETCH_DISPUTE_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_DISPUTE_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchAddNewNote = ({ user_id, note, dispute_id } = {}) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .post('/dispute-notes', {
      user_id,
      note,
      dispute_id,
    })
    .then((res) => {
      console.log('dispute', res);
      dispatch({
        type: FETCH_ADD_NEW_NOTE,
        payload: res.data ? res.data : false,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_ADD_NEW_NOTE}: ${e.message}`);
      dispatch({
        type: FETCH_ADD_NEW_NOTE_ERROR,
        payload: e.message,
      });
    });
};

export const fetchAllNotes = (id) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get(`/dispute-notes/getByDispute/${id}`)
    .then((res) => {
      dispatch({
        type: FETCH_ALL_NOTES,
        payload: res.data ? res.data : false,
      });
    })
    .catch((e) => {
      console.error(`action: ${FETCH_ALL_NOTES}: ${e.message}`);
      dispatch({
        type: FETCH_ALL_NOTES_ERROR,
        payload: e.message,
      });
    });
};

export const fetchAproveOrReject = (id, is_valid) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .put(`/disputes/${id}`, {
      is_valid,
    })
    .then((res) => {
      dispatch({
        type: FETCH_APROVE_OR_REJECT,
        payload: res.data ? res.data : false,
      });
      setTimeout(function() {
        history.push('/disputas/lista');
      }, 2000);
    })
    .catch((e) => {
      console.error(`action: ${FETCH_APROVE_OR_REJECT}: ${e.message}`);
      dispatch({
        type: FETCH_APROVE_OR_REJECT_ERROR,
        payload: e.message,
      });
    });
};

// export const updateValidation = (userID, name, email, password) => async (
//   dispatch,
//   getState,
//   connections
// ) => {
//   connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

//   const res = await connections.apiAdmin
//     .put('/ponts/' + userID, {})
//     .then((res) => {
//       dispatch({
//         type: UPDATE_VALIDATION,
//         payload: res.data ? res.data : false,
//       });
//       history.push('/ponts/lista');
//     })
//     .catch((e) => {
//       console.error(`action: ${UPDATE_VALIDATION_ERROR}: ${e.message}`);
//       dispatch({
//         type: UPDATE_VALIDATION_ERROR,
//         payload: handleResponseError(e),
//       });
//     });
// };

export const resetDisputeInfo = () => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: RESET_DISPUTE_INFO,
  });
};
