import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// material-ui icons
import Check from '@material-ui/icons/Check';
import PersonAdd from '@material-ui/icons/PersonAdd';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';

import { fetchCurrentUser } from '../../../actions/loginAction';
import { createUser, resetUserInfo } from '../../../actions/userAction';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import {
  validateEmail,
  validatePassword,
  removeReadonly,
} from '../../../helpers/utils';
import ReplyIcon from '@material-ui/icons/Reply';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import Alert from '@material-ui/icons/NotificationImportant';
import Snackbar from '../../../components/Snackbar/Snackbar';
// import PictureUpload2 from '../../Components/PictureUpload2'

const style = (theme) => ({
  paperBreadcrumb: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: '16px',
  },
  icon: {
    fontSize: '14px',
  },
});

class AddUserPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      isValidName: true,
      nameValidationMessage: '',
      email: '',
      emailValidationMessage: '',
      isValidEmail: true,
      password: '',
      passwordValidationMessage: '',
      isValidPassword: true,
    };
  }

  componentWillUnmount() {
    this.props.resetUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.createdUser &&
      prevProps.createdUser !== this.props.createdUser
    ) {
      this.setState(
        {
          notificationMessage: 'Usuário adicionado com sucesso',
        },
        () => this.showNotification('primary')
      );
    }
    if (
      this.props.createdUserError &&
      prevProps.createdUserError !== this.props.createdUserError
    ) {
      this.setState(
        {
          notificationMessage: 'Falha no cadastro do usuário',
        },
        () => this.showNotification('danger')
      );
    }
  }

  handleOnChangeName(event) {
    this.setState({ name: event.target.value });
  }

  handleOnChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleOnChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit() {
    if (this.state.name.length < 3) {
      this.setState({
        isValidName: false,
        nameValidationMessage: 'O nome deve conter pelo menos 3 caracteres.',
      });
    } else {
      this.setState({
        isValidName: true,
        nameValidationMessage: '',
      });
    }

    if (this.state.email === '') {
      this.setState({
        isValidEmail: false,
        emailValidationMessage: 'O e-mail não foi preenchido.',
      });
    } else {
      this.setState(
        {
          isValidEmail: validateEmail(this.state.email),
        },
        () => {
          this.setState({
            emailValidationMessage:
              !this.state.isValidEmail && 'O e-mail digitado é inválido.',
          });
        }
      );
    }

    if (this.state.password === '') {
      this.setState({
        isValidPassword: false,
        passwordValidationMessage: 'A senha não foi informada.',
      });
    } else {
      this.setState(
        {
          isValidPassword: validatePassword(this.state.password),
        },
        () => {
          this.setState({
            passwordValidationMessage:
              !this.state.isValidPassword &&
              'A senha deve conter no mínimo 6 caracteres.',
          });
        }
      );
    }
    if (
      this.state.isValidName &&
      this.state.isValidEmail &&
      this.state.isValidPassword
    ) {
      this.props.createUser(
        this.state.name,
        this.state.email,
        btoa(this.state.password)
      );
    }
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  showNotification(color = 'primary') {
    if (this.state['showNotification']) return;

    let state = [];
    state['showNotification'] = true;
    state['notificationColor'] = color;

    this.setState(state, () => {
      this.props.resetUserInfo();
      setTimeout(
        function() {
          state['showNotification'] = false;
          state['notificationColor'] = color;
          state['notificationMessage'] = '';
          this.setState(state);
        }.bind(this),
        4000
      );
    });
  }

  closeNotification = () => {
    let state = [];
    state['showNotification'] = false;
    state['notificationMessage'] = '';
    this.setState(state);
  };

  render() {
    const { classes } = this.props;

    let notification = (
      <Snackbar
        place="br"
        color={this.state.notificationColor || 'primary'}
        icon={Alert}
        message={this.state.notificationMessage}
        open={this.state.showNotification}
        closeNotification={this.closeNotification}
        close
      />
    );

    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Paper className={classes.paperBreadcrumb}>
              <Breadcrumbs separator="›" arial-label="Breadcrumb">
                <Link color="inherit" href="/usuario/lista">
                  <ReplyIcon className={classes.icon} />
                  Usuários
                </Link>
                <Typography color="textPrimary">Adicionar</Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}></GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Edit />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    error={!this.state.isValidName}
                    helpText={
                      !this.state.isValidName &&
                      this.state.nameValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'name',
                      onChange: this.handleOnChangeName.bind(this),
                      value: this.state.name,
                    }}
                  />
                  <CustomInput
                    labelText="Email"
                    id="email"
                    error={!this.state.isValidEmail}
                    helpText={
                      !this.state.isValidEmail &&
                      this.state.emailValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'email',
                      name: 'email',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangeEmail.bind(this),
                      value: this.state.email,
                    }}
                  />
                  <CustomInput
                    labelText="Senha"
                    id="password"
                    error={!this.state.isValidPassword}
                    helpText={
                      !this.state.isValidPassword &&
                      this.state.passwordValidationMessage
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password',
                      readOnly: true,
                      onFocus: removeReadonly.bind(this),
                      onChange: this.handleOnChangePassword.bind(this),
                      value: this.state.password,
                    }}
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Check /> Cadastrar
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {notification}
      </div>
    );
  }
}

AddUserPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticatedUser: state.loginReducer.authenticatedUser,
    createdUser: state.userReducer.createdUser,
    createdUserError: state.userReducer.createdUserError,
  };
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, { fetchCurrentUser, createUser, resetUserInfo })
)(withRouter(AddUserPage));
