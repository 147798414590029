import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './types';

export const showNotification = (message, type = 'success') => (dispatch) => {
  dispatch({
    type: SHOW_NOTIFICATION,
    payload: { message, type },
  });
};
export const hideNotification = () => (dispatch) => {
  dispatch({
    type: HIDE_NOTIFICATION,
  });
};
