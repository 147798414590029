import {
  FETCH_VALIDATION,
  FETCH_VALIDATION_ERROR,
  FETCH_VALIDATION_ID,
  FETCH_VALIDATION_ID_ERROR,
  UPDATE_VALIDATION,
  UPDATE_VALIDATION_ERROR,
  RESET_VALIDATION_INFO,
} from './types';
import { handleResponseError } from './handleResponseError';
import history from '../history';

export const fetchValidations = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/ponts')
      .then((res) => {
        dispatch({
          type: FETCH_VALIDATION,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FETCH_VALIDATION_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_VALIDATION_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const fetchValidationId = (id) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get(
        '/ponts/' +
          id +
          '?relations=videos,themes,backgrounds,meetingValues,professionals'
      )
      .then((res) => {
        dispatch({
          type: FETCH_VALIDATION_ID,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FETCH_VALIDATION_ID_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_VALIDATION_ID_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const updateValidation = ({
  id,
  blocked,
  client_id,
  banned_reason,
} = {}) => async (dispatch, getState, connections) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .put('/ponts/' + id, {
      id,
      blocked,
      client_id,
      banned_reason,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_VALIDATION,
        payload: res.data ? res.data : false,
      });
      history.push('/ponts/lista');
    })
    .catch((e) => {
      console.error(`action: ${UPDATE_VALIDATION_ERROR}: ${e.message}`);
      dispatch({
        type: UPDATE_VALIDATION_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const resetValidationInfo = () => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: RESET_VALIDATION_INFO,
  });
};
