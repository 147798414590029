import {
  FETCH_BGTYPES,
  FETCH_BGTYPES_ERROR,
  FETCH_ADD_NEW_BG,
  FETCH_ADD_NEW_BG_ERROR,
  FETCH_BGTYPE,
  FETCH_BGTYPE_ERROR,
  UPDATE_BGTYPES,
  UPDATE_BGTYPES_ERROR,
  DELETE_BGTYPES,
  DELETE_BGTYPES_ERROR,
  RESET_BGTYPES_INFO,
} from './types';
import { handleResponseError } from './handleResponseError';
import history from '../history';

export const fetchBGTypes = (token) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  if (token) {
    connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
    connections.apiAdmin.defaults.headers.get['token'] = token;

    const res = await connections.apiAdmin
      .get('/background-types')
      .then((res) => {
        dispatch({
          type: FETCH_BGTYPES,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.error(`action: ${FETCH_BGTYPES}: ${e.message}`);
        dispatch({
          type: FETCH_BGTYPES_ERROR,
          payload: handleResponseError(e),
        });
      });
  } else {
    dispatch({
      type: FETCH_BGTYPES_ERROR,
      payload: 'Token não informado.',
    });
  }
};

export const createBgTypes = ({ order, type }) => async (
  dispatch,
  getState,
  connections
) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .post('/background-types', {
      order,
      type,
    })
    .then((res) => {
      dispatch({
        type: FETCH_ADD_NEW_BG,
        payload: res.data ? res.data : false,
      });
      setTimeout(function() {
        history.push('/tipo-background/lista');
      }, 2000);
    })
    .catch((e) => {
      console.error(`action: ${FETCH_ADD_NEW_BG}: ${e.message}`);
      dispatch({
        type: FETCH_ADD_NEW_BG_ERROR,
        payload: e.message,
      });
    });
};

export const getBGType = (id) => async (dispatch, getState, connections) => {
  const token = sessionStorage.getItem('userToken');
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';
  connections.apiAdmin.defaults.headers.post['token'] = token;

  const res = await connections.apiAdmin
    .get('/background-types/' + id)
    .then((res) => {
      dispatch({
        type: FETCH_BGTYPE,
        payload: res.data ? res.data : false,
      });
      history.push('/tipo-background/' + id);
    })
    .catch((e) => {
      console.error(`action: ${FETCH_BGTYPE}: ${e.message}`);
      dispatch({
        type: FETCH_BGTYPE_ERROR,
        payload: e.message,
      });
    });
};

export const updateBgType = (id, order, type) => async (
  dispatch,
  getState,
  connections
) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .put('/background-types/' + id, {
      order,
      type,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_BGTYPES,
        payload: res.data ? res.data : false,
      });
      history.push('/tipo-background/lista');
    })
    .catch((e) => {
      console.error(`action: ${UPDATE_BGTYPES_ERROR}: ${e.message}`);
      dispatch({
        type: UPDATE_BGTYPES_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const deleteBgType = (id) => async (dispatch, getState, connections) => {
  connections.apiAdmin.defaults.headers['Content-Type'] = 'application/json';

  const res = await connections.apiAdmin
    .delete('/background-types/' + id)
    .then((res) => {
      dispatch({
        type: DELETE_BGTYPES,
        payload: res.data ? res.data : false,
      });
      history.push('/tipo-background/lista');
    })
    .catch((e) => {
      console.error(`action: ${DELETE_BGTYPES}: ${e.message}`);
      dispatch({
        type: DELETE_BGTYPES_ERROR,
        payload: handleResponseError(e),
      });
    });
};

export const resetBGTypesInfo = () => async (
  dispatch,
  getState,
  connections
) => {
  dispatch({
    type: RESET_BGTYPES_INFO,
  });
};
